export default {
  height (map) {
    return map.element.offsetHeight
  },
  width (map) {
    return map.element.offsetWidth
  },
  getSize (map) {
    return {
      x: this.width(map),
      y: this.height(map)
    }
  },
  setSize (map) {
    const x = map.size.x ? map.size.x*2 : map.element.offsetWidth
    map.element.style.width = `${ x * (map.zoom)}px`
    map.markers.style.width = `${ x * (map.zoom)}px`
    map.markers.style.height = `${ this.height(map) }px`
  },
  initialSize (map) {
    const params = new URLSearchParams(window.location.search)
    const zoom = parseFloat(params.get('zoom'))
    map.zoom = zoom ? zoom : map.zoom
    return {
      x: map.element.offsetWidth,
      y: map.element.offsetHeight
    }
  }
}