const auth = {
  checkLogin(logged = false, user = {}) {
    window.axios.get('/sanctum/csrf-cookie').then(() => {
      // Login...
    });
    if(logged) {
      if(!Object.values(user).length) {
        auth.me()
      }
    }
  },
  me() {
    window.axios
      .post("/api/me")
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data))
      })
      .catch((e) => {
        console.log("error", e);
      });
  },
  logout() {
    window.axios
      .post("/api/logout")
      .then(() => {
        localStorage.setItem("logged", false)
        localStorage.setItem("user", '{}')
        location.href = '/login'
      })
      .catch(() => {
        localStorage.setItem("logged", false)
        localStorage.setItem("user", '{}')
        location.href = '/login'
      });
  },
}
module.exports = auth;
