<template>
  <div class="uc-carousel"
    :data-carousel="id"
    data-type="carousel"
    data-items="1"
    data-slideby="1"
    data-loop="true"
    data-nav="true"
    data-controls="false"
    :data-navcontainer="'#carousel-nav-'+id">
    <div :class="id">
      <div
        v-for="(slide, index) in data"
        :key="'slide-'+index"
        class="uc-card card-gradient--bottom-black card-radius--none card-border--none"
      >
        <div class="slide-container">
          <img :src="slide.url" alt="slide">
        </div>
      </div>
    </div>
    <div class="uc-carousel-nav primary-carousel-nav">
      <div class="carousel-nav-container">
        <ul class="carousel-nav-list" :id="'carousel-nav-'+id">
          <li
            v-for="(slide, index) in data"
            :key="'slide-'+index"
            class="carousel-nav-item"
          >
            <div class="number">{{ 9 > index ? '0' : '' }}{{index + 1}}</div>
            <div class="indicator"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { UcCarousel } from "@digitaluc/uc-kitdigital/src/js/components/carousels";
export default {
  name: 'VideoSlider',
  props: ['data', 'id'],
  mounted () {
    if(this.data.length) new UcCarousel();
  },
  watch: {
    data () {
      if(this.data.length) new UcCarousel();
    }
  }
}
</script>
<style lang="scss" scoped>
.uc-carousel{
  .uc-carousel-nav{
    .carousel-nav-container{
      .carousel-nav-list{
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        .carousel-nav-item.carousel-nav-item{
          margin-right: 0;
          margin-left: 1rem !important;
          &.tns-nav-active{
            .indicator{
              width: 32px;
            }
          }
        }
      }
    }
  }
}
.slide-container{
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
}
</style>
