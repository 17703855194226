<template>
<div class="iframe-container">
  <iframe class="chat-iframe" :src="url" />
  <div class="d-none">
    <pre>{{data}}</pre>
  </div>
</div>
</template>
<script>
export default {
  props:['data'],
  name: "ChatComponent",
  mounted () {
    this.$store.state.hidepadding = true;
  },
  data(){
    return {
      tawkURL : ""
    }
  },
  computed: {
    url () {
      if(this.data){
        if(this.data.chat_enlace_directo){
          /* CUSTOM CHAT */
          return this.data.chat_enlace_directo
        }
      }
      return `https://tawk.to/chat/${process.env.VUE_APP_TAWK_ID}/${process.env.VUE_APP_TAWK_CHAT_ID}`
    }
  },
  watch: {
 
    '$route.path' () {
      this.$store.state.hidepadding = false;
    }
  }
}
</script>