import { createApp } from "vue";
import { createGtm } from '@gtm-support/vue-gtm';
import { dragscrollNext } from "vue-dragscroll";
import App from "./App.vue";
import router from "./router";
import vuex from "./vuex";
import { VueReCaptcha } from "vue-recaptcha-v3";

import "./css/index.css";
import "element-plus/dist/index.css";

window._ = require("lodash");
window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

require("@digitaluc/uc-kitdigital/dist/js/uc-kitdigital");
import { Accordion } from "@digitaluc/uc-kitdigital/src/js/components/accordion";
new Accordion();
const app = createApp(App);

app.use(
  createGtm({
    id: process.env.VUE_APP_GTAG,
    defer: false,
    compatibility: false,
    //nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false
  })
);


app
  .use(router)
  .use(vuex)
  .use(VueReCaptcha, { siteKey: process.env.VUE_APP_CATPCHA_PUBLIC })
  .directive("dragscroll", dragscrollNext)
  .mount("#app");

import axiosFetch from "axios";
app.provide("$axios", axiosFetch);

import dayjs from "dayjs";
require("dayjs/locale/es");
app.provide("$dayjs", dayjs);
