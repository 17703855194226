<template>
  <div>
    <div class="mb-60 text-center" style="background-color: #0176DE; height: 300px">
      <img class="mt-40" src="https://kit-digital-uc-desa.s3.amazonaws.com/img/logo-uc-blanco.svg" alt="" style="height: 10rem;">
    </div>
    <div class="container mb-60" style="margin-top: -160px">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div id="error-404" class="uc-card card-bg--gray">
            <div class="uc-card_body--xl">
              <div class="uc-subtitle"></div>
              <div class="h1 mb-24">{{ title }}</div>
              <p v-html="subtitle"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    title: process.env.VUE_APP_MAINTENANCE_TITLE ? process.env.VUE_APP_MAINTENANCE_TITLE : "¡Nos vemos pronto!",
    subtitle: process.env.VUE_APP_MAINTENANCE_SUBTITLE ? process.env.VUE_APP_MAINTENANCE_SUBTITLE : "A partir del 18 de octubre podrás visitar la Expo Futuro Novato y Novata UC 2022"
  })
}
</script>