//x e y aceptan valores de -100 a 100
const setLeft = async () => {

}
const setTop = async () => {
}
const setCenter = async (map, x, y) => {
  map.center = {
    x: parseInt(x),
    y: parseInt(y)
  }
}
const center = async (map) => {
  const params = new URLSearchParams(window.location.search)
  await setCenter(map, params.get('x'), params.get('y'))
  setLeft(map)
  setTop(map)
  //map.component.scroll({
  //    left: map.left,
  //    top: map.top,
  //    behavior: 'smooth'
  //})
}
const getScales = (map) => {
  const footer = document.querySelector('footer').offsetHeight
  const header = document.querySelector('header').offsetHeight
  map.scale = {
    x: map.size.x - document.body.offsetWidth,
    y: map.size.y - document.body.offsetHeight + header + footer
  }
}
const watch = (map) => {
  map.component.onwheel = () => {
    updateTranslate(map)
  }
}
const updateTranslate = (map) => {
  map.translate.y = map.component.scrollTop
  map.translate.x = map.component.scrollLeft
}

export { center, getScales, watch}
