<template>
  <div>
    <div class="single-career-img">
      <img :src="imagenPrincipal" :alt="`imagen principal de ${data.nombre}`">
    </div>

    <div class="single-career-content">
      <div
        v-if="
          data.grado_academico ||
          data.titulo ||
          data.arancel ||
          data.vacantes ||
          data.codigo"
        class="bloque texto"
      >
        <h3>Pregrado</h3>
        
        <div v-show="data.grado_academico" class="list-fields">
          <strong>Grado Académico: </strong> {{data.grado_academico}}
        </div>
        <div v-show="data.titulo" class="list-fields">
          <strong>Título Profesional: </strong> {{data.titulo}}
        </div>
        <div v-show="data.arancel" class="list-fields">
          <strong>Arancel anual: </strong> {{data.arancel}}
        </div>
        <div v-show="data.vacantes" class="list-fields">
          <strong>Vacantes ofrecidas: </strong> {{data.vacantes}}
        </div>
        <div v-show="data.codigo" class="list-fields">
          <strong>Código DEMRE: </strong> {{data.codigo}}
        </div>
      </div>

      <div v-if="data.como_ingresar" class="bloque texto">
        <h3>¿Cómo ingresar?</h3>
        <div class="list-fields">
          {{ data.como_ingresar.includes('\n') ? data.como_ingresar.split('\n').join(', ') : data.como_ingresar }}
        </div>
      </div>

      <div v-if="data.notas || data.ranking || data.comprension_lectora || data.matematica || data.historia || data.ciencias" class="bloque texto requisitos">
        <div class="in">
          <h3>Ponderaciones</h3>
          <ul class="requisitos-ul">
            <li v-show="data.notas">
              <div>
                <span>{{data.notas}}%</span> Notas Enseñanza Media
              </div>
            </li>
            <li v-show="data.ranking">
              <div>
                <span>{{data.ranking}}%</span> Ranking
              </div>
            </li>
            <li v-show="data.comprension_lectora">
              <div>
                <span>{{data.comprension_lectora}}%</span> Prueba de Comprensión Lectora
              </div>
            </li>
            <li v-show="data.matematica">
              <div>
                <span>{{data.matematica}}%</span> Prueba de Matemática
              </div>
            </li>
            <li v-show="data.historia && !data.historia_o_ciencias">
              <div>
                <span>{{data.historia}}%</span> Prueba de Historia y Cs. Sociales
              </div>
            </li>
            <li v-show="data.ciencias && !data.historia_o_ciencias">
              <div>
                <span>{{data.ciencias}}%</span> Prueba de Ciencias
              </div>
            </li>
            <li v-show="data.historia_o_ciencias">
              <div>
                <span>{{data.historia_o_ciencias}}%</span> Prueba de Historia y Cs. Sociales o<br> Prueba de Ciencias
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="data.promedio_minimo || data.puntaje_minimo || data.puntaje_ultimo" class="bloque texto requisitos">
        <div class="in">
          <h3>Requisitos</h3>
          <ul class="requisitos-ul">
            <li v-if="data.promedio_minimo">
              <div>
                <span>{{data.promedio_minimo}}</span>Promedio Mínimo (C. LECT y MAT)
              </div>
            </li>
            <li v-if="data.puntaje_minimo">
              <div>
                <span>{{data.puntaje_minimo}}</span>Puntaje Ponderado Mínimo
              </div>
            </li>
            <li v-if="data.puntaje_ultimo">
              <div>
                <span>{{data.puntaje_ultimo}}</span>Puntaje último Matriculado 2021
              </div>
            </li>
          </ul>
        </div>
      </div>
      
      <div v-if="data.prueba_especial" class="bloque texto">
        <h3>Prueba especial</h3>
        {{data.prueba_especial ? 'Si' : 'No aplica'}}
      </div>

      <div v-if="data.perfil_egreso" class="bloque texto ">
        <h3>Perfil de egreso</h3>
        <div v-html="data.perfil_egreso"></div>
      </div>

      <div v-if="data.info_general" class="bloque texto">
        <h3 >Información general</h3>
        <div class="single-career-content" v-html="data.info_general">
        </div>
      </div>

    </div>
    <div v-show="mallaLink(data)">
      <h3 class="h5 mt-28 mb-8">Malla Curricular</h3>
      <a class="malla-curricular-link" target="_blank" :href="mallaLink(data)">
        <img src="@/assets/images/icon-pdf.svg" :alt="'malla curricular de '+data.nombre">
      </a>
    </div>
    <div v-if="images.length">
      <h3 class="h5 mt-28 mb-8">Imágenes</h3>
      <image-slider id="images" :data="images"/>
    </div>
    <div v-if="videos.length">
      <h3 class="h5 mt-28 mb-8">Videos</h3>
      <video-slider id="videos" :data="videos"/>
    </div>
    <div v-if="data.url_facebook || data.url_instagram || data.url_youtube" class="social-networks mt-8">
      <span>
        Síguenos
      </span>
      <div class="social-networks-items">
        <div v-if="data.url_facebook" class="social-networks-item">
          <a :href="data.url_facebook" target="_blank">
            <img src="/icons/facebook.svg" alt="logo de facebook">
          </a>
        </div>
        <div v-if="data.url_instagram" class="social-networks-item">
          <a :href="data.url_instagram" target="_blank">
            <img src="/icons/instagram.svg" alt="logo de instagram">
          </a>
        </div>
        <div v-if="data.url_youtube" class="social-networks-item">
          <a :href="data.url_youtube" target="_blank">
            <img src="/icons/youtube.svg" alt="logo de youtube">
          </a>
        </div>
        <div v-if="data.url_web" class="social-networks-item">
          <a :href="data.url_web" target="_blank" class="uc-icon">
            language
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoSlider from '@/components/VideoSlider'
import ImageSlider from '@/components/ImageSlider'

export default {
  name: 'StandData',
  props: ['data'],
  components: {
    VideoSlider,
    ImageSlider
  },
  data: () => ({
    errorCounter: 0
  }),
  computed: {
    imagenPrincipal () {
      if(this.data.imagen_principal)
        return this.$store.state.imagePath + this.data.imagen_principal
      if(this.images.length)
        return this.images[0].url
      return ''
    },
    videos () {
      if(!this.data?.multimedia?.length) return []
      return this.data.multimedia.filter(el => el.id_tipo_multimedia === 2)
    },
    images () {
      if(!this.data?.multimedia?.length) return []
      return this.data.multimedia
        .filter(el => el.id_tipo_multimedia === 1)
        .map(el => {
          if(!el.url.includes(this.$store.state.imagePath))
            el.url = this.$store.state.imagePath + el.url
          return el
        })
    }
  },
  methods: {
    mallaLink (data) {
      if(data.malla_curricular_url || data.malla_curricular){
        return this.$store.state.imagePath + data.malla_curricular
      }
      return null
    },
    replaceLink (e) {
      if(this.errorCounter === 0 && Object.keys(this.data).length) {
        e.target.src = this.data.imagen_principal
        this.errorCounter++
      }
    }
  }
}
</script>
<style lang="scss">
.single-career-img {
  object-fit: contain !important;
}
.malla-curricular-link{
  width: 70px;
  display: block;
  img {
    width: 100%;
  }
}
</style>
