<template>
  <aside class="side-data">
    <div class="container h100s">
      <div class="row h100s">
        <div class="col-12 col-md-6 align-center">
          <div :class="['side-data-info']">
            <a @click.stop="close()" class="side-data-close">
              <i class="uc-icon icon-shape--rounded">close</i>
            </a>
            <span>
              <h3 :class="['uc-btn btn-cta h6', {'margin-bottom':!showSide}]">
                {{ title }}
              </h3>
              <div v-show="false" v-if="!showSide" class="side-data-info-btns">
                <router-link :to="dataPath($route.path)" :class="['uc-btn btn-blue info-btn d-flex justify-content-between', {active: active === 'data'}]">
                  <span>
                    Más información
                  </span>
                  <i class="uc-icon icon-shape--rounded">arrow_forward</i>
                </router-link>
              </div>
              <div v-if="showSide" class="side-data-info-btns">
                <router-link :to="dataPath($route.path)" :class="['uc-btn btn-blue info-btn d-flex justify-content-between', {active: active === 'data'}]">
                  <span>
                    Información <br>general
                  </span>
                  <i class="uc-icon icon-shape--rounded">arrow_forward</i>
                </router-link>
                <router-link :to="charlasPath($route.path)" :class="['uc-btn btn-blue info-btn d-flex justify-content-between', {active: active === 'cronograma'}]">
                  <span>
                    Cronograma <br>charlas
                  </span>
                  <i class="uc-icon icon-shape--rounded">arrow_forward</i>
                </router-link>

              </div>
              <div v-if="showSide && $store.state.chatsActive" class="side-data-info-btns">
                <router-link :to="makeLink($route.path, '/chat')" class="uc-btn info-btn btn-primary d-flex justify-content-between active">
                  <span class="d-flex">
                    <div class="chat-icon">
                      <img src="@/assets/images/chat.svg" />
                    </div>
                    <span>
                      Chatea con <br>nosotros
                    </span>
                  </span>
                  <i class="uc-icon icon-shape--rounded">arrow_forward</i>
                </router-link>
              </div>
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 h100s">
          <div :class="['side-data-body h100s', {'p-0 overflow-hidden': $store.state.hidepadding}]">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  name: 'SideData',
  computed: {
    active () {
      return this.$route.path.includes('/charlas') ? 'cronograma' : 'data'
    }
  },
  props: {
    title: String,
    showSide: {
      type: Boolean,
      default: true
    },
    parent: {
      type: String,
      default: 'carreras'
    }
  },
  methods: {
    close () {
      const path = !this.$route.query.hideparent ? `/${this.$store.state.prefix}mapa/` + this.parent : `/${this.$store.state.prefix}mapa`
      this.$router.push({ path: path })
    },
    makeLink (route, name) {
      const r = route.replace('/charlas', '')
      return r.includes(name) ? r : r + name
    },
    dataPath (route) {
      return route.replace('/charlas', '').replace('/chat', '')
    },
    charlasPath (route) {
      const r = route.replace('/chat', '')
      return r.replace('/chat', '').includes('/charlas') ? r : r + '/charlas'
    }
  }
}
</script>
<style lang="scss" scoped>
.side-data {
  background-color: rgba(var(--dark-blue), 0.85);
  position: fixed;
  height: calc(100vh - var(--top) - var(--bottom));
  top: var(--top);
  right: 0;
  z-index: 100;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: scroll;
  @media screen and (min-width: 768px){
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
  .side-data-close {
    position: absolute;
    z-index: 999;
    padding: 10px;
    top: -40px;
    right: 0;
    cursor: pointer;
    i {
      font-size: 2rem;
    }
  }
  .h100s {
    max-height: fit-content;
    @media screen and (min-width: 768px){
      height: 100%;
    }
  }
  .align-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .side-data-info {
    position: relative;
    width: 100%;
    &.hide-side {
      height: 100%;
      @media screen and (min-width: 768px){
        .side-data-close{
          top: 0;
        }
      }
    }
    .side-data-info-title {
      background-color: var(--yellow);
      width: fit-content;
      margin: auto;
      padding: 10px 14px;
      border-radius: 4px;
    }
    .btn-cta {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .side-data-info-btns {
    margin: 30px auto;
    max-width: 300px;
    .info-btn{
      font-weight: bold;
      margin: auto;
      padding: 12px 16px;
      &.btn-blue {
        color: white;
        background-color: var(--light-blue);
        span {
          opacity: .5;
          transition: ease-in-out all .1s;
        }
        &.active {
          box-shadow: 0px .1rem 2em var(--blue);
          span {
            transform: scale(1.1);
            opacity: 1;
          }
        }
      }
      &:nth-child(1) {
        margin-bottom: 1px;
      }
    }
  }
  .side-data-body {
    background-color: white;
    padding: 30px;
    overflow: hidden;
    @media screen and (min-width: 768px){
      overflow-y: scroll;
      top: 0;
      right: 0;
      width: 50vw;
    }
    &.overflow-hidden {
      overflow: hidden!important;
    }
  }
}
.chat-icon {
  --size: 48px;
  margin-right: 10px;
  width: var(--size);
  height: var(--size);
}
.disabled {
  display: none !important;
}
</style>
<style lang="scss">
iframe{
  &.chat-iframe{
    min-height: 50vh;
    width: 100%;
    height: 100%;
    border: none;
  }
}
.margin-bottom {
  margin-bottom: 2rem;
}
</style>
