<template>
  <div
    class="with-background-sticky"
    style="min-height: 100vh"
    :style="{ backgroundImage: 'url(' + page.imagenFondo + ')' }"
  >
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="m-12">
        <img class="logo-uc" :src="page.logo" alt="" />
      </div>

      <div
        v-if="showMessage"
        style="background-color: white"
        class="uc-alert warning my-12"
      >
        <div class="uc-alert_content">
          <i class="uc-icon icon-size--sm">warning</i>
          <span class="p p-size--sm bold ml-8">{{ messageContent }}</span>
        </div>
      </div>

      <div class="uc-card mt-16 mb-36" style="max-width: 500px">
        <div class="uc-card_body">
          <p>
            A continuación ingrese la nueva contraseña, repitala y haga clic en
            enviar.
          </p>
          <p>*Debe tener al menos 8 caracteres</p>
          <form>
            <div class="uc-form-group">
              <label for="ucinputRut"
                ><i class="uc-icon">key</i> Nueva contraseña (*)</label
              >
              <input
                v-model="form.password"
                id="ucinputRut"
                type="text"
                class="uc-input-style"
                placeholder="Ingrese nueva contraseña"
              />
            </div>

            <div class="uc-form-group">
              <label for="ucinputRut"
                ><i class="uc-icon">key</i> Repita la contraseña (*)</label
              >
              <input
                v-model="form.password_confirmation"
                id="ucinputRut"
                type="text"
                class="uc-input-style"
                placeholder="Repita contraseña"
              />
            </div>

            <a href="/login" class="link-inline"> Volver al login </a>
            <div class="d-flex justify-content-end">
              <button
                :disabled="loading"
                @click.prevent="authenticate"
                class="uc-btn btn-primary btn-inline"
              >
                Enviar
                <i class="uc-icon">arrow_forward</i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { checkLogin } from "../helpers/auth.js";

export default {
  name: "Recuperar",
  components: {},
  props: {
    msg: String
  },
  setup() {
    return { checkLogin };
  },
  created() {
    this.form.token = this.$route.query.token;
    this.form.email = this.$route.query.email;
    this.fetchPageData();
    const logged = JSON.parse(localStorage.getItem("logged"))
    const user = JSON.parse(localStorage.getItem("user"))
    this.checkLogin(logged, user);
  },
  data() {
    return {
      showMessage: false,
      messageContent: "",
      loading: false,
      page: {
        titulo: "",
        logo: "",
        imagenFondo: "",
        imagenPromocional: ""
      },
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        token: ""
      }
    };
  },
  methods: {
    fetchPageData() {
      window.axios
        .get("/api/page/login")
        .then(({ data }) => {
          if (data) {
            this.page.titulo = data.titulo;
            this.page.logo = data.logo_url;
            this.page.imagenFondo = data.imagen_fondo_url;
            this.page.imagenPromocional = data.imagen_promocional_url;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    async getCsrfCookie() {
      await window.axios.get("/sanctum/csrf-cookie");
    },
    async authenticate() {
      if (this.validInputs()) {
        this.loading = true;
        await this.getCsrfCookie();
        window.axios
          .post("/api/reset-password", this.form)
          .then(({ data }) => {
            if (data.error == "Authenticated") {
              this.$router.push("/");
            } else {
              (this.form.password = ""), (this.form.password_confirmation = "");
              ElMessage({
                showClose: true,
                message:
                  "La contraseña se ha restablecido correctamente, redirigiendo al login en 10 segundos...",
                type: "success",
                duration: 10000
              });
            }
            setTimeout(() => {
              this.$router.push("/login");
            }, 10000);
            this.loading = false;
          })
          .catch(e => {
            this.triggerMessage(e.response.data, 10000);
            this.loading = false;
          });
      }
    },
    triggerMessage(message, duration) {
      this.showMessage = true;
      this.messageContent = message;
      setTimeout(() => {
        this.showMessage = false;
      }, duration);
    },
    validInputs() {
      if (this.form.password === "" && this.form.password_confirmation === "") {
        this.triggerMessage(
          `Debe ingresar la nueva contraseña y repetirla.`,
          10000
        );
        return false;
      }
      if (this.form.password != this.form.password_confirmation) {
        this.triggerMessage(
          `Los campos nueva contraseña y repita la contraseña deben ser identicos.`,
          10000
        );
        return false;
      }
      if (this.form.password.length < 8) {
        this.triggerMessage(
          `La nueva contraseña debe tener mínimo 8 caracteres.`,
          10000
        );
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped>
.logo-uc {
  width: 140px;
}
</style>
