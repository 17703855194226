<template>
  <div
    id="profile-view"
    :style="{ backgroundImage: 'url(' + page.imagenFondo + ')' }"
  >
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="uc-card mt-16 mb-36 registro" style="overflow:visible">
        <div class="uc-card_body container">
          <h3 class="mb-24 text-center">Mi perfil</h3>
          <form action="">
            <div class="uc-form-group disabled">
              <label for="nombre"
                ><i class="uc-icon">account_circle</i> Nombres (*)</label
              >
              <input
                id="nombre"
                v-model="form.nombre"
                type="text"
                maxlength="250"
                class="uc-input-style"
                placeholder=""
              />
            </div>
            <div class="uc-form-group disabled">
              <label for="apellido"
                ><i class="uc-icon">account_circle</i> Apellidos (*)</label
              >
              <input
                v-model="form.apellidos"
                id="apellido"
                type="text"
                maxlength="250"
                class="uc-input-style"
                placeholder=""
              />
            </div>
            <div class="mb-16 disabled">
              <div class="uc-form-group uc-radio-group mb-8">
                <input
                  type="radio"
                  id="switch_1"
                  v-model="tipoDocumento"
                  value="rut"
                  class="uc-orig-radio"
                  checked=""
                />
                <label for="switch_1" class="uc-radio-button"> RUT </label>
                <input
                  type="radio"
                  id="switch_2"
                  v-model="tipoDocumento"
                  value="pasaporte"
                  class="uc-orig-radio"
                />
                <label for="switch_2" class="uc-radio-button">
                  PASAPORTE
                </label>
              </div>
            </div>

            <div v-if="tipoDocumento == 'rut'" class="uc-form-group disabled">
              <label for="rut"
                ><i class="uc-icon">admin_panel_settings</i> RUT (*)</label
              >
              <input
                id="rut"
                type="text"
                @blur="rutFocusOut"
                v-model="form.rut"
                maxlength="11"
                class="uc-input-style"
                placeholder="Sin puntos ni guion"
              />
            </div>

            <div v-if="tipoDocumento == 'pasaporte'" class="uc-form-group disabled">
              <label for="rut"
                ><i class="uc-icon">admin_panel_settings</i> PASAPORTE
                (*)</label
              >
              <input
                id="rut"
                type="text"
                v-model="form.pasaporte"
                maxlength="20"
                class="uc-input-style"
                placeholder="Ingrese número de pasaporte"
              />
            </div>

            <div class="uc-form-group disabled">
              <label for="email">
                <i class="uc-icon mr-4">email</i>Email (*)</label
              >
              <input
                v-model="form.email"
                id="email"
                maxlength="250"
                type="text"
                class="uc-input-style"
              />
            </div>
            <div class="uc-form-group">
              <label for="telefono"
                ><i class="uc-icon">smartphone</i> Celular de contacto
                (*)</label
              >
              <input
                id="telefono"
                v-model="form.telefono"
                name="telefono"
                maxlength="20"
                type="text"
                class="uc-input-style"
              />
            </div>
            <div class="uc-form-group">
              <label for="id_visitante"
                ><i class="uc-icon">label</i> ¿Qué tipo de visitante eres? (*)</label
              >
              <select
                v-model="form.id_visitante"
                class="uc-input-style"
                name="id_visitante"
                id="id_visitante"
              >
                <option disabled value="">Seleccione opción</option>
                <option
                  v-for="(visitante, vIndex) in listadoVisitantes"
                  :key="vIndex"
                  :value="visitante.id"
                >
                  {{ visitante.nombre }}
                </option>
              </select>
            </div>
            <div
              v-if="form.id_visitante == 12"
              class="uc-form-group"
              id="div_option_visitante"
            >
              <label for="option_visitante"
                ><i class="uc-icon">label</i> Indique el tipo de visitante (*)</label
              >
              <input
                id="option_visitante"
                name="option_visitante"
                v-model="form.opcion_visitante"
                type="text"
                class="uc-input-style"
                placeholder=""
              />
            </div>
            <div class="uc-form-group">
              <label for="id_region"
                ><i class="uc-icon">label</i> Región (*)</label
              >
              <select
                v-model="form.id_region"
                class="uc-input-style"
                name="id_region"
                id="id_region"
                @change="regionChanged"
              >
                <option disabled value="">Seleccione región</option>
                <option
                  v-for="(region, rIndex) in listadoRegiones"
                  :key="rIndex"
                  :value="region.id"
                >
                  {{ region.nombre }}
                </option>
              </select>
            </div>

            <div class="uc-form-group">
              <label for="id_comuna"
                ><i class="uc-icon">label</i> Comuna de residencia (*)</label
              >
              <select
                v-model="form.id_comuna"
                class="uc-input-style"
                name="id_comuna"
                id="id_comuna"
                :disabled="!listadoComunasFiltradas.length"
              >
                <option disabled value="">Seleccione comuna</option>
                <option
                  v-for="(comuna, index) in listadoComunasFiltradas"
                  :key="index"
                  :value="comuna.id"
                >
                  {{ comuna.nombre }}
                </option>
              </select>
            </div>
            <div v-if="false" class="uc-form-group">
              <label for="id_colegio"
                ><i class="uc-icon">label</i> Colegio (*)</label
              >
              <uc-select palceholder="Seleccione el colegio" v-model="form.id_colegio" @change="colegioChanged" :options="listadoColegios">
                <uc-select-option :options="listadoColegios" />
              </uc-select>
            </div>

            <div
              v-if="showOtherCollegeInput"
              class="uc-form-group"
              id="div_option_colegio"
            >
              <label for="option_colegio"
                ><i class="uc-icon">label</i> En caso de no encontrar tu
                colegio, ingrésalo aquí (*)</label
              >
              <input
                id="option_colegio"
                name="option_colegio"
                v-model="form.otroColegio"
                type="text"

                class="uc-input-style"
              />
            </div>

            <div v-if="false" class="uc-form-group">
              <label for="carreras"
                ><i class="uc-icon">label</i> ¿Qué carreras te interesan?
                (*)</label
              >
              <uc-select
                palceholder="Seleccione las carreras"
                v-model="form.carreras"
                :options="listadoCarreras"
                :limit="9999"
                multiple
              >
                <uc-select-option :options="listadoCarreras" />
              </uc-select>
            </div>
            <div style="display: none" class="uc-form-group">
              <label for="ucinput"
                >Dentro de la Expo tendrás acceso a un ensayo de la Prueba de
                Transición, ¿Deseas rendirla ? (*)</label
              >
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input
                    type="radio"
                    class="radio_ensayo form-check-input"
                    name="ensayo"
                    value="si"
                  />
                  Si
                  <input
                    type="radio"
                    class="radio_ensayo form-check-input"
                    checked
                    name="ensayo"
                    value="no"
                  />
                  No
                </label>
              </div>
              <small class="form-text" id="text_ensayo" hidden
                >Para ingresar al ensayo, tu usuario es el RUT y tu contraseña
                son los 4 primeros dígitos del RUT.</small
              >
            </div>
            <div class="uc-form-group">
              <label for="ucinput"
                >Si deseas cambiar tu contraseña ingresa a <a href="/recuperar-clave">recuperar clave</a> para recibir una nueva contraseña en tu correo</label
              >
            </div>
            <div>
              <div
                v-for="(error, index) in errors"
                :key="index"
                class="uc-alert error mb-12"
              >
                <div class="uc-alert_content">
                  <i class="uc-icon icon-size--sm">cancel</i>
                  <span class="p p-size--sm bold ml-8">{{ error }}</span>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <a
                :disabled="loading"
                :href="`/${$store.state.prefix}mapa`"
                class="uc-btn btn-primary btn-inline"
              >
                Volver
                <i class="uc-icon">arrow_back</i>
              </a>
              <button
                :disabled="loading"
                @click.prevent="updateUser(form)"
                class="uc-btn btn-primary btn-inline"
              >
                Guardar
                <i class="uc-icon">save</i>
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import RutHelper from "../helpers/Rut";
import UcSelect from "@/components/UcSelect"
import UcSelectOption from "@/components/UcSelectOption"
import { ElMessage } from "element-plus";
export default {
  components: {
    UcSelect,
    UcSelectOption
  },
  data: () => ({
    showOtherCollegeInput: false,
    form: {
      celular: '',
      tipoVisitante: '',
      tipoVisitanteOtro: '',
      region: '',
      comuna: '',
      colegio: '',
      otroColegio: '',
      carreras: '',
      condicion: ''
    },
    listadoComunasFiltradas: [],
    listadoCarreras: [],
    listadoColegios: [],
    listadoVisitantes: [],
    listadoRegiones: [],
    listadoComunas: [],
    errors: [],
    loading: false,
    page: {}
  }),
  created () {
    this.fetchPageData();
    this.fetchRegisterFields();
  },
  computed: {
    tipoDocumento () {
      if(!this.form) return 'rut'
      return this.form.rut === null ? 'pasaporte' : 'rut'
    }
  },
  mounted () {
    this.form = JSON.parse(window.localStorage.getItem("user"))
    this.colegioChanged()
    this.listadoComunas = this.listadoComunasFiltradas
  },
  methods: {
    fetchPageData() {
      window.axios
        .get("/api/page/registro")
        .then(({ data }) => {
          if (data) {
            this.page.titulo = data.titulo;
            this.page.logo = data.logo_url;
            this.page.imagenFondo = data.imagen_fondo_url;
            this.page.imagenPromocional = data.imagen_promocional_url;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    updateUser() {
      window.axios.post('/api/user', this.form)
        .then(res => {
          ElMessage({
            showClose: true,
            message:
              "Los datos han sido actualizados correctamente...",
            type: "success",
            duration: 10000
          });
          localStorage.setItem('user', JSON.stringify(res.data))
        })
    },
    rutFocusOut() {
      if (this.form.rut) {
        this.form.rut = RutHelper.format(this.form.rut);
      }
    },
    fetchRegisterFields() {
      window.axios.get("/api/register").then(({ data }) => {
        let { carreras, colegios, comunas, regiones, visitantes } = data;
        this.sortAlphabetically(carreras);
        this.listadoCarreras = carreras.map(col => {
          return {
            value: col.id,
            label: col.nombre
          };
        });
        // eslint-disable-next-line no-unused-vars
        let oIndex = null;
        this.listadoColegios = colegios.map((col, index) => {
          if (col.nombre == "Otra opción") {
            oIndex = index;
          }
          return {
            value: col.id,
            label: col.nombre
          };
        });

        if (oIndex) {
          let otherOption = this.listadoColegios.splice(oIndex, 1);
          this.listadoColegios.unshift(otherOption[0]);
        }

        this.listadoComunas = comunas;
        this.listadoComunasFiltradas = comunas;
        this.listadoRegiones = regiones;
        this.listadoVisitantes = visitantes;
      });
    },
    sortAlphabetically(array) {
      this.listadoCarreras = array.sort(function(a, b) {
        if (a.nombre < b.nombre) {
          return -1;
        }
        if (a.nombre > b.nombre) {
          return 1;
        }
        return 0;
      });
    },
    colegioChanged(i) {
      this.form.otroColegio = "";
      this.showOtherCollegeInput = i == 3623;
    },
    regionChanged() {
      this.listadoComunasFiltradas = [];
      this.listadoComunasFiltradas = this.listadoComunas.filter(
        c => c.id_region == this.form.region
      );
    }
  }
}
</script>
<style scoped>
  #profile-view {
    height: calc(100vh - var(--top) - var(--bottom));
    overflow-y: scroll;
    background-size: cover;
  }
</style>