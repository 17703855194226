<template>
<div class="charlas-container">
  <h3 v-if="Object.entries(charlasActive).length">Charlas del día</h3>
  <div
    v-for="(day, dayIndex) in Object.entries(charlasActive)"
    :key="'day-'+dayIndex"
    class="col-12"
  >
    <h3 class="h3 mb-8">
      {{$dayjs(formatDate(day[0])).locale('es').format('DD')}}
      de
      <span class="capitalize">{{$dayjs(formatDate(day[0])).locale('es').format('MMMM')}}</span>
    </h3>
    <div class="row">
      <div
        v-for="(charla, charlaIndex) in day[1]"
        :key="'charlaIndex-'+charlaIndex"
        :class="`col-12 col-md-${cols} charla-item`"
        style="margin-bottom: 2rem"
      >
        <charla-item :charla="charla"/>
      </div>
    </div>
  </div>
  <h3 v-if="Object.entries(charlasFuture).length">Proximas charlas</h3>
  <div
    v-for="(day, dayIndex) in Object.entries(charlasFuture).reverse()"
    :key="'day-'+dayIndex"
    class="col-12"
  >
    <h3 class="h3 mb-8">
      {{$dayjs(formatDate(day[0])).locale('es').format('DD')}}
      de
      <span class="capitalize">{{$dayjs(formatDate(day[0])).locale('es').format('MMMM')}}</span>
    </h3>
    <div class="row">
      <div
        v-for="(charla, charlaIndex) in day[1]"
        :key="'charlaIndex-'+charlaIndex"
        :class="`col-12 col-md-${cols} charla-item`"
        style="margin-bottom: 2rem"
      >
        <charla-item :charla="charla"/>
      </div>
    </div>
  </div>
  <h3 v-if="Object.entries(charlasInactive).length">
    Charlas pasadas
  </h3>
  <div
    v-for="(day, dayIndex) in Object.entries(charlasInactive)"
    :key="'day-'+dayIndex"
    class="col-12"
  >
    <h3 class="h3 mb-8">
      {{$dayjs(formatDate(day[0])).locale('es').format('DD')}}
      de
      <span class="capitalize">{{$dayjs(formatDate(day[0])).locale('es').format('MMMM')}}</span>
    </h3>
    <div class="row">
      <div
        v-for="(charla, charlaIndex) in day[1]"
        :key="'charlaIndex-'+charlaIndex"
        :class="`col-12 col-md-${cols} charla-item`"
        style="margin-bottom: 2rem"
      >
        <charla-item :charla="charla" :hide-hour="true"/>
      </div>
    </div>
  </div>
  <div v-if="loaded && !Object.entries(charlas).length" class="no-data">
    <div class="uc-message warning mb-32">
    <div class="uc-message_body">
      <i class="uc-icon warning-icon">event_busy</i>
      <h3>No se encontraron charlas disponibles para esta categoría</h3>
    </div>
</div>
  </div>
</div>
</template>
<script>
import { groupBy } from "@/helpers/GroupBy"
import CharlaItem from '@/components/CharlaItem'
const internationalDateFormat = (d) => {
  const arr = d.split(' ');
  return `${arr[0].split('-').reverse().join('-')} ${arr[1]}`
}
export default {
  name: 'CharlasComponent',
  inject: ['$dayjs'],
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    cols: {
      type: String,
      default: '4'
    }
  },
  components: {
    CharlaItem
  },
  computed: {
    charlasFuture () {
      if (!this.charlas) return []
      const filtered = this.charlas.filter(el => {
        const d = internationalDateFormat(el.fecha_santiago);
        return this.$dayjs(d).diff(this.$dayjs(), 's') > 0
      });
      console.log(filtered)
      return groupBy(filtered, 'fecha_raw', 'just_hour');
    },
    charlasActive () {
      if (!this.charlas) return []
      const filtered = this.charlas.filter(el => {
        const d = internationalDateFormat(el.fecha_santiago);
        return this.$dayjs(d).diff(this.$dayjs(), 's') == 0
      });
      return groupBy(filtered, 'fecha_raw', 'just_hour');
    },
    charlasInactive () {
      if (!this.charlas) return []
      const filtered = this.charlas.filter(el => {
        const d = internationalDateFormat(el.fecha_santiago);
        return this.$dayjs(d).diff(this.$dayjs(), 's') < 0
      });
      console.log(filtered)
      return groupBy(filtered, 'fecha_raw', 'just_hour');
    },
    charlas(){
      let data = this.$store.state.charlas;
      if(this.data) {
        data = data.filter(el => this.data.id === el.id_stand)
      }
      data = data.map((el) => {
        el.fecha_raw = el.fecha_santiago.split(' ')
        if(el.fecha_raw.length) {
          const d = [...el.fecha_raw]

          el.regular_date = d[0].split('-').reverse().join('-')+' '+d[1]

          el.just_hour = el.fecha_raw[1]?.split(':').join('')
          el.fecha_raw = el.fecha_raw[0]
          el.intDate = parseInt(el.fecha_raw.split('-').reverse().join(''))
        }
        return el
      })

      data.sort((a, b) => b.intDate - a.intDate)
      //data = groupBy(data, 'fecha_raw', 'just_hour')
      return data
    },
    loaded () {
      return this.$store.state.charlas.length
    }
  },
  async mounted () {
    await this.$store.dispatch('getCharlas')
  },
  methods: {
    name () {
      if(this.$route.fullPath.includes('/servicios/')) return 'para el servicio'
      if(this.$route.fullPath.includes('/carreras/')) return 'para la carrera'
      
    },
    formatDate (d) {
      return d.split('-').reverse().join('-')
    },
    showDropDown (charla) {
      charla.show = !charla.show
    }
  }
}
</script>