import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Map from "@/views/Map.vue";
//import Map2 from "@/views/Map2.vue";
import Layout from "@/layouts/Default.vue";
import Login from "@/views/Login.vue";
import Registro from "@/views/Registro.vue";
import ChatWithCareers from "@/views/Stands/ChatWithCareers.vue";
import ChatWithServices from "@/views/Stands/ChatWithServices.vue";
import SingleCareer from "@/views/SingleCareer.vue";
import MarkerInfo from "@/views/MarkerInfo.vue";

import RecuperarClave from "@/views/RecuperarClave.vue";
import CambiarClave from "@/views/CambiarClave.vue";
import Charlas from "@/views/Charlas/Index.vue";
import CharlasVideo from "@/views/Charlas/CharlasVideo.vue";
import NestedViews from "@/layouts/NestedViews";

import StandData from "@/views/Stands/StandData";
import CareersList from "@/views/Stands/CareersList";
import ServicesList from "@/views/Stands/ServicesList"

import KnowUc from "@/views/KnowUc/Index.vue";
import KnowUcList from "@/views/KnowUc/KnowUcList"

import Maintenance from "@/views/Maintenance.vue";

import CharlasComponent from "@/components/CharlasComponent.vue";
import ChatComponent from "@/components/ChatComponent.vue";

import Perfil from "@/views/Perfil.vue";
const hiddenPrefix = process.env.VUE_APP_HIDDEN_MAP_PREFIX ? process.env.VUE_APP_HIDDEN_MAP_PREFIX : ''

const mapa = {
    path: `/${hiddenPrefix}mapa`,
    component: Map,
    children: [
        //CARRERAS
        {
            path: "carreras",
            component: ChatWithCareers,
            children: [{
                    path: "",
                    component: CareersList
                },
                {
                    path: ":slug",
                    component: SingleCareer,
                    children: [{
                            path: "",
                            name: "CarreraSingleData",
                            component: StandData
                        },
                        {
                            path: "charlas",
                            component: CharlasComponent
                        },
                        {
                            path: "chat",
                            component: ChatComponent
                        }
                    ]
                }
            ]
        },
        //SERVICIOS
        {
            path: "servicios",
            component: ChatWithServices,
            children: [{
                    path: "",
                    component: ServicesList
                },
                {
                    path: ":slug",
                    component: SingleCareer,
                    children: [{
                            path: "",
                            component: StandData
                        },
                        {
                            path: "charlas",
                            component: CharlasComponent
                        },
                        {
                            path: "chat",
                            component: ChatComponent
                        }
                    ]
                }
            ]
        },
        //STAND
        {
            path: "stand",
            component: ChatWithServices,
            children: [{
                path: ":slug",
                component: SingleCareer,
                name: "singleStands",
            }]
        },
        //CONOCE MAS DE LA UC
        {
            path: "conoce-mas-de-la-uc",
            component: KnowUc,
            children: [{
                    path: "",
                    component: KnowUcList,
                    name: "KnowUcList"
                },
                {
                    path: ":slug",
                    component: MarkerInfo,
                    name: "markerInfo",
                }
            ]
        },
        //CHARLAS
        {
            path: "charlas",
            component: NestedViews,
            children: [{
                    path: "",
                    component: Charlas,
                    name: "Charlas",
                },
                {
                    path: ":slug",
                    component: CharlasVideo,
                    name: "CharlasVideo",
                }
            ]
        }
    ]
}


let homePages = {
    path: "/",
    component: Layout,
    meta: {
        authenticated: true,
    },
    children: [
        {
            path: "",
            name: "Home",
            component: Home
        },
        {
            path: "/perfil",
            name: "Perfil",
            component: Perfil
        },
        { ...mapa }
    ]
}

//is maintenance mode
if (process.env.VUE_APP_MAINTENANCE && process.env.VUE_APP_MAINTENANCE.toLocaleLowerCase() == 'true') {
    homePages = {
        path: "/:catchAll(.*)",
        component: Maintenance
    }
}

const routes = [
    { ...homePages },
    {
        path: "/stand/:slug",
        redirect: {
            name: "CarreraSingleData"
        }
    },
    {
        path: "/pronto",
        name: "Pronto",
        component: Maintenance
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/registro",
        alias: "/",
        name: "Registro",
        component: Registro,
    },

    {
        path: "/recuperar-clave",
        name: "Recuperar",
        component: RecuperarClave,
    },
    {
        path: "/cambiar-clave",
        name: "Cambiar",
        component: CambiarClave,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    //const free = ['/pronto', '/login', '/registro', '/recuperar', '/cambiar', '/'+process.env.VUE_APP_HIDDEN_MAP_PREFIX]
    //let found = false
    //for (let index = 0; index < free.length; index++) {
    //    if(to.fullPath.includes(free[index])) {
    //        found = true
    //        break;
    //    }
    //}
    if(process.env.NODE_ENV === 'local'){
        next()
        return
    }
    const free = [ 'Login', 'Cambiar', 'Recuperar', 'Registro', 'Pronto' ]
    const logged = JSON.parse(localStorage.getItem('logged'))
    if (!logged && !free.includes(to.name)) next({ name: 'Login' })
    else next()

})
export default router;