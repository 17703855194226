<template>
  <modal v-model="allways" modal-name="charlasModal" title="Programa Charlas">
    <div class="row">
      <charlas-component />
    </div>
  </modal>
</template>
<script>
import ModalMixin from '@/components/ModalMixin'
import CharlasComponent from '@/components/CharlasComponent'

export default {
  name: 'KnowUc',
  mixins: [ ModalMixin ],
  components: {
    CharlasComponent
  },
  data: () => ({
    modal: false
  })
}
</script>
