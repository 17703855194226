import Modal from './Modal.vue'
export default {
  components: {
    Modal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    active: false,
    allways: true,
    modal: true
  }),
  watch: {
    active () {
      if (!this.active) {
        this.close()
      }
    },
    modelValue () {
      this.active = this.modelValue
    }
  },
  methods: {
    close () {
      if(this.modal) {
        this.$emit('update:modelValue', false)
      }
    }
  }
}