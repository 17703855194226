<template>
  <modal v-if="$store.state.charlas.length" v-model="allways" modal-name="charlasVideoModal" :title="currentCharla.titulo" :close-path="`/${$store.state.prefix}mapa/charlas`">

    <div class="row">
      <div class="col-12 col-md-8 mb-12">
        <div class="iframe-container">
          <iframe :src="`https://www.youtube.com/embed/${currentCharla.codigo_youtube}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-12">
        <iframe :src="LIVE_CHAT_BASE + currentCharla.codigo_youtube+'&embed_domain=' + url" />
      </div>
    </div>
  </modal>
</template>
<script>
import ModalMixin from '@/components/ModalMixin'

export default {
  name: 'CharlasVideo',
  mixins: [ ModalMixin ],
  computed: {
    currentCharla () {
      const charlas = this.$store.state.charlas
      if (!charlas.length) return {}
      return charlas.find(el => el.slug === this.$route.params.slug)
    }
  },
  data: () => ({
    LIVE_CHAT_BASE: 'https://www.youtube.com/live_chat?v=',
    url: location.host
  }),
  mounted () {
    this.$store.dispatch('getCharlas')
  }
}
</script>
<style lang="scss" scoped>
.iframe-container{
  height: 0;
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  iframe{
    position: absolute;
  }
}
iframe{
  width: 100%;
  height: 100%;
}
</style>
