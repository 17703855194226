import mapSize from "./mapSize.js"
import * as mapCenter from './mapCenter.js'

export default {
  element: {},
  component: {},
  map: {},
  width: 0,
  height:0,
  left: 0,
  top: 0,
  zoom: 0.4,
  //in px
  size: {
    x: 0,
    y: 0
  },
  //max
  scale: {
    x: 0,
    y: 0
  },
  //actual scroll
  //position x => [0, 100], y => [0, 100]
  center: {
    x: 50,
    y: 50
  },
  centerMap () {
    mapCenter.center(this)
  },
  init (el) {
    this.element = document.querySelector(el)
    this.component = document.querySelector('#map-markers-component')
    this.markers = document.querySelector('#markers')

    this.initial = mapSize.initialSize(this)

    mapSize.setSize(this)
    this.size = mapSize.getSize(this)
    mapCenter.getScales(this)
    mapCenter.center(this)
    //mapCenter.watch(this)
    //this.component.onclick = (e) => {
    //  console.log(e)
    //}
  },
  zoomUp (step = 0.1) {
    this.zoom -= step*1
    mapSize.setSize(this)
    mapCenter.getScales(this)
    mapCenter.center(this)
  },
  zoomDown (step = 0.1) {
    this.zoom += step*1
    mapSize.setSize(this)
    mapCenter.getScales(this)
    mapCenter.center(this)
  }
}