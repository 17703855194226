<template>
  <modal v-model="allways" modal-name="chatWithServices" title="Servicios UC" :close-path="`/${this.$store.state.prefix}mapa`">
    <list v-for="(service, index) in Object.entries(services)" :key="index" :data="service" />
  </modal>
</template>
<script>
import ModalMixin from '@/components/ModalMixin'
import List from '@/components/List'

export default {
  props: ['services'],
  mixins: [ ModalMixin ],
  components: {
    List
  },  
}
</script>