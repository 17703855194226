<template>
  <modal v-model="allways" modal-name="knowUc" title="Conoce más de la UC" :close-path="`/${$store.state.prefix}mapa`">
    <list2 :data="markers" />
  </modal>
</template>
<script>
import ModalMixin from '@/components/ModalMixin'
import List2 from '@/components/List2'

export default {
  name: 'KnowUcList',
  mixins: [ ModalMixin ],
  data: () => ({
    modal: false
  }),
  components: {
    List2
  },
  computed: {
    markers () {
      return this.$store.state.markers.filter(marker => marker.featured === 1)
    }
  },
  mounted() {
    this.$store.dispatch('getMarkers')
  }
}
</script>