<template>
  <div class="loader">
    <div class="loader-container">
      <logo-uc />
      <div class="loading">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="rotate(0 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(30 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(60 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(90 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(120 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(150 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(180 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(210 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(240 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(270 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(300 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
            </rect>
          </g><g transform="rotate(330 50 50)">
            <rect x="46.5" y="26.5" rx="3.5" ry="3.5" width="7" height="7" fill="#0176de">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
            </rect>
          </g>
        </svg>
        <span>
          Cargando...
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import LogoUc from '@/components/LogoUc.vue'

export default {
  name: 'Loader',
  components: {
    LogoUc
  }
} 
</script>
<style lang="scss" scoped>
@keyframes shade {
  0%{
    opacity: .2;
  }
  50%{
    opacity: .5;
  }
  100%{
    opacity: .8;
  }
}
.loader{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 9999;
  top: 0;
  .loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    @media screen and (min-width: 660px) {
      flex-direction: row;
      .line {
        display: block;
      }
    }
    .loading{
      position: absolute;
      display: flex;
      align-items: center;
      color:var(--blue);
      bottom: -3rem;
      svg{
        width: 2rem;
        margin: 0;
        height: fit-content;
      }
      span{
        animation: shade 1s infinite;
      }
    }
    .line{
      width: 1px;
      margin: 0 3rem;
      height: 1rem;
      background: transparent;
      @media screen and (min-width: 660px) {
        background: #EAEAEA;
        height: 10rem;
      }
    }
    svg{
      display: block;
      height: 10rem;
    }
  }
}
</style>
