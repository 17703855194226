<template>
  <div class="list">
    <div class="list-body">
      <ul>
        <li v-for="(item, index) in data" :key="index">
          <router-link :to="`${$route.fullPath}/${item.slug}`">{{ item.titulo }}</router-link>
          <i class="uc-icon icon-shape--rounded">arrow_forward</i>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    key: String,
    prefix: String,
    data: {
      type: Object,
      default: () => ({
        title: '',
        items: []
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.list{
  font-size: 1rem;
  .list-title{
    margin-bottom: 1rem;
    color: var(--blue);
  }
  .list-body{
    ul{
      list-style-type: none;
      display: block;
      padding: 0;
      column-count: 1;
      @media (min-width: $xs) {
        column-count: 2;
      }
      @media (min-width: $md) {
        column-count: 3;
      }
      @media (min-width: $lg) {
        column-count: 4;
      }
      li{
        position: relative;
        padding-right: 1.3rem;
        margin-bottom: 10px;
        &:hover {
          i {
            opacity: 1;
            transform: translateX(0);
          }
        }
        a{
          font-size: 1rem;
          color: black;
          text-decoration: none;
        }
        i {
          opacity: 0;
          transform: translateX(-10px);
          transition: ease-in-out transform 0.5s, opacity .3s;
          position: absolute;
          right: 0;
          top: 50%;
          font-size: 0.7rem;
          margin-top: -0.7rem;
        }
      }
    }
  }
}
</style>
