<template>
<div class="register-container">
  <div
    class="with-background-sticky"
    :style="{ backgroundImage: 'url(' + page.imagenFondo + ')' }"
  >
    <div
      class="min-h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="m-12">
        <img class="logo-uc" :src="page.logo" alt="" />
      </div>

      <div class="uc-card w-100" style="max-width: 500px">
        <div class="uc-card_body">
          <img class="w-100" :src="page.imagenPromocional" alt="" />
        </div>
      </div>
      <div
        v-if="showMessage"
        style="background-color: white"
        class="uc-alert warning my-12"
      >
        <div class="uc-alert_content">
          <i class="uc-icon icon-size--sm">warning</i>
          <span class="p p-size--sm bold ml-8">{{ messageContent }}</span>
        </div>
      </div>
      <div class="uc-card mt-16 mb-36" style="max-width: 500px">
        <div class="uc-card_body">
          <p>
            Si eres novato o novata UC 2022, puedes ingresar con tu RUT, de lo contrario deberás registrarte para ingresar a la Feria
          </p>
          <form>
            <div class="uc-form-group">
              <label for="ucinputRut"
                ><i class="uc-icon">account_circle</i> Correo electrónico
                (*)</label
              >
              <input
                v-model="form.email"
                id="ucinputRut"
                type="text"
                class="uc-input-style"
                placeholder="Ingrese correo electrónico"
              />
            </div>

            <div class="uc-form-group">
              <label for="ucinputpassword">
                <i class="uc-icon mr-4">vpn_key</i>Contraseña (*)</label
              >
              <input
                id="ucinputpassword"
                autocomplete="on"
                v-model="form.password"
                type="password"
                class="uc-input-style"
              />
              <!--   <small>Tu contraseña son los 4 primeros dígitos del RUT.</small> -->
            </div>
            <a href="/recuperar-clave" class="d-block">
              ¿Se te ha olvidado la contraseña?
            </a>
            <a href="/registro" class="link-inline"> Registrarme </a>
            <div class="d-flex justify-content-end">
              <button
                :disabled="loading"
                @click.prevent="authenticate"
                class="uc-btn btn-primary btn-inline"
              >
                Ingresar
                <i class="uc-icon">login</i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
  <!--   <button @click="checkLogin">Checkear el login</button>
  <button @click="logout">Checkear el logout</button> -->
</template>

<script>
import { checkLogin, logout, me } from "../helpers/auth.js";

export default {
  name: "login",
  components: {},
  props: {
    msg: String
  },
  setup() {
    return { checkLogin, logout, me };
  },
  created() {
    this.fetchPageData();
    const logged = JSON.parse(localStorage.getItem("logged"))
    const user = JSON.parse(localStorage.getItem("user"))
    this.checkLogin(logged, user);
  },
  data() {
    return {
      showMessage: false,
      messageContent: "",
      loading: false,
      page: {
        titulo:
          "Si eres novato o novata UC 2022, puedes ingresar con tu RUT , de lo contrario deberás registrarte para ingresar a la Feria",
        logo: "",
        imagenFondo: "",
        imagenPromocional: ""
      },
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    fetchPageData() {
      window.axios
        .get("/api/page/login")
        .then(({ data }) => {
          if (data) {
            this.page.titulo = data.titulo;
            this.page.logo = data.logo_url;
            this.page.imagenFondo = data.imagen_fondo_url;
            this.page.imagenPromocional = data.imagen_promocional_url;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },

    async getCsrfCookie() {
      await window.axios.get("/sanctum/csrf-cookie");
    },
    async authenticate() {
      if (this.validInputs()) {
        this.loading = true;
        await this.getCsrfCookie();
        window.axios
          .post("/login", this.form)
          .then(() => {
            this.loading = false;
            localStorage.setItem("logged", true)
            this.me()
            this.$router.push("/");
          })
          .catch(() => {
            this.triggerMessage(
              `Correo electrónico o contraseña incorrectos`,
              5000
            );
            this.loading = false;
          });
      } else {
        this.triggerMessage(
          `Debe ingresar correo electrónico y contraseña.`,
          5000
        );
      }
    },
    triggerMessage(message, duration) {
      this.showMessage = true;
      this.messageContent = message;
      setTimeout(() => {
        this.showMessage = false;
      }, duration);
    },
    validInputs() {
      return this.form.email != "" && this.form.password != "";
    }
  }
};
</script>

<style scoped>
.logo-uc {
  width: 140px;
}
</style>
