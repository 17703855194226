<template>
  <div id="ChatWithServices">
    <router-view parent="servicios" :services="services"/>
  </div>
</template>
<script>
export default {
  name: 'ChatWithServices',
  computed: {
    services () {
      return this.$store.state.services
    }
  },
  mounted() {
    this.$store.dispatch('getServices')
  }
}
</script>
