<template>
  <div class="uc-select-options-container">
    <div
      v-for="(option, optionIndex) in fixedOptions"
      :key="optionIndex"
      :class="['uc-select-option', {selected:selecteds.includes(option.value)}]"
      @click="pickOption(option, optionIndex)"
    >
      {{ option.label }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'UcOption',
  props: ['options', 'q'],
  data: () => ({
    selecteds: []
  }),
  watch: {
    "$parent.ids" () {
      this.selecteds = this.$parent.ids
    }
  },
  computed: {
    fixedOptions () {
      let options = [ ...this.options ]
      if (this.$parent.q && this.$parent.q.length && options.length) {
        options = options.filter((option) => {
          return option.label.toLowerCase().includes(this.$parent.q.toLowerCase());
        })
      }
      return options.slice(0, this.$parent.limit)
    }
  },
  methods: {
    pickOption (option) {
      
      if(this.$parent.multiple) {
        
        this.$parent.q2 = option.label
        if(!this.selecteds.includes(option.value)) {
          this.selecteds.push(option.value)
        } else {
          delete this.selecteds[this.selecteds.indexOf(option.value)]
        }
        this.selecteds = this.selecteds.filter(el => el !== null)
        this.$parent.ids = []
        this.$parent.ids = this.selecteds
        return
      }
      this.$parent.q = option.label
      this.$parent.id = option.value
      setTimeout(() => this.$parent.focused = false, 100)
    }
  },
  mounted () {
    this.selecteds = this.$parent.ids
  }
}
</script>
<style lang="scss" scoped>
.uc-select-options-container{
  max-height: calc(34px * 5);
  overflow-y: scroll;
  .uc-select-option{
    line-height: 34px;
    padding-left: 20px;
    cursor: pointer;
    &:hover{
      background-color: rgb(245, 247, 250) !important;
    }
    &.selected{
      &:after{
        content: "✓";
        display: block;
        float: right;
        left: -10px;
        position: relative;
      }
    }
  }
}
</style>
