<template>
  <section id="home" :class="{fadeout: fadeout}">
    <div class="bg-img">
      <img src="@/assets/images/map.jpg" alt="map image" >
    </div>
    <div class="text-center home-iframe-container">
      <h3 class="mb-12">
        ¡Te damos la bienvenida a vivir tu experiencia en la UC!
      </h3>

      <p>La Bienvenida Novata es una actividad mediante la cual podrás  conocer los diferentes servicios y apoyos estudiantiles que tenemos disponibles para acompañarte durante toda tu experiencia universitaria en la UC.</p>
      <p>Explora el mapa  e ingresa conocer los stands de servicios estudiantiles y todo lo que la UC tiene para ofrecerte.</p>
      <p> Esperamos que este primer acercamiento virtual con el campus te ayude a tener una idea general. </p>
      <p>¡No te preocupes! </p>
      <p>Prontamente podrás visitar, recorrer y conocer a tus compañeros/as, profesores, pares de otras generaciones y a toda la comunidad universitaria. </p>

      ¡Comienza a vivir tu experiencia en la UC!
      <iframe
        v-if="false"
        :src="iframeLink"
        title="Expo futuro novato y novata uc 2022"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div>
      <button @click="next()" class="uc-btn btn-cta">
        Comenzar&nbsp;<i class="uc-icon">arrow_forward</i>
      </button>
    </div>
  </section>
</template>
<script>

export default {
  data: () => ({
    fadeout: false,
    iframeLink: process.env.VUE_APP_HOME_VIDEO
  }),
  methods: {
    next () {
      this.fadeout = true
      setTimeout(()=> {
        this.$router.push({path: `/${this.$store.state.prefix}mapa`})
      }, 300)
    }
  }
}
</script>
<style lang="scss">
#home {
  background-color: rgba(var(--dark-blue), .85);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  .home-iframe-container{
    margin: auto;
    --width: 90vw;
    width: var(--width);
    position: relative;
    @media screen and (min-width: 600px) {
      --width: 50vw;
    }
    iframe{
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
    }
  }
  .limit {
    max-width: 700px;
    margin: auto;
  }
  p {
    margin: 0;
    font-size: 1rem;
  }
  &.fadeout {
    opacity: 0;
  }
  .uc-btn {
    margin: 30px auto;
  }
}
</style>
