<template>
  <div ref="modal" :data-modal="modalName" :class="['uc-modal', $store.state.modal, {active: modelValue}]">
    <div class="uc-modal_content uc-message">
      <a href="javascript:void(0)" class="uc-message_close-button" @click="close()"><i class="uc-icon icon-shape--rounded">close</i></a>
      <h2 class="modal-title">{{title}}</h2>
      <div class="uc-message_body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    closePath: {
      type: String,
      default: `/mapa`
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    title: String,
    modalName: String
  },
  methods: {
    close () {
      this.$router.push({ path: this.closePath })
    }
  },
  mounted () {
    this.$refs.modal.onclick = (e) => {
      e.stopPropagation()
    }
  }
}
</script>
<style lang="scss" scoped>
.uc-modal {
  z-index: 9999;
  background-color: rgba(var(--dark-blue), .85);
  padding-top: calc(var(--top) + 5rem);
  padding-bottom: calc(var(--bottom) + 5rem);
  .uc-message {
    .uc-message_body {
      padding: 20px;
      padding-top: 0;
      @media screen and (min-width: 768px){
        padding: 48px;
      }
    }
  }
}
.active {
  display: block;
}
.uc-message_close-button {
  top: -50px;
  right: 0;
  z-index: 1;
  i {
    padding: 10px;
  }
}
.modal-title {
  color: white;
  padding-right: 35px;
  top: -50px;
  position: relative;
  text-align: left;
  font-size: 1.3em;
  @media (min-width: $xs) {
    font-size: 1.5em;
    text-align: center;
  }
}
</style>