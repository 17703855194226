<template>
  <div @click="showDropDown(charla)" class="uc-accordion">
    <div class="uc-collapse uc-card">
      <a href="javascript:void(0);" class="uc-collapse_heading js-accordion-title" :data-collapse="`charla-${charla.slug}`">
        <h4 class="h6">{{ !hideHour ? `[${charlaTime}]&nbsp;` : '' }}{{ charla.stand_name }}</h4>
        <span class="uc-btn btn-inline ml-auto">
          <i class="uc-icon icon-shape--rounded">keyboard_arrow_down</i>
        </span>
      </a>
      <div :class="['uc-collapse_body js-accordion-content', {show:charla.show}]" data-open="false" :data-toggle="`charla-${charla.slug}`">
        <div class="uc-card_body">
          <div class="charla-info">
            <div class="charla-info-item">
              <strong>Charla</strong>
              <div>{{ charla.titulo }}</div>
            </div>
            <div class="charla-info-item">
              <strong>Relator</strong>
              <div>{{ charla.expositor }}</div>
            </div>
            <div class="charla-info-item">
              <strong>Reseña</strong>
              <div>{{ charla.lugar }}</div>
            </div>
            <br>
            <div v-if="charla.codigo_youtube" class="charla-info-item">
              <router-link :to="`/${$store.state.prefix}mapa/charlas/${charla.slug}`" class="uc-btn btn-cta">Ver charla</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CharlaItem',
  inject: ['$dayjs'],
  props: {
    charla: {
      type: Object,
      defatult: () => {}
    },
    hideHour: {
      type: Boolean,
      defatult: () => false
    }},
  computed: {
    charlaTime () {
      if(this.charla.fecha_santiago) {
        const d1 = this.charla.fecha_santiago.split(' ')
        return d1.length > 1 ? d1[1] : '---'
      }
      const d1 = this.charla.fecha.split(' ')
      return d1.length > 1 ? d1[1] : '---'
    }
  },
  methods: {
    formatDate (d) {
      return d.split('-').reverse().join('-')
    },
    showDropDown (charla) {
      charla.show = !charla.show
    }
  }
}
</script>
<style lang="scss">
  .uc-collapse_body.js-accordion-content {
    &:not(.show){
      height: 0;
    }
  }
</style>
