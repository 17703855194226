<template>
  <div class="uc-carousel"
    :data-carousel="id"
    data-type="carousel"
    data-items="1"
    data-slideby="1"
    data-loop="true"
    data-nav="true"
    data-controls="false"
    :data-navcontainer="'#carousel-nav-'+id">
    <div :class="id">

      <div
        v-for="(slide, index) in data"
        :key="'slide-'+index"
        class="uc-card card-radius--none card-border--none"
      >
        <div class="primary-carousel_item-content">
          <div class="iframe-container">
            <iframe
              :id="`video-${index}`"
              class="slide-youtube-iframe"
              :src="`https://www.youtube.com/embed/${slide.codigo_youtube}?enablejsapi=1`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

    </div>
    <div class="uc-carousel-nav primary-carousel-nav">
      <div class="carousel-nav-container">
        <ul class="carousel-nav-list" :id="'carousel-nav-'+id">
          <li
            v-for="(slide, index) in data"
            :key="'slide-'+index"
            class="carousel-nav-item"
            @click="stopAll(index)"
          >
            <div class="indicator"></div>
            <div class="thumbnail">
              <img :src="`https://img.youtube.com/vi/${slide.codigo_youtube}/0.jpg`" alt="thumbnail">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { UcCarousel } from "@digitaluc/uc-kitdigital/src/js/components/carousels";
export default {
  name: 'VideoSlider',
  props: ['data', 'id'],
  mounted () {
    if(this.data.length) new UcCarousel();
  },
  watch: {
    data () {
      if(this.data.length) new UcCarousel();
    }
  },
  methods: {
    playVideo (id) {
      document.querySelector(`#video-${id}`)?.contentWindow?.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*')
      return id
    },
    stopAll (index) {
      document.querySelectorAll('.slide-youtube-iframe').forEach(el => {
        el.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*')
      })
      this.playVideo(`video-${index}`)
    }
  }
}
</script>
<style lang="scss">
.iframe-container{
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  iframe{
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.uc-carousel{
  overflow: visible;
  .uc-carousel-nav{
    --width: 8rem;
    bottom: inherit !important;
    position: relative;
    .carousel-nav-container{
      width: 100%;
      .carousel-nav-list{
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        .carousel-nav-item{
          color: #757575;
          margin-left: 0 !important;
          margin-right: 1rem;
          margin-bottom: 10px;
          .indicator{
            background: #757575;
            margin-bottom: 5px;
          }
          &.tns-nav-active{
            .indicator{
              width: 100%;
              background: #0176DE;
            }
            color: #0176DE;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          .thumbnail{
            width: var(--width);
            height: calc(var(--width) / 2);
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            } 
          }
        }
      }
    }
  }
}
</style>
