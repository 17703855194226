<template>
  <div id="chatWithCareers">
    <router-view parent="carreras" :careers="careers"/>
  </div>
</template>
<script>
export default {
  name: 'ChatWithCareers',
  computed: {
    careers () {
      return this.$store.state.stands
    }
  },
  mounted() {
    this.$store.dispatch('getStands')
  }
}
</script>
