<template>
  <modal v-model="allways" modal-name="chatWithCareers" title="Carreras UC" :close-path="`/${$store.state.prefix}mapa`">
    <list v-for="(career, index) in Object.entries(careers)" :key="index" :data="career" prefix="slug" />
  </modal>
</template>
<script>
import ModalMixin from '@/components/ModalMixin'
import List from '@/components/List'

export default {
  props: ['careers'],
  mixins: [ ModalMixin ],
  components: {
    List
  },  
}
</script>