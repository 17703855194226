<template>
  <div id="single-career">
    <side-data v-if="marker_info?.info_general" :title="marker.titulo" parent="conoce-mas-de-la-uc" :show-side="false">
      <div class="single-career-img">
        <img @error="replaceLink" :src2="marker.imagen_principal" :src="imageLink(marker.imagen_principal)" :alt="`imagen principal de ${marker.titulo}`">
      </div>
      <div class="single-career-content">
        <div v-if="marker_info.info_general" class="bloque texto">
          <h3 class="">Información</h3>
        </div>
        <span v-html="marker_info.info_general">
        </span>
      </div>
      <div v-if="images.length">
        <h3 class="h5 mt-28 mb-8">Imágenes</h3>
        <image-slider id="images" :data="images"/>
      </div>

      <div v-if="videos.length">
        <h3 class="h5 mt-28 mb-8">Videos</h3>
        <video-slider id="videos" :data="videos"/>
      </div>
      <div v-if="marker_info.url_facebook || marker_info.url_instagram || marker_info.url_youtube" class="social-networks">
        <span>
          Síguenos
        </span>
        <div class="social-networks-items">
          <div v-if="marker_info.url_facebook" class="social-networks-item">
            <a :href="marker_info.url_facebook" target="_blank">
              <img src="/icons/facebook.svg" alt="logo de facebook">
            </a>
          </div>
          <div v-if="marker_info.url_instagram" class="social-networks-item">
            <a :href="marker_info.url_instagram" target="_blank">
              <img src="/icons/instagram.svg" alt="logo de instagram">
            </a>
          </div>
          <div v-if="marker_info.url_youtube" class="social-networks-item">
            <a :href="marker_info.url_youtube" target="_blank">
              <img src="/icons/youtube.svg" alt="logo de youtube">
            </a>
          </div>
        </div>
      </div>
    </side-data>
  </div>
</template>
<script>
import VideoSlider from '@/components/VideoSlider'
import ImageSlider from '@/components/ImageSlider'
import SideData from '@/components/SideData.vue'
export default {
  name: 'SingleCareer',
  components: {
    SideData,
    VideoSlider,
    ImageSlider
  },
  data: () => ({
    errorCounter: 0,
  }),
  computed: {
    videos () {
      if(!this.marker?.multimedia?.length) return []
      return this.marker.multimedia.filter(el => el.id_tipo_multimedia === 2)
    },
    images () {
      if(!this.marker?.multimedia?.length) return []
      return this.marker.multimedia
        .filter(el => el.id_tipo_multimedia === 1)
        .map(el => {
          if(!el.url.includes(this.$store.state.imagePath))
            el.url = this.$store.state.imagePath + el.url
          return { ...el }
        })
    },
    marker () {
      if(!this.$store.state.markers.length) return {}
      return this.$store.state.markers.find(el => el.slug === this.$route.params.slug)
    },
    marker_info () {
      if(!this.marker.id) return {}
      return this.$store.state.marker_info.find(el => el.id_punto === this.marker.id)
    }
  },
  mounted () {
    this.$store.dispatch('getMarkers')
    this.$store.dispatch('getMarkerInfo')
  },
  methods: {
    replaceLink (e) {
      if(this.errorCounter === 0 && Object.keys(this.marker).length) {
        e.target.src = this.$store.state.imagePath + this.marker.imagen_principal
        this.errorCounter++
      }
    },
    imageLink (url) {
      return '/images/puntos/'+url
    }
  }
}
</script>
<style lang="scss">
#single-career {
  .single-career-img {
    width: 100%;
    background-color: lightgrey;
    height: 0;
    padding-bottom: 66%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .single-career-content {
    font-size: 1rem;
    h3 {
      font-weight: 300;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    ul {
      margin: 0;
      padding: 0;
      padding-left: 1rem;
      list-style-type: none;
    }
    .requisitos {
      .requisitos-ul {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin: 0 -7.5px;
        li {
          background-color: var(--blue);
          color: white;
          margin: 0 7.5px;
          margin-top: 15px;
          border-radius: 4px;
          padding: 12px;
          display: flex;
          justify-content: center;
          align-items: stretch;
          flex: 1 0 30%;
          font-weight: 500;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            span {
              font-weight: 500;
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}
.social-networks{
  .social-networks-items{
    display: flex;
    margin: 0 -5px;
    .social-networks-item{
      --size: 20px;
      height: var(--size);
      cursor: pointer;
      transition: ease-in-out all .2s;
      padding: 0 5px;
      a{
        text-decoration: none;
        color: rgb(46,112,171);
      }
      &:hover{
        filter: brightness(0.7);
      }
      img{
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
