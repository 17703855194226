<template>
  <div class="register-container">
    <div
      class="with-background-sticky top"
      style="min-height: 100vh"
      :style="{ backgroundImage: 'url(' + page.imagen_fondo_url + ')' }"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="m-12">
          <a href="/">
            <img class="logo-uc" :src="page.logo_url" alt="" />
          </a>
        </div>

        <div class="uc-card w-100" style="max-width: 500px">
          <div class="uc-card_body">
            <img class="w-100" :src="page.imagen_promocional_url" alt="" />
          </div>
          <div class="uc-card mt-16 mb-36 registro">
            <div class="uc-card_body container">
              <h3 class="mb-24">Registra tus datos a continuación</h3>
              <!-- <button class="my-4" v-if="dev" @click="fillFormDummy">
              rellenar formulario
            </button> -->
              <form action="">
                <div class="uc-form-group">
                  <label for="nombre"
                    ><i class="uc-icon">account_circle</i> Nombres (*)</label
                  >
                  <input
                    id="nombre"
                    v-model="form.nombre"
                    type="text"
                    maxlength="250"
                    class="uc-input-style"
                    placeholder=""
                  />
                </div>
                <div class="uc-form-group">
                  <label for="apellido"
                    ><i class="uc-icon">account_circle</i> Apellidos (*)</label
                  >
                  <input
                    v-model="form.apellido"
                    id="apellido"
                    type="text"
                    maxlength="250"
                    class="uc-input-style"
                    placeholder=""
                  />
                </div>
                <div class="mb-16">
                  <div class="uc-form-group uc-radio-group mb-8">
                    <input
                      type="radio"
                      id="switch_1"
                      v-model="form.tipoDocumento"
                      value="rut"
                      class="uc-orig-radio"
                      checked=""
                    />
                    <label for="switch_1" class="uc-radio-button"> RUT </label>
                    <input
                      type="radio"
                      id="switch_2"
                      v-model="form.tipoDocumento"
                      value="pasaporte"
                      class="uc-orig-radio"
                    />
                    <label for="switch_2" class="uc-radio-button">
                      PASAPORTE
                    </label>
                  </div>
                </div>

                <div v-if="form.tipoDocumento == 'rut'" class="uc-form-group">
                  <label for="rut"
                    ><i class="uc-icon">admin_panel_settings</i> RUT (*)</label
                  >
                  <input
                    id="rut"
                    type="text"
                    @blur="rutFocusOut"
                    v-model="form.rut"
                    maxlength="11"
                    class="uc-input-style"
                    placeholder="Sin puntos ni guion"
                  />
                </div>

                <div
                  v-if="form.tipoDocumento == 'pasaporte'"
                  class="uc-form-group"
                >
                  <label for="rut"
                    ><i class="uc-icon">admin_panel_settings</i> PASAPORTE
                    (*)</label
                  >
                  <input
                    id="rut"
                    type="text"
                    v-model="form.pasaporte"
                    maxlength="20"
                    class="uc-input-style"
                    placeholder="Ingrese número de pasaporte"
                  />
                </div>

                <div class="uc-form-group">
                  <label for="email">
                    <i class="uc-icon mr-4">email</i>Email (*)</label
                  >
                  <input
                    v-model="form.email"
                    id="email"
                    maxlength="250"
                    type="text"
                    class="uc-input-style"
                  />
                </div>
                <div v-if="false" class="uc-form-group">
                  <label for="telefono"
                    ><i class="uc-icon">smartphone</i> Celular de contacto
                    (*)</label
                  >
                  <input
                    id="telefono"
                    v-model="form.celular"
                    name="telefono"
                    maxlength="20"
                    type="text"
                    class="uc-input-style"
                  />
                </div>
                <div class="uc-form-group">
                  <label for="id_visitante"
                    ><i class="uc-icon">label</i> ¿Qué tipo de visitante eres?
                    (*)</label
                  >
                  <select
                    v-model="form.tipoVisitante"
                    class="uc-input-style"
                    name="id_visitante"
                    id="id_visitante"
                  >
                    <option disabled value="">Seleccione opción</option>
                    <option
                      v-for="(visitante, vIndex) in listadoVisitantes"
                      :key="vIndex"
                      :value="visitante.id"
                    >
                      {{ visitante.nombre }}
                    </option>
                  </select>
                </div>
                <div
                  v-if="form.tipoVisitante == 12"
                  class="uc-form-group"
                  id="div_option_visitante"
                >
                  <label for="option_visitante"
                    ><i class="uc-icon">label</i> Indique el tipo de visitante
                    (*)</label
                  >
                  <input
                    id="option_visitante"
                    name="option_visitante"
                    v-model="form.tipoVisitanteOtro"
                    type="text"
                    class="uc-input-style"
                    placeholder=""
                  />
                </div>
                <div v-if="false" class="uc-form-group">
                  <label for="id_region"
                    ><i class="uc-icon">label</i> Región (*)</label
                  >
                  <select
                    v-model="form.region"
                    class="uc-input-style"
                    name="id_region"
                    id="id_region"
                    @change="regionChanged"
                  >
                    <option disabled value="">Seleccione región</option>
                    <option
                      v-for="(region, rIndex) in listadoRegiones"
                      :key="rIndex"
                      :value="region.id"
                    >
                      {{ region.nombre }}
                    </option>
                  </select>
                </div>

                <div v-if="false" class="uc-form-group">
                  <label for="id_comuna"
                    ><i class="uc-icon">label</i> Comuna de residencia (*)</label
                  >
                  <select
                    v-model="form.comuna"
                    class="uc-input-style"
                    name="id_comuna"
                    id="id_comuna"
                    :disabled="!listadoComunasFiltradas.length"
                  >
                    <option disabled value="">Seleccione comuna</option>
                    <option
                      v-for="(comuna, index) in listadoComunasFiltradas"
                      :key="index"
                      :value="comuna.id"
                    >
                      {{ comuna.nombre }}
                    </option>
                  </select>
                </div>

                <div v-if="false" class="uc-form-group">
                  <label for="id_colegio"
                    ><i class="uc-icon">label</i> Colegio (*)</label
                  >
                  <uc-select
                    palceholder="Seleccione el colegio"
                    v-model="form.colegio"
                    @change="colegioChanged"
                    :options="listadoColegios"
                  >
                    <uc-select-option :options="listadoColegios" />
                  </uc-select>
                </div>

                <div

                  v-if="showOtherCollegeInput"
                  class="uc-form-group"
                  id="div_option_colegio"
                >
                  <label for="option_colegio"
                    ><i class="uc-icon">label</i> En caso de no encontrar tu
                    colegio, ingrésalo aquí (*)</label
                  >
                  <input
                    id="option_colegio"
                    name="option_colegio"
                    v-model="form.otroColegio"
                    type="text"
                    class="uc-input-style"
                  />
                </div>

                <div v-if="false" class="uc-form-group">
                  <label for="carreras"
                    ><i class="uc-icon">label</i> ¿Qué carreras te interesan?
                    (*)</label
                  >
                  <uc-select
                    palceholder="Seleccione las carreras"
                    v-model="form.carreras"
                    :options="listadoCarreras"
                    :limit="9999"
                    multiple
                  >
                    <uc-select-option :options="listadoCarreras" />
                  </uc-select>
                </div>
                <div style="display: none" class="uc-form-group">
                  <label for="ucinput"
                    >Dentro de la Expo tendrás acceso a un ensayo de la Prueba de
                    Transición, ¿Deseas rendirla ? (*)</label
                  >
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="radio_ensayo form-check-input"
                        name="ensayo"
                        value="si"
                      />
                      Si
                      <input
                        type="radio"
                        class="radio_ensayo form-check-input"
                        checked
                        name="ensayo"
                        value="no"
                      />
                      No
                    </label>
                  </div>
                  <small class="form-text" id="text_ensayo" hidden
                    >Para ingresar al ensayo, tu usuario es el RUT y tu contraseña
                    son los 4 primeros dígitos del RUT.</small
                  >
                </div>

                <div class="uc-form-group">
                  <label for="ucinput">Campo requerido(*)</label>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        name="condicion"
                        v-model="form.condicion"
                        id="condicion"
                        type="checkbox"
                        class="form-check-input"
                      />
                      Acepto recibir información sobre la Pontificia Universidad
                      Católica de Chile. Conforme a la Ley 19.628, la Universidad
                      no divulgará, ni transferirá esta información.
                    </label>
                  </div>
                </div>
                <div>
                  <div
                    v-for="(error, index) in errors"
                    :key="index"
                    class="uc-alert error mb-12"
                  >
                    <div class="uc-alert_content">
                      <i class="uc-icon icon-size--sm">cancel</i>
                      <span class="p p-size--sm bold ml-8">{{ error }}</span>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end">
                  <button
                    :disabled="loading"
                    @click.prevent="registerUser"
                    class="uc-btn btn-primary btn-inline"
                  >
                    Inscribirme
                    <i class="uc-icon">login</i>
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div v-if="showMessage" class="uc-alert success mb-12">
            <div class="uc-alert_content">
              <i class="uc-icon icon-size--sm">check_circle</i>
              <span class="p p-size--sm bold ml-8">{{ messageContent }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UcSelect from "@/components/UcSelect";
import UcSelectOption from "@/components/UcSelectOption";
import RutHelper from "../helpers/Rut";
import { ElMessage } from "element-plus";
import { checkLogin } from "../helpers/auth.js";

export default {
  name: "registro",
  props: {
    msg: String
  },
  components: {
    UcSelect,
    UcSelectOption
  },
  setup() {
    return { checkLogin };
  },
  created() {
    const logged = JSON.parse(localStorage.getItem("logged"));
    const user = JSON.parse(localStorage.getItem("user"));
    this.checkLogin(logged, user);
    this.fetchPageData();
    this.fetchRegisterFields();
  },
  data() {
    return {
      dev: process.env.NODE_ENV !== "production",
      showMessage: false,
      messageContent: "",
      errors: [],
      loading: false,
      listadoCarreras: [],
      listadoColegios: [],
      listadoVisitantes: [],
      listadoRegiones: [],
      listadoComunas: [],
      listadoComunasFiltradas: [],
      value: [],
      showOtherCollegeInput: false,
      page: {
        titulo: "¡Te damos la bienvenida!",
        subtitulo: "Registra tus datos a continuación. Si ya estuviste en la Expo Futuro Novato y Novata UC del año anterior, no necesitas registrarte de nuevo.",
        condiciones:
          "Acepto recibir información sobre la Pontificia Universidad Católica de Chile. Conforme a la Ley 19.628, la Universidad no divulgará, ni transferirá esta información.",
        logo_url: "",
        logo: "GcPH8YjImBc412KIvPPKwsNoXYYAk4TOoFNm4xF0.png",
        imagen_promocional_url: "",
        imagen_promocional: "N7qu1kHb4xush7FtZIu3Ev3szU84uHHgCgm3ngtn.png",
        imagen_fondo_url: "",
        imagen_fondo: "izpRwcsdiP0fuGDpIxrmv7EBBibaul1hffxrfROl.svg",

        imagenFondo: "",
        imagenPromocional: ""
      },
      form: {
        nombre: "",
        tipoDocumento: "rut",
        apellido: "",
        rut: "",
        pasaporte: "",
        email: "",
        celular: "",
        tipoVisitante: "",
        tipoVisitanteOtro: "",
        region: "",
        comuna: "",
        colegio: "",
        otroColegio: "",
        carreras: [],
        ensayo: "no",
        condicion: false,
        token: ""
      }
    };
  },
  watch: {
    "form.tipoDocumento"() {
      this.form.pasaporte = "";
      this.form.rut = "";
    }
  },
  methods: {
    fetchPageData() {
      window.axios
        .get("/api/page/registro")
        .then(({ data }) => {
          if (data) {
            this.page.titulo = data.titulo;
            this.page.subtitulo = data.subtitulo;
            this.page.logo_url = data.logo_url;
            this.page.imagen_fondo_url = data.imagen_fondo_url;
            this.page.imagen_promocional_url = data.imagen_promocional_url;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    fillFormDummy() {
      this.form.nombre = "Jhon doe";
      this.form.apellido = "Lastname";
      this.form.rut = "9765547-2";
      this.form.email = "test@asimov.cl";
      this.form.celular = "944354832";
      this.form.tipoVisitante = 3;
      this.form.region = 3;
      this.form.comuna = 14;
      this.form.colegio = 1;
      this.form.carreras = [63];
      this.form.condicion = true;
    },
    //async loadRecaptcha() {
    //  await this.$recaptchaLoaded();
    //  const token = await this.$recaptcha("registro");
    //  this.form.token = token;
    //},
    async registerUser() {
      if (this.validFields()) {
        //await this.loadRecaptcha();
        this.loading = true;
        window.axios
          .post("/api/register", this.form)
          .then(() => {
            this.loading = false;
            ElMessage({
              showClose: true,
              message:
                "Registro con éxito, hemos enviado a su correo electrónico sus datos de acceso.",
              type: "success",
              duration: 10000
            });
            setTimeout(() => {
              this.$router.push("/login");
            }, 10000);
          })
          .catch(e => {
            this.loading = false;
            if (e.response.data.errors) {
              this.errors = Object.values(e.response.data.errors).map(i => {
                return i.toString();
              });
            }
          });
      }
    },
    rutFocusOut() {
      if (this.form.rut) {
        this.form.rut = RutHelper.format(this.form.rut);
      }
    },
    validFields() {
      this.errors = [];
      let {
        tipoDocumento,
        nombre,
        apellido,
        rut,
        pasaporte,
        email,
        //celular,
        tipoVisitante,
        //region,
        //comuna,
        //colegio,
        //carreras,
        //condicion,
        //otroColegio
      } = this.form;

      if (
        !nombre ||
        !apellido ||
        !email ||
        // !celular ||
        // !region ||
        // !comuna ||
        // !carreras.length ||
        // !colegio
        !tipoVisitante
      ) {
        this.errors.push("Verifique los campos requeridos");
        return false;
      }

      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errors.push("Formato de correo no valido");
      }
      if (tipoDocumento == "rut" && rut == "") {
        this.errors.push("El RUT es requerido");
      } else if (tipoDocumento == "rut" && !RutHelper.validate(rut)) {
        this.errors.push("Formato de RUT inválido");
      }

      if (tipoDocumento == "pasaporte" && pasaporte == "") {
        this.errors.push("El número de pasaporte es requerido");
      }
      return !this.errors.length;
    },
    triggerMessage(message, duration) {
      this.showMessage = true;
      this.messageContent = message;
      setTimeout(() => {
        this.showMessage = false;
      }, duration);
    },
    colegioChanged(i) {
      if (typeof i === "string") {
        this.form.otroColegio = "";
        this.showOtherCollegeInput = i === "otra opción";
      }
    },
    regionChanged() {
      this.listadoComunasFiltradas = [];
      this.listadoComunasFiltradas = this.listadoComunas.filter(
        c => c.id_region == this.form.region
      );
    },
    fetchRegisterFields() {
      window.axios.get("/api/register").then(({ data }) => {
        let { carreras, colegios, comunas, regiones, visitantes } = data;
        this.sortAlphabetically(carreras);
        this.listadoCarreras = carreras.map(col => {
          return {
            value: col.id,
            label: col.nombre
          };
        });
        // eslint-disable-next-line no-unused-vars
        let oIndex = null;
        this.listadoColegios = colegios.map((col, index) => {
          if (col.nombre == "Otra opción") {
            oIndex = index;
          }

          let label = "";
          label += col.nombre.toLowerCase();
          if (col.comuna) {
            label += "_" + col.comuna.toLowerCase() + "_";
          }
          if (col.rbd) {
            label += col.rbd;
          }
          return {
            value: col.id,
            label: label
          };
        });

        if (oIndex) {
          let otherOption = this.listadoColegios.splice(oIndex, 1);
          this.listadoColegios.unshift(otherOption[0]);
        }

        this.listadoComunas = comunas;
        this.listadoRegiones = regiones;
        this.listadoVisitantes = visitantes;
      });
    },
    sortAlphabetically(array) {
      this.listadoCarreras = array.sort(function(a, b) {
        if (a.nombre < b.nombre) {
          return -1;
        }
        if (a.nombre > b.nombre) {
          return 1;
        }
        return 0;
      });
    }
  }
};
</script>


<style lang="scss" >
.logo-uc {
  width: 140px;
}
.el-select-dropdown__list {
  li {
    span {
      text-transform: uppercase;
    }
  }
}
.register-container {
  height: 100%;
  overflow-y: scroll;
}
@media (min-width: 992px) {
  .registro {
    width: 500px;
  }
}
</style>
