<template>
  <div :id="layoutName">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'KnowUc',
  props: {
    layoutName: {
      type: String,
      default: 'nested-view'
    }
  }
}
</script>
