<template>
  <section id="map-page" dragscroll>
    <Map>
      <template v-slot:img>
        <img id="mapa-img" :src="img" alt="mapa"/>
      </template>
      <simulate-score />
    </Map>
    <router-view />
  </section>
</template>
<script>
import Map from '@/components/map/Map.vue'
import SimulateScore from '@/components/SimulateScore.vue'
import image from '@/assets/images/map.jpg'

export default {
  components: {
    Map,
    SimulateScore
  },
  data: () => ({
    markers: [],
    img: image
  })
}
</script>
