<template>
  <div @click.stop="focused = true" :class="['uc-select', { 'uc-select-focused': focused }, { multiple:multiple }]">
    <div v-if="q && ids && !q.length && !ids.length" class="uc-select-placeholder">
      {{ placeholder }}
    </div>
    <div class="uc-select-value">
      <div v-if="multiple" class="uc-select-value-multiple">
        <div
          v-for="(multipleItem, multipleItemIndex) in ids"
          :key="`multiple-item-${multipleItemIndex}`"
          class="uc-select-value-multiple-item"
        >
          {{ optionLabel(multipleItem) }}
          <span @click="removeOption(multipleItem)" class="uc-select-value-multiple-item-delete">
            x
          </span>
        </div>
        <span>
          <input @keypress.enter="prevent" type="text" v-model="q">
        </span>
      </div>
      <input v-show="!multiple" type="text" v-model="q">
    </div>
    <div v-if="focused" :class="['uc-select-options', {multiple:multiple}]">
      <slot :q="q" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'UcSelect',
  props: {
    placeholder: String,
    modelValue: Number,
    options: Array,
    limit: {
      type: Number,
      default: 20
    }
  },
  data: () => ({
    q: '',
    q2: '',
    id: '',
    ids: [],
    focused: false,
    multiple: null
  }),
  watch: {
    q () {
      this.$emit('update:modelValue', this.id)
      this.$emit('change', this.options?.find(el=>el.value === this.id)?.label)
    },
    ids () {
      this.$emit('update:modelValue', this.ids)
    }
  },
  methods: {
    prevent (e) {
      e.preventDefault()
    },
    optionLabel (id) {
      return this.options.find(el => el.value === id)?.label
    },
    removeOption (id) {
      delete this.ids[this.ids.indexOf(id)]
      this.ids = this.ids.filter(el => el !== null)
    }
  },
  mounted () {
    this.q = this.modelValue
    this.multiple = Object.keys(this.$attrs).includes('multiple')
    window.addEventListener('click', () => {
      this.focused = false
    })
  }
}
</script>
<style lang="scss" scoped>
.uc-select{
  position: relative;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: relative;
  padding: 10px;
  padding-left: 15px;
  line-height: 100%;
  font-size: 14px;
  transition: ease-in-out all .1s;
  &.uc-select-focused{
    border-color: #409eff;
  }
  .uc-select-placeholder{
    position: absolute;
    color: var(--placeholder-color);
    padding-left: 3px;
  }
  .uc-select-value{
    position: relative;
    z-index: 2;
    display: flex;
    input{
      background-color: transparent;
      outline: none;
      border: 0;
      width: 100%;
    }
    .uc-select-value-multiple{
      display: flex;
      flex-wrap: wrap;
      .uc-select-value-multiple-item{
        background-color: #f0f2f5;
        color: rgb(144, 147, 153);
        margin: 2px 6px 2px 0px;
        padding: 3px 8px;
        font-size: 12px;
        border-radius: 4px;
        white-space: nowrap;
        .uc-select-value-multiple-item-delete{
          --size: 12px;
          display: inline-block;
          text-align: center;
          background-color: rgb(192, 196, 204);
          color: #f0f2f5;
          cursor: pointer;
          width: var(--size);
          height: var(--size);
          line-height: 100%;
          border-radius: 50%;
          &:hover{
            background-color: rgb(144, 147, 153);
          }
        }
      }
    }
  }
  .uc-select-options{

    position: absolute;
    z-index: 9;
    width: 100%;
    left: 0;
    padding: 10px 0px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    &:not(.multiple){
      top: 45px;
    }
    &.multiple{
      transform: translateY(17px);
    }
  }
}
</style>
