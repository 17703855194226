<template>
  <div id="default-layout">
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>
<script>
import AppHeader from './AppHeader.vue'
import AppFooter from './AppFooter.vue'

export default {
  name: 'LayoutDefault',
  components: {
    AppHeader,
    AppFooter
  },
  mounted () {
    this.makeTawk()
  },
  methods: {
    makeTawk () {
      /* Setting a diferent name to chat */
      /* window.Tawk_API.visitor.name="" */
    }
  }
}
</script>
<style lang="scss" scoped>
#default-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
</style>