<template>
  <div id="map-markers-component" :class="{grabbing:grabbing}">
    <cursor v-if="false" />
    <loader v-if="loading" />
    <div class="d-none map-info">
      <table>
        <tr>
          <th colspan="2">center</th>
        </tr>
        <tr>
          <th>x</th>
          <td>{{ center.x }}</td>
        </tr>
        <tr>
          <th>y</th>
          <td>{{ center.y }}</td>
        </tr>
        <tr>
          <th colspan="2">delta</th>
        </tr>
        <tr>
          <th>x</th>
          <td>{{ movements.deltaX }}</td>
        </tr>
        <tr>
          <th>y</th>
          <td>{{ movements.deltaY }}</td>
        </tr>
        <tr colspan="2">
          <th>marker</th>
        </tr>
        <tr>
          <th>top</th>
          <td>{{ currentMarker.top }}</td>
        </tr>
        <tr>
          <th>left</th>
          <td>{{ currentMarker.valor_left }}</td>
        </tr>
      </table>
    </div>
    <div @click.stop="$refs.searchBoxInput.focus()" class="map-search-box">
      <div @click="showDropdown = true" class="map-search-box--input">
        <input v-model="q" placeholder="Buscar en la feria" type="text" ref="searchBoxInput">
        <i v-if="!q.length" class="uc-icon">search</i>
        <i @click="q = ''" v-else class="uc-icon">close</i>
      </div>
      <div v-if="q.length" class="map-search-box--dropdown">
        <ul>
          <li
            v-for="(marker, index) in filtered"
            :key="`search-marker-${index}`"
            @click="pickDropdownItem(marker)"
          >
            <img
              @error="errorIconLink"
              errors="0"
              :src2="marker.icono"
              :src="iconUrl(marker.icono)"
              :alt="`icono de ${marker.titulo}`"
            >
            <span>{{ marker.titulo }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="map-img">
      <slot class="img" name="img"/>
    </div>
    <div id="markers" ref="markers" :class="{active: active || currentMarker.id}">
      <div
        v-for="(marker, index) in filtered2"
        :key="`marker-${index}`"
        :class="['marker', { hovered:hoverMarkerId === marker.id }, { active: currentMarker.id === marker.id }]"
        v-show="marker.icono"
        :style="getPosition(marker)"
        @mouseover.stop="active = true;hoverMarkerId = marker.id"
        @mouseleave.stop="active = false;hoverMarkerId = ''"
      >
        <router-link
          :to="markerlink(marker)"
          @click="pickMarker(marker)"
          :class="[
            'marker-icon',
            marker.direccion,
            {menu:marker.menu},
            {active:currentMarker.id === marker.id}
          ]"
        >
          <img
            @error="errorIconLink"
            errors="0"
            :src2="marker.icono"
            :src="iconUrl(marker.icono)"
            :alt="`icono de ${marker.titulo}`"
          >
          <div :class="['marker-tooltip', marker.direccion]">
            {{ marker.titulo }}
            <span class="marker-tooltip-sub">{{ marker.sub_titulo }}</span>
          </div>
        </router-link>
        <div :class="['marker-line', marker.direccion]">
          <img v-if="marker.direccion === 'left'" src="@/assets/markers/signleft.svg">
          <img v-else src="@/assets/markers/signright.svg">
        </div>
        <div class="marker-circle"><b></b></div>
      </div>
    </div>
    <div id="zoom">
      <div @click.stop="zoomDown()" class="zoom-item zoom-down">+</div>
      <div @click.stop="zoomUp()" class="zoom-item zoom-up">-</div>
    </div>
    <slot />
  </div>
</template>
<script>
import map from './lib/map'
import Loader from '@/components/Loader.vue'
import Cursor from '@/components/Cursor.vue'

export default {
  name: 'Map',
  components: {
    Loader,
    Cursor
  },
  data: () => ({
    center: {
      x: 0,
      y: 0
    },
    movements: {
      x: 0,
      y: 0,
      top: 0,
      left: 0,
      deltaX: 0,
      deltaY: 0
    },
    q: '',
    showDropdown: false,
    hoverMarkerId: '',
    active: false,
    grabbing: false,
    currentMarker: {},
    infomodal: {
      modal: true,
      data: {}
    },
    loading: true,
    scale: 1,
    mapComponent: {},
    mouse: {
      x: 0,
      y: 0
    },
    scroll: {
      x: 0,
      y: 0
    }
  }),
  computed: {
    filtered () {
      return this.markers.filter(el => this.normalize(el.titulo).includes(this.normalize(this.q)))
    },
    filtered2 () {
      return this.q.length ? this.filtered : this.markers
    },
    markers () {
      let careers = []
      Object.values(this.$store.state.stands).forEach(c2 => {
        c2.forEach(career => {
          career.titulo = career.nombre
          career.icono = 'icon-edificio.png'
          career.custom = true
          careers.push(career)
        })
      })
      console.log(careers)
      return [ ...this.$store.state.markers, ...careers ]
    }
  },
  watch: {
    q () {
      this.showDropdown = this.q.length
    }
  },
  methods: {
    errorIconLink (e) {
      const errors = parseInt(e.target.getAttribute('errors'))
      if (errors === 0) {
        e.target.src = this.$store.state.imagePath + e.target.getAttribute('src2')
        e.target.setAttribute('errors', errors + 1)
      }
    },
    iconUrl (icon) {
      return `/icons/${icon}`
    },
    markerlink (marker) {
      let markerUrl = ""
      if (marker.id_tipo_punto === 1) {
        markerUrl = `/${this.$store.state.prefix}mapa/conoce-mas-de-la-uc/${marker.slug}`
      } else {
        markerUrl = `/${this.$store.state.prefix}mapa/${marker.slug}`
      }
      if (marker.custom) {
        markerUrl = '/mapa/carreras/'+marker.slug
      }
      return markerUrl
    },
    pickDropdownItem (marker) {
      this.q = marker.titulo

      this.pickMarker(marker)
      this.$nextTick(() => {
        this.showDropdown = false
      })
    },
    normalize (s) {
      return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    },
    pickMarker (marker) {
      this.currentMarker = marker
      //const coordinates = { ...this.$route.query, x: marker.valor_left + 15, y: marker.top }
      const coordinates = {  }
      //check
      let markerUrl = this.markerlink(marker)
      if(markerUrl.includes('conoce-mas-de-la-uc')) {
        this.$router.push({ path: markerUrl, query: { hideparent: true, ...coordinates } })
      } else {
        this.$router.push({ path: markerUrl, query: { ...coordinates } })
      }
      
      setTimeout(()=> {
        this.centerMap(marker.valor_left + 15, marker.top - 5)
      },100)
    },
    zoomUp (step = 0.1) {
      map.zoomUp(step)
    },
    zoomDown (step = 0.1) {
      map.zoomDown(step)
    },
    getPosition (marker) {
      return `left: calc(${marker.valor_left}% + 50vw);top: calc(${marker.top}% + 50vh);`
    },
    scrollMap (movements) {
      this.center.x += movements.deltaX
      this.center.y += movements.deltaY
      document.querySelector('#map-markers-component').scroll({
        left: this.center.x,
        top: this.center.y,
        behavior: "smooth"
      })
    },
    watchMouse () {
      var ele = document.querySelector('#map-markers-component')
      if(!ele) return
      document.addEventListener('mousedown', e => {
        this.currentMarker = {}
        e.preventDefault()
        this.grabbing = true
        this.movements = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          
          x: e.clientX,
          y: e.clientY
        }
        ele.addEventListener("mousemove", mouseMoveHandler)
        ele.addEventListener("mouseup", mouseUpHandler)
      })

      const mouseMoveHandler = (e) => {
        if(this.grabbing) {
          const dx = e.clientX - this.movements.x;
          const dy = e.clientY - this.movements.y;
  
          ele.scrollTop = this.movements.top - dy;
          ele.scrollLeft = this.movements.left - dx;
        }
      };
      const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      }
      document.addEventListener('mouseup', () => {
        this.grabbing = false
      })

      ele.addEventListener('mousewheel', e => {
        e.preventDefault()
        //const [x, y] = this.getParams()
        //this.centerMap(x, y)
        const zoom = e.deltaY
        const step = 0.008
        if(zoom < 0) {
          this.zoomDown(step)
        } else {
          this.zoomUp(step)
        }
      })
      const touchStart = e => {
        this.grabbing = true
        this.movements = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          
          x: e.touches[0].clientX,
          y: e.touches[0].clientY
        }
        ele.addEventListener("touchmove", touchMove)
      }

      const touchMove = e => {
        if(this.grabbing) {
          const dx = e.touches[0].clientX - this.movements.x;
          const dy = e.touches[0].clientY - this.movements.y;

          ele.scrollTop = this.movements.top - dy;
          ele.scrollLeft = this.movements.left - dx;
        }
      }
      document.addEventListener('touchstart', touchStart)
      document.addEventListener('touchend', () => {
        this.grabbing = false
      })
    },
    getParams () {
      const params = new URLSearchParams(window.location.search)
      const x = parseInt(params.get('x'))
      const y = parseInt(params.get('y'))
      return [x ? x : 50, y ? y : 50]
    },
    centerMap (x = 50, y = 50) {
      const height = document.querySelector("#mapa-img").offsetHeight
      const width = document.querySelector("#mapa-img").offsetWidth
      document.querySelector("#map-markers-component").scroll({
        top: height * (y / 100),
        left: width * (x / 100),
        behavior: 'smooth'
      })
    }
  },
  mounted () {
    this.$store.dispatch('getMarkers')
    this.$store.dispatch('getStands')
    const img = document.querySelector('.map-img img')
    if(!img) return
    setTimeout(() => {
      const [x, y] = this.getParams()
      this.centerMap(x, y)
      this.loading = false
    },2500)
    document.body.classList.add("map-on")
    window.addEventListener('click', () => {
      this.$refs.searchBoxInput.blur()
      this.showDropdown = false
    })
    this.watchMouse()
    this.loading = true
    window.onload = () => {
      this.mapComponent = map.init('.map-img img')
    }
    if (img.offsetHeight) {
      this.mapComponent = map.init('.map-img img')
    }
  },
  unmounted () {
    document.body.classList.remove("map-on")
  }
}
</script>
<style lang="scss">
#map-markers-component{
  overflow: hidden;
  background: rgb(var(--dark-grey));
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: grab;
  &.grabbing{
    cursor: grabbing;
  }
  &::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .map-info{
    background-color: black;
    color: white;
    position: fixed;
    z-index: 999999;
    font-size: 12px;
  }
  .map-search-box{
    --padding: 12px;
    width: calc(100vw - 1rem);
    max-width: 390px;
    background-color: white;
    position: fixed;
    z-index: 100;
    margin: .5rem;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
    border-radius: 8px;
    .map-search-box--input{
      display: flex;
      padding: var(--padding);
      box-shadow: 0 0 2px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%);
      input{
        width: 100%;
        border: none;
        outline-color: transparent;
      }
    }
    .map-search-box--dropdown{
      ul{
        padding: 0;
        margin: 0;
        list-style-type: none;
        max-height: calc(48px * 5);
        overflow-y: scroll;
        li{
          padding: 12px;
          &:hover{
            background-color: rgba(0, 0, 0, .06);
          }
          img{
            width: 1.5rem;
            margin-left: .5rem;
            margin-right: 1.2rem;
          }
        }
      }
    }
  }
  .map-img{
    img{
      margin: 50vh 50vw;
      position: relative;
    }
  }
  #markers{
    position: absolute;
    left: 0;
    top: 0;
    --circle: 1rem;
    --line: 4rem;
    --icon: 4rem;
    --tooltip-dir: -14.5px;
    --tooltip-pos: 5rem;
    z-index: 99;
    &.active{
      .marker:not(.hovered):not(.active){ 
        opacity: .4;
      }
    }
    .marker{
      position: absolute;
      transition: ease-in-out all 0.1s;
      cursor: pointer;
      &:hover{
        z-index: 2;
      }
      .marker-tooltip{
        position: absolute;
        padding: 1rem;
        border-radius: 4px;
        width: 15rem;
        background: white;
        transform: translateX(-.6em);
        top: 0;
        opacity: 0;
        transition: opacity calc(var(--duration) * 1) ease-out, transform calc(var(--duration) * 2) var(--bezier1);
        z-index: 1;
        visibility: hidden;
        color: var(--light-blue);
        font-weight: 500;
        line-height: 1.1;
        .marker-tooltip-sub{
          font-size: 0.9rem;
          font-weight: 400;
          color: var(--grey);
        }
        &.left{
          left: var(--tooltip-pos);
          &::after{
            border-right-color: white;
            left: var(--tooltip-dir);
          }
        }
        &.right{
          right: var(--tooltip-pos);
          &::after{
            border-left-color: white;
            right: var(--tooltip-dir);
          }
        }
        &::after {
          content: ' ';
          top: 1rem;
          display: block;
          width: 1em;
          height: 1em;
          border: .5em solid transparent;
          position: absolute;
        }
      }
      .marker-icon{
        --padding: 10px;
        width: calc(var(--icon));
        height: calc(var(--icon));
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: calc(-1 * (var(--line) + var(--circle) * 2));
        z-index: 4;
        border: 4px solid var(--yellow);
        &:not(:hover):not(.active):not(.menu) {
          opacity: 0.3;
        }
        &.menu{
          z-index: 2;
          border: 4px solid var(--green);
        }
        &:hover, &.active{
          .marker-tooltip{
            visibility: visible;
            transform: translateX(0);
            opacity: 1;
          }
        }
        &.left {
          margin-left: calc(-1 * (var(--line) + var(--circle)));
        }
        &.right {
          margin-left: calc((var(--line) / 2));
        }
        img{
          width: 70%;
          object-fit: contain;
        }
      }
      .marker-line{
        position: absolute;
        width: var(--line);
        margin-top: calc(-1 * var(--line));
        &.left{
          margin-left: calc((-1 * var(--line)) + (var(--circle) / 2));
        }
        &.right{
          margin-left: calc(var(--circle) / 2);
        }
      }
      .marker-circle{
        background-color: var(--blue);
        border-radius: 50%;
        border: 2px solid white;
        width: var(--circle);
        height: var(--circle);
      }
    }
  }
  #zoom{
    position: fixed;
    --space: 5px;
    right: var(--space);
    bottom: calc(var(--bottom) + var(--space));
    z-index: 99;
    color: black;
    font-size: 20px;
    background: white;
    border-radius: 8px;
    text-align: center;
    .zoom-item {
      cursor: pointer;
      padding: 5px 10px;
      line-height: 100%;
      position: relative;
      &:not(:nth-child(1)){
        &:before{
          content: "";
          width: calc(100% - 8px);
          height: 1px;
          background: var(--grey);
          display: block;
          position: absolute;
          left: 4px;
          top: 0;
        }
      }
    }
  }
}
</style>
