import { createStore } from 'vuex'
//import Stands from '@/mock/stands.json'
//import markers from '@/mock/markers.json'
//import services from '@/mock/services.json'
//import charlas from '@/mock/charlas.json'

// Create a new store instance.
const store = createStore({
    state() {
        return {
            //careers: Stands,
            charlas: [],
            statuses: {
                stands: false,
                markers: false,
                marker_info: false,
                services: false,
                charlas: false
            },
            services: {},
            stands: {},
            markers: [],
            marker_info: [],
            modal: true,
            padding: true,
            chatsActive: ['true', 'false'].includes(process.env.VUE_APP_CHATS_ACTIVE) ? JSON.parse(process.env.VUE_APP_CHATS_ACTIVE) : false,
            prefix: process.env.VUE_APP_HIDDEN_MAP_PREFIX ? process.env.VUE_APP_HIDDEN_MAP_PREFIX : '',
            imagePath: process.env.VUE_APP_IMAGES_PATH ? process.env.VUE_APP_IMAGES_PATH : ''
        }
    },
    mutations: {
        SET_STANDS(state, stands) {
            state.stands = stands
            state.statuses.stands = true
        },
        SET_MARKERS(state, markers) {
            state.markers = markers
            state.statuses.markers = true
        },
        SET_MARKER_INFO(state, marker_info) {
            state.marker_info = marker_info
            state.statuses.marker_info = true
        },
        SET_SERVICES(state, services) {
            state.services = services
            state.statuses.services = true
        },
        SET_CHARLAS(state, charlas) {
            state.charlas = charlas
            state.statuses.charlas = true
        },
    },
    actions: {
        getStands({ commit }) {
            fetch('/mock/stands.json')
                .then(response => response.json())
                .then(data => {
                    commit('SET_STANDS', data)
                })
        },
        getMarkers({ commit }) {
            fetch('/mock/markers.json')
                .then(response => response.json())
                .then(data => {
                    data = data.filter(el => el.id_estado === 1)
                    commit('SET_MARKERS', data)
                })
        },
        getMarkerInfo({ commit }) {
            fetch('/mock/markers_info.json')
                .then(response => response.json())
                .then(data => {
                    commit('SET_MARKER_INFO', data)
                })
        },
        getServices({ commit }) {
            fetch('/mock/services.json')
                .then(response => response.json())
                .then(data => {
                    commit('SET_SERVICES', data)
                })
        },
        getCharlas({ commit }) {
            fetch('/mock/charlas.json')
                .then(response => response.json())
                .then(data => {
                    commit('SET_CHARLAS', data)
                })
        }
    },
    getters: {
        stands: (state) => {
            return state.stands
        }
    }
})

export default store