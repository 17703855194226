<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.2681732 99.2125473" overflow="visible" xml:space="preserve">
    <g>
      <g>
        <path fill="#397DDA" d="M82.658783,37.9258003c0-1.6631012,0-1.9697151-0.0214996-2.3193016
          c-0.0214005-0.3721008-0.1092987-0.5468979-0.4707031-0.6240005c-0.0877991-0.0214996-0.2733994-0.032299-0.4266968-0.032299
          c-0.1201019,0-0.1856003-0.0223999-0.1856003-0.0985985c0-0.0772018,0.07621-0.0986023,0.2412109-0.0986023
          c0.5790863,0,1.2685852,0.0331993,1.5742874,0.0331993c0.4492035,0,1.0839996-0.0331993,1.5868988-0.0331993
          c1.368103,0,1.8602982,0.4598999,2.0351028,0.6240005c0.2402954,0.2294998,0.5468979,0.7216988,0.5468979,1.3017006
          c0,1.5537987-1.1376953,2.6474991-2.6913986,2.6474991c-0.0547028,0-0.1747894,0-0.2294998-0.0107002
          c-0.0547028-0.0107994-0.1317978-0.0224991-0.1317978-0.0986977c0-0.0879021,0.077095-0.1201019,0.3065948-0.1201019
          c0.6123047,0,1.4658051-0.6893997,1.4658051-1.8056984c0-0.3613014-0.0332031-1.0937004-0.6455002-1.6406021
          c-0.3935013-0.3613167-0.8427048-0.4267006-1.0937042-0.4267006c-0.1641006,0-0.3280945,0.0107002-0.426796,0.0439034
          c-0.0547028,0.0214806-0.0878906,0.0868988-0.0878906,0.2069969v4.3770027c0,1.0058975,0,1.8700981,0.0546875,2.3407974
          c0.0332031,0.3066864,0.0987015,0.5469017,0.4268036,0.5908012c0.1532974,0.0224991,0.3935089,0.0439987,0.5575943,0.0439987
          c0.1211014,0,0.1758041,0.0331993,0.1758041,0.0878868c0,0.0761147-0.0878906,0.109314-0.2080002,0.109314
          c-0.7217026,0-1.4112015-0.0331993-1.6963043-0.0331993c-0.2509995,0-0.9404984,0.0331993-1.3779984,0.0331993
          c-0.1415863,0-0.2186966-0.0331993-0.2186966-0.109314c0-0.0546875,0.0438995-0.0878868,0.1747971-0.0878868
          c0.1641006,0,0.294899-0.0214996,0.3945007-0.0439987c0.218811-0.0438995,0.2734985-0.2841148,0.3163986-0.6015015
          c0.0547028-0.4599991,0.0547028-1.3241997,0.0547028-2.3300972V37.9258003z"></path>
        <path fill="#397DDA" d="M88.8677826,38.8886986c0-1.8711014,1.2362976-4.2891006,4.5186005-4.2891006
          c2.7236023,0,4.419899,1.5870018,4.419899,4.0918007c0,2.5059013-1.7509995,4.4854012-4.5292969,4.4854012
          C90.1372833,43.1767998,88.8677826,40.8251991,88.8677826,38.8886986z M96.2740784,39.0966988
          c0-2.4511986-1.4101944-3.9716988-3.2050934-3.9716988c-1.2578049,0-2.6914062,0.7001991-2.6914062,3.4248009
          c0,2.2753983,1.2578049,4.0800972,3.3594055,4.0800972C94.5025787,42.6298981,96.2740784,42.2577972,96.2740784,39.0966988z"></path>
        <path fill="#397DDA" d="M100.868782,41.5361977c0.0224991,0.8535004,0.1641006,1.1377029,0.3837967,1.2148018
          c0.1855011,0.0653992,0.392601,0.0761986,0.568306,0.0761986c0.1201935,0,0.1856079,0.0214996,0.1856079,0.0878868
          c0,0.0869141-0.0977097,0.109314-0.2519989,0.109314c-0.7109146,0-1.1484146-0.0331993-1.3554153-0.0331993
          c-0.0995865,0-0.6132965,0.0331993-1.181694,0.0331993c-0.142601,0-0.2412033-0.0116997-0.2412033-0.109314
          c0-0.0663872,0.0653992-0.0878868,0.1747971-0.0878868c0.142601,0,0.3399048-0.0107994,0.4922028-0.0546989
          c0.285202-0.0879021,0.3281021-0.4053001,0.339798-1.3564987l0.097702-6.4775009
          c0-0.2187004,0.0332031-0.3711014,0.142601-0.3711014c0.1211014,0,0.2186966,0.1416016,0.4042969,0.3389015
          c0.1318054,0.142601,1.7949142,1.9257851,3.392601,3.5116997c0.7441025,0.7440834,2.2207031,2.3193016,2.4062042,2.4940834
          h0.0546951L106.3716812,36c-0.0106964-0.6669998-0.1093979-0.875-0.3711014-0.9843025
          c-0.1640015-0.0654984-0.4276962-0.0654984-0.5800858-0.0654984c-0.131813,0-0.1757126-0.0331993-0.1757126-0.0985985
          c0-0.0879021,0.1211014-0.0986023,0.2851028-0.0986023c0.5683975,0,1.0937958,0.0331993,1.3241959,0.0331993
          c0.1192017,0,0.535202-0.0331993,1.0713043-0.0331993c0.1425934,0,0.2519989,0.0107002,0.2519989,0.0986023
          c0,0.0653992-0.0653992,0.0985985-0.1973038,0.0985985c-0.1093979,0-0.1962967,0-0.3280945,0.032299
          c-0.3057022,0.0877991-0.3936005,0.3173027-0.405304,0.930584l-0.1191025,6.9033165
          c0,0.2402992-0.044899,0.3389015-0.1425934,0.3389015c-0.1211014,0-0.2520065-0.1201019-0.3721008-0.2402153
          c-0.6893921-0.6572876-2.0898056-2.0898857-3.2275009-3.2167854c-1.1923981-1.1817017-2.4072037-2.5489006-2.6035004-2.7569008
          h-0.0332031L100.868782,41.5361977z"></path>
        <path fill="#397DDA" d="M112.5113831,35.3652992l-1.6298904,0.0438995
          c-0.6348114,0.0224991-0.8975143,0.0772018-1.0615082,0.3174019c-0.1094055,0.1640968-0.1641006,0.2958984-0.1865997,0.3827972
          c-0.0214005,0.0879021-0.0546036,0.1318016-0.1201019,0.1318016c-0.0762024,0-0.0986023-0.0545998-0.0986023-0.1757011
          c0-0.1748009,0.2080002-1.1699982,0.2304993-1.2579002c0.0322037-0.1425133,0.0653992-0.2080002,0.1307983-0.2080002
          c0.0879059,0,0.1973038,0.1094017,0.4707031,0.1319008c0.3163986,0.0321999,0.732399,0.0546989,1.093811,0.0546989h4.3320007
          c0.7011871,0,0.9628906-0.1093979,1.0507889-0.1093979c0.07621,0,0.0878983,0.0653992,0.0878983,0.2294998
          c0,0.2294998-0.0331955,0.9843979-0.0331955,1.2685013c-0.0108032,0.1093979-0.0332031,0.1757965-0.0987015,0.1757965
          c-0.0868988,0-0.1092987-0.0546989-0.1201019-0.2186966l-0.0106888-0.1211014
          c-0.0225143-0.2841988-0.3174133-0.5908012-1.2910004-0.6123009l-1.3789139-0.0331993v4.4863014
          c0,1.0058975,0,1.8700981,0.0546036,2.3407974c0.0332031,0.3066864,0.0987015,0.5469017,0.426796,0.5908012
          c0.1533051,0.0224991,0.394516,0.0439987,0.5586014,0.0439987c0.1201019,0,0.1748047,0.0331993,0.1748047,0.0878868
          c0,0.0761147-0.0878906,0.109314-0.2080002,0.109314c-0.722702,0-1.4121017-0.0331993-1.717804-0.0331993
          c-0.2509995,0-0.9403992,0.0331993-1.3778992,0.0331993c-0.142601,0-0.2187958-0.0331993-0.2187958-0.109314
          c0-0.0546875,0.0429993-0.0878868,0.1738968-0.0878868c0.1640015,0,0.2958984-0.0214996,0.3945007-0.0439987
          c0.2186966-0.0438995,0.2842102-0.2841148,0.3173981-0.6015015c0.0547028-0.4599991,0.0547028-1.3241997,0.0547028-2.3300972
          V35.3652992z"></path>
        <path fill="#397DDA" d="M119.2134933,37.9258003c0-1.6631012,0-1.9697151-0.0215149-2.3193016
          c-0.0214844-0.3721008-0.1307983-0.5575981-0.371994-0.6123009c-0.1202011-0.0331993-0.2627029-0.0439987-0.3936005-0.0439987
          c-0.1094055,0-0.1758041-0.0223999-0.1758041-0.1094017c0-0.0663986,0.0879135-0.0877991,0.2637024-0.0877991
          c0.4150009,0,1.1044998,0.0331993,1.4218979,0.0331993c0.2733994,0,0.9188995-0.0331993,1.3339996-0.0331993
          c0.1425018,0,0.2304001,0.0214005,0.2304001,0.0877991c0,0.0870018-0.0663986,0.1094017-0.1757965,0.1094017
          c-0.1092911,0-0.1962051,0.0107994-0.3281021,0.032299c-0.294899,0.0545845-0.3827972,0.2411995-0.4042969,0.6240005
          c-0.0214996,0.3495865-0.0214996,0.6562004-0.0214996,2.3193016v1.9258003c0,1.060482,0,1.9247971,0.0429993,2.3954811
          c0.0331955,0.2959175,0.1093979,0.492218,0.4375,0.5361176c0.1542969,0.0224991,0.3945007,0.0439987,0.5585938,0.0439987
          c0.1201019,0,0.1748047,0.0331993,0.1748047,0.0878868c0,0.0761147-0.0868988,0.109314-0.2080002,0.109314
          c-0.7217026,0-1.4111023-0.0331993-1.7070007-0.0331993c-0.2509995,0-0.9404984,0.0331993-1.3779984,0.0331993
          c-0.1416016,0-0.2187042-0.0331993-0.2187042-0.109314c0-0.0546875,0.0438995-0.0878868,0.1748047-0.0878868
          c0.1641006,0,0.294899-0.0214996,0.3945007-0.0439987c0.2187958-0.0438995,0.2831955-0.2294998,0.3163986-0.5467987
          c0.0547104-0.4600029,0.0547104-1.3243179,0.0547104-2.3848V37.9258003z"></path>
        <path fill="#397DDA" d="M125.7232819,39.8516006c0,1.0497971,0,1.8700981,0.0547028,2.3300972
          c0.0331955,0.3173866,0.0985947,0.5576019,0.4266968,0.6015015c0.1532974,0.0224991,0.3936005,0.0439987,0.5468979,0.0439987
          c0.1416016,0,0.1865005,0.0331993,0.1865005,0.0878868c0,0.0761147-0.077095,0.109314-0.2411957,0.109314
          c-0.6893921,0-1.3778992-0.0331993-1.6631012-0.0331993c-0.2842026,0-0.9736023,0.0331993-1.3886032,0.0331993
          c-0.1542969,0-0.2304993-0.0223999-0.2304993-0.109314c0-0.0546875,0.0438995-0.0878868,0.1641006-0.0878868
          c0.1640015,0,0.294899-0.0214996,0.3945007-0.0439987c0.2186966-0.0438995,0.2733994-0.2841148,0.3163986-0.6015015
          c0.0547028-0.4599991,0.0547028-1.2803001,0.0547028-2.3300972v-1.9258003c0-1.6631012,0-1.9697151-0.0214996-2.3193016
          c-0.0214996-0.3721008-0.1093903-0.5468979-0.4707031-0.6240005c-0.0878983-0.0214996-0.2733994-0.032299-0.426796-0.032299
          c-0.1093063,0-0.1855011-0.0223999-0.1855011-0.0878983c0-0.0879021,0.0868988-0.1093025,0.2509995-0.1093025
          c0.5692978,0,1.2586975,0.0331993,1.5760956,0.0331993c0.4160004,0,2.6368027,0,2.8887024-0.0117989
          c0.2069931-0.0107002,0.3378983-0.0107002,0.4473038-0.0429001c0.066391-0.0224991,0.1210938-0.0664139,0.1757965-0.0664139
          c0.0428925,0,0.0760956,0.0439148,0.0760956,0.0986137c0,0.0770988-0.0547028,0.2080002-0.0878906,0.4921989
          c-0.0097046,0.1094017-0.0322113,0.7549019-0.0547028,0.8866997c-0.0097046,0.0869026-0.032196,0.1533012-0.1092987,0.1533012
          c-0.0762024,0-0.097702-0.0546989-0.1094055-0.1758156c0-0.0868835,0-0.2616844-0.0644989-0.4150009
          c-0.0771027-0.1532822-0.3065872-0.3173828-0.7225876-0.328083l-1.6856079-0.0331993
          c-0.0643921,0-0.0976028,0.0331993-0.0976028,0.1532822v2.7354164c0,0.0653992,0.0106964,0.0975838,0.07621,0.0975838
          l1.4002914-0.0106812c0.1865997,0,0.3398972,0,0.4921951-0.0108032c0.2089996-0.0107002,0.3613968-0.0546989,0.4706955-0.197197
          c0.0547028-0.0655022,0.0762024-0.1094017,0.1426086-0.1094017c0.0429993,0,0.0653992,0.0331993,0.0653992,0.0985985
          c0,0.065403-0.0545959,0.339901-0.0868988,0.6787033c-0.0223999,0.2187843-0.0449066,0.7216988-0.0449066,0.7988968
          c0,0.0976028,0,0.2070007-0.1093903,0.2070007c-0.0644073,0-0.0868988-0.0761986-0.0868988-0.152401
          c0-0.0770988-0.0223999-0.2079964-0.0654068-0.339798c-0.0546875-0.1533012-0.2187958-0.3496017-0.5586014-0.3936005
          c-0.2733994-0.0331993-1.4325943-0.0331993-1.6190872-0.0331993c-0.0547104,0-0.07621,0.0224991-0.07621,0.0986977V39.8516006z"></path>
        <path fill="#397DDA" d="M131.3033752,37.9258003c0-1.6631012,0-1.9697151-0.0214996-2.3193016
          c-0.0214996-0.3721008-0.1308899-0.5575981-0.3721008-0.6123009c-0.1200867-0.0331993-0.2626953-0.0439987-0.3934937-0.0439987
          c-0.1094055,0-0.1757812-0.0223999-0.1757812-0.1094017c0-0.0663986,0.0878754-0.0877991,0.2635803-0.0877991
          c0.4151001,0,1.1045074,0.0331993,1.4219055,0.0331993c0.2735138,0,0.9189911-0.0331993,1.3339996-0.0331993
          c0.1425934,0,0.2305145,0.0214005,0.2305145,0.0877991c0,0.0870018-0.0664215,0.1094017-0.1758118,0.1094017
          c-0.1094055,0-0.1963043,0.0107994-0.3281097,0.032299c-0.2949982,0.0545845-0.3827972,0.2411995-0.4042969,0.6240005
          c-0.0214996,0.3495865-0.0214996,0.6562004-0.0214996,2.3193016v1.9258003c0,1.060482,0,1.9247971,0.0429993,2.3954811
          c0.0332031,0.2959175,0.1092987,0.492218,0.4375,0.5361176c0.1542053,0.0224991,0.3945007,0.0439987,0.5585022,0.0439987
          c0.1201935,0,0.1748047,0.0331993,0.1748047,0.0878868c0,0.0761147-0.0869141,0.109314-0.2080078,0.109314
          c-0.7216034,0-1.411087-0.0331993-1.7070007-0.0331993c-0.2509918,0-0.9403992,0.0331993-1.3778992,0.0331993
          c-0.1416016,0-0.2187958-0.0331993-0.2187958-0.109314c0-0.0546875,0.0439911-0.0878868,0.1747894-0.0878868
          c0.1641083,0,0.2950134-0.0214996,0.3946075-0.0439987c0.2187042-0.0438995,0.2832184-0.2294998,0.3164062-0.5467987
          c0.0546875-0.4600029,0.0546875-1.3243179,0.0546875-2.3848V37.9258003z"></path>
        <path fill="#397DDA" d="M136.3326874,41.9843979c-1.0723114-0.9403992-1.3575134-2.1660004-1.3575134-3.2274971
          c0-0.7432022,0.2305145-2.0342026,1.281311-2.9971008c0.7870941-0.7227173,1.8262024-1.160202,3.4570007-1.160202
          c0.6777954,0,1.0820007,0.0440025,1.5860138,0.1094017c0.4160004,0.0546989,0.7655792,0.1533012,1.0936737,0.1865005
          c0.121109,0.0107994,0.1641083,0.0654984,0.1641083,0.1308975c0,0.0879021-0.0323029,0.2186852-0.0547028,0.6016006
          c-0.0214996,0.3613014-0.0108032,0.9627991-0.032196,1.1816025c-0.0108032,0.1640968-0.0332947,0.2519989-0.131897,0.2519989
          c-0.0868988,0-0.1094055-0.0879021-0.1094055-0.2413025c-0.0106964-0.3387985-0.152298-0.7215996-0.425705-1.0057983
          c-0.3613892-0.3828011-1.2148895-0.6786995-2.2216949-0.6786995c-0.9521942,0-1.5751953,0.2411995-2.0567017,0.6786995
          c-0.7987976,0.732399-1.0067902,1.7723999-1.0067902,2.8330002c0,2.6044998,1.9804993,3.8955002,3.4473114,3.8955002
          c0.97258,0,1.5643768-0.1094017,2.0018768-0.6133003c0.1856079-0.2080002,0.3281097-0.5243988,0.371109-0.7216988
          c0.0331879-0.1748009,0.0546875-0.2187004,0.1425934-0.2187004c0.0762024,0,0.0986023,0.0761986,0.0986023,0.1639977
          c0,0.1309013-0.1318054,1.0831032-0.2411957,1.455101c-0.0547028,0.1856003-0.0976105,0.2402992-0.2842102,0.3174019
          c-0.4375,0.1747971-1.2684937,0.2509995-1.969696,0.2509995
          C138.5865784,43.1767998,137.3287811,42.8486977,136.3326874,41.9843979z"></path>
        <path fill="#397DDA" d="M145.1451874,37.9258003c0-1.6631012,0-1.9697151-0.0214996-2.3193016
          c-0.0225067-0.3721008-0.1319122-0.5575981-0.3731079-0.6123009c-0.1190948-0.0331993-0.2617035-0.0439987-0.3934937-0.0439987
          c-0.1094055,0-0.1748047-0.0223999-0.1748047-0.1094017c0-0.0663986,0.0879059-0.0877991,0.2626953-0.0877991
          c0.4150085,0,1.1045227,0.0331993,1.4217987,0.0331993c0.2745056,0,0.9190063-0.0331993,1.3350067-0.0331993
          c0.1425934,0,0.2304993,0.0214005,0.2304993,0.0877991c0,0.0870018-0.0664062,0.1094017-0.1757965,0.1094017
          c-0.1094055,0-0.1973114,0.0107994-0.3281097,0.032299c-0.2949982,0.0545845-0.3828888,0.2411995-0.4042969,0.6240005
          c-0.0224915,0.3495865-0.0224915,0.6562004-0.0224915,2.3193016v1.9258003c0,1.060482,0,1.9247971,0.0438995,2.3954811
          c0.0332947,0.2959175,0.1093903,0.492218,0.4375,0.5361176c0.1533966,0.0224991,0.3936005,0.0439987,0.5585938,0.0439987
          c0.1192017,0,0.1739197,0.0331993,0.1739197,0.0878868c0,0.0761147-0.0869141,0.109314-0.2071228,0.109314
          c-0.7225952,0-1.4120941-0.0331993-1.7070007-0.0331993c-0.2518768,0-0.941391,0.0331993-1.378891,0.0331993
          c-0.1416016,0-0.2187042-0.0331993-0.2187042-0.109314c0-0.0546875,0.0438995-0.0878868,0.175705-0.0878868
          c0.164093,0,0.2950134-0.0214996,0.3936005-0.0439987c0.218689-0.0438995,0.2841949-0.2294998,0.3174133-0.5467987
          c0.0546875-0.4600029,0.0546875-1.3243179,0.0546875-2.3848V37.9258003z"></path>
        <path fill="#397DDA" d="M151.4878845,40.1689987c-0.0547028,0-0.0771027,0.0214996-0.0986023,0.0868988l-0.5907898,1.5643997
          c-0.1094055,0.2735023-0.1641083,0.5362015-0.1641083,0.6680031c0,0.1962967,0.0986023,0.3388977,0.4375,0.3388977h0.1651001
          c0.1307983,0,0.1640015,0.0214996,0.1640015,0.0878868c0,0.0869141-0.0664062,0.109314-0.1864929,0.109314
          c-0.3506165,0-0.8213196-0.0331993-1.1592102-0.0331993c-0.121109,0-0.7225952,0.0331993-1.2910004,0.0331993
          c-0.1426086,0-0.2089996-0.0223999-0.2089996-0.109314c0-0.0663872,0.0448914-0.0878868,0.1318054-0.0878868
          c0.0986938,0,0.2509918-0.0107994,0.3388977-0.0214996c0.503891-0.0663986,0.7109985-0.4384995,0.9296875-0.9854012
          l2.746109-6.8700981c0.1318054-0.3174019,0.1972961-0.4375,0.3065948-0.4375c0.0987091,0,0.1641083,0.0976982,0.2734985,0.3496017
          c0.2627106,0.6015968,2.0136108,5.0654984,2.7129059,6.7284966c0.4160004,0.9844017,0.7333984,1.1377029,0.9629059,1.1924019
          c0.1639862,0.0331993,0.3280945,0.0439987,0.4598999,0.0439987c0.0868988,0,0.1415863,0.0107002,0.1415863,0.0878868
          c0,0.0869141-0.0975952,0.109314-0.5018921,0.109314c-0.3946075,0-1.1934052,0-2.0684052-0.0223999
          c-0.1972961-0.0107994-0.3280945-0.0107994-0.3280945-0.0869141c0-0.0663872,0.0429993-0.0878868,0.152298-0.0986862
          c0.0771942-0.0223999,0.1542969-0.1200981,0.0995941-0.2518997l-0.875-2.319416
          c-0.0223999-0.0545845-0.0555878-0.0760841-0.1102905-0.0760841H151.4878845z M153.7193756,39.6210823
          c0.0547028,0,0.0664062-0.0321846,0.0547028-0.0653839l-0.9843903-2.702198
          c-0.0108032-0.0438995-0.0215149-0.0986023-0.0547028-0.0986023c-0.0332031,0-0.0547028,0.0547028-0.0653992,0.0986023
          l-1.0069122,2.6914978c-0.0106964,0.0438995,0,0.0760841,0.0440063,0.0760841H153.7193756z"></path>
        <path fill="#397DDA" d="M82.1880798,53.6758003c0-1.6631012,0-1.9697151-0.0214996-2.3193016
          c-0.0214005-0.3721008-0.1092987-0.5469017-0.4706955-0.6240005c-0.0868988-0.0214996-0.2733917-0.032299-0.425705-0.032299
          c-0.1210938,0-0.1865997-0.0223999-0.1865997-0.0986023c0-0.077198,0.0772018-0.0985985,0.2412033-0.0985985
          c0.580101,0,1.2686005,0.0331993,1.5967102,0.0331993c0.2626877,0,0.9521866-0.0331993,1.3456879-0.0331993
          c0.1641006,0,0.2412033,0.0214005,0.2412033,0.0985985c0,0.0762024-0.0664062,0.0986023-0.175705,0.0986023
          c-0.1201859,0-0.1856003,0.0107994-0.3163986,0.032299c-0.2958984,0.0545845-0.3828964,0.2411995-0.4052963,0.6240005
          c-0.0224915,0.3495865-0.0224915,0.6562004-0.0224915,2.3193016v1.5312843c0,1.5869141,0.3183899,2.2539139,0.8534851,2.6914139
          c0.4922028,0.405201,0.9961014,0.4491997,1.3682022,0.4491997c0.4813995,0,1.071312-0.1533012,1.5098038-0.5908012
          c0.6015091-0.6015968,0.6337967-1.5868988,0.6337967-2.7138977v-1.3671989c0-1.6631012,0-1.9697151-0.0214996-2.3193016
          c-0.0214996-0.3721008-0.1093979-0.5469017-0.4706955-0.6240005c-0.0870056-0.0214996-0.2735062-0.032299-0.3936005-0.032299
          c-0.1201019,0-0.1864929-0.0223999-0.1864929-0.0986023c0-0.077198,0.0770874-0.0985985,0.2304916-0.0985985
          c0.5585098,0,1.2470016,0.0331993,1.2577972,0.0331993c0.1317978,0,0.8202972-0.0331993,1.2480011-0.0331993
          c0.1523972,0,0.2294998,0.0214005,0.2294998,0.0985985c0,0.0762024-0.0653992,0.0986023-0.1973038,0.0986023
          c-0.1200943,0-0.1864853,0.0107994-0.3172989,0.032299c-0.2949982,0.0545845-0.3828964,0.2411995-0.4042969,0.6240005
          c-0.0214996,0.3495865-0.0214996,0.6562004-0.0214996,2.3193016v1.1708984c0,1.2139015-0.1211014,2.5048981-1.0401001,3.2929993
          c-0.7762909,0.6669998-1.5643997,0.7871017-2.2753983,0.7871017c-0.5800018,0-1.6297989-0.0321999-2.4287033-0.7549019
          c-0.5586014-0.5028992-0.9746017-1.3125-0.9746017-2.8983994V53.6758003z"></path>
        <path fill="#397DDA" d="M92.5435791,57.2861977c0.0214996,0.8535004,0.1641006,1.1376991,0.3828049,1.2148018
          c0.1865005,0.0653992,0.3945007,0.0761986,0.5692978,0.0761986c0.1202011,0,0.1865997,0.0214996,0.1865997,0.0878868
          c0,0.0869141-0.0996017,0.109314-0.2519989,0.109314c-0.7108994,0-1.1483994-0.0331993-1.357399-0.0331993
          c-0.097702,0-0.6123047,0.0331993-1.1816025,0.0331993c-0.1417007,0-0.2402954-0.0116997-0.2402954-0.109314
          c0-0.0663872,0.0663986-0.0878868,0.1757965-0.0878868c0.1416016,0,0.3378983-0.0107994,0.4922028-0.0546989
          c0.2831955-0.0879021,0.3280945-0.4053001,0.3378983-1.3564987l0.0996094-6.4775009
          c0-0.2187004,0.0321884-0.3711014,0.1415863-0.3711014c0.1201019,0,0.2197037,0.1416016,0.405304,0.3389015
          c0.1307983,0.142601,1.7938995,1.9257851,3.3906021,3.5116997c0.7440948,0.7440834,2.2216949,2.3192978,2.4081955,2.4940834
          h0.0547028L98.047493,51.75c-0.0117111-0.6669998-0.1094131-0.875-0.3730011-0.9843025
          c-0.1641083-0.0654984-0.4258118-0.0654984-0.5801086-0.0654984c-0.1308899,0-0.1738892-0.0331993-0.1738892-0.0986023
          c0-0.0878983,0.1191864-0.0985985,0.283287-0.0985985c0.5702972,0,1.0947113,0.0331993,1.3242035,0.0331993
          c0.1210938,0,0.5360947-0.0331993,1.072197-0.0331993c0.142601,0,0.2519989,0.0107002,0.2519989,0.0985985
          c0,0.065403-0.0663986,0.0986023-0.1972961,0.0986023c-0.1093903,0-0.1972046,0-0.3281021,0.032299
          c-0.3066025,0.0877991-0.3936005,0.3172989-0.4042892,0.930584l-0.121109,6.9033165
          c0,0.2402992-0.0429993,0.3389015-0.142601,0.3389015c-0.1191025,0-0.2509003-0.1201019-0.3711014-0.2402153
          c-0.6893997-0.6572876-2.089798-2.0898857-3.2275009-3.2167854c-1.1923981-1.1817017-2.407196-2.5489006-2.6044998-2.7569008
          h-0.0321884L92.5435791,57.2861977z"></path>
        <path fill="#397DDA" d="M102.4849854,53.6758003c0-1.6631012,0-1.9697151-0.0224915-2.3193016
          c-0.0224152-0.3721008-0.131813-0.5575981-0.3720016-0.6123009c-0.1201096-0.0331993-0.2627106-0.0439987-0.3946075-0.0439987
          c-0.1093063,0-0.1738052-0.0223999-0.1738052-0.1094017c0-0.0663986,0.0868988-0.0877991,0.2617035-0.0877991
          c0.4160004,0,1.1054993,0.0331993,1.4218979,0.0331993c0.2743988,0,0.919899-0.0331993,1.3358994-0.0331993
          c0.1416016,0,0.2294998,0.0214005,0.2294998,0.0877991c0,0.0870018-0.0653992,0.1094017-0.1747971,0.1094017
          c-0.1092987,0-0.1972046,0.0107994-0.3281021,0.032299c-0.2958984,0.0545845-0.3827972,0.2411995-0.4052963,0.6240005
          c-0.0223923,0.3495865-0.0223923,0.6562004-0.0223923,2.3193016v1.9257965c0,1.0604858,0,1.9248009,0.0448914,2.3954849
          c0.032196,0.2959175,0.1093979,0.492218,0.4375,0.5361176c0.152298,0.0224991,0.3935013,0.0439987,0.5576096,0.0439987
          c0.1200867,0,0.1747894,0.0331993,0.1747894,0.0878868c0,0.0761147-0.0878983,0.109314-0.2080002,0.109314
          c-0.7217026,0-1.4111023-0.0331993-1.7060013-0.0331993c-0.2519989,0-0.9414978,0.0331993-1.3789978,0.0331993
          c-0.1425018,0-0.2187042-0.0331993-0.2187042-0.109314c0-0.0546875,0.0429993-0.0878868,0.1748047-0.0878868
          c0.1641083,0,0.2958984-0.0214996,0.3936005-0.0439987c0.2186966-0.0438995,0.2850952-0.2294998,0.3182983-0.5468025
          c0.0547028-0.4599991,0.0547028-1.3243141,0.0547028-2.3848V53.6758003z"></path>
        <path fill="#397DDA" d="M107.1949844,51.5751991c-0.2509995-0.6015015-0.4374924-0.7216988-0.8311005-0.8310013
          c-0.1641006-0.0439987-0.3398056-0.0439987-0.4268036-0.0439987c-0.098587,0-0.1317978-0.0331993-0.1317978-0.0986023
          c0-0.0878983,0.1211014-0.0985985,0.2852097-0.0985985c0.5800018,0,1.2030869,0.0331993,1.5858917,0.0331993
          c0.2733994,0,0.7987976-0.0331993,1.3456955-0.0331993c0.1309052,0,0.2519989,0.0214005,0.2519989,0.0985985
          c0,0.0762024-0.0663986,0.0986023-0.164093,0.0986023c-0.1758041,0-0.3398056,0.0107994-0.4268036,0.0761986
          c-0.0771027,0.0546875-0.1102982,0.1318016-0.1102982,0.2294998c0,0.142601,0.0996094,0.4491844,0.2518997,0.8428001
          l1.8828964,4.9668007h0.0429001c0.4707031-1.2032013,1.892601-4.7266006,2.155304-5.4482994
          c0.0546951-0.142601,0.0986099-0.3065987,0.0986099-0.4043007c0-0.0878983-0.0439148-0.1758156-0.1425095-0.2080002
          c-0.1309052-0.0438995-0.2950058-0.0546989-0.4374924-0.0546989c-0.0977097,0-0.1856079-0.0116997-0.1856079-0.0879021
          c0-0.0878983,0.0976944-0.1092987,0.3066101-0.1092987c0.5468903,0,1.0058899,0.0331993,1.1484909,0.0331993
          c0.1855087,0,0.7001953-0.0331993,1.0282974-0.0331993c0.1416016,0,0.2294998,0.0214005,0.2294998,0.0985985
          c0,0.0762024-0.0653992,0.0986023-0.1747971,0.0986023c-0.1094055,0-0.3389053,0-0.5361023,0.1308861
          c-0.142601,0.0986137-0.3067017,0.284214-0.6016006,0.9844131c-0.4267883,1.0293007-0.722702,1.6190987-1.3125,2.9648018
          c-0.7012024,1.5976982-1.214798,2.7899971-1.455101,3.3368988c-0.2851028,0.6348-0.3506012,0.8096008-0.5038986,0.8096008
          c-0.142601,0-0.2080002-0.152401-0.4160004-0.6669998L107.1949844,51.5751991z"></path>
        <path fill="#397DDA" d="M117.0025787,53.6758003c0-1.6631012,0-1.9697151-0.0224991-2.3193016
          c-0.0223999-0.3721008-0.1093979-0.5469017-0.4706955-0.6240005c-0.0868912-0.0214996-0.2733994-0.032299-0.4267044-0.032299
          c-0.1201859,0-0.1856003-0.0223999-0.1856003-0.0986023c0-0.077198,0.0762024-0.0985985,0.2402039-0.0985985
          c0.580101,0,1.2695999,0.0331993,1.57621,0.0331993c0.3495865,0,2.5273895,0,2.7343903-0.0117989
          c0.2080002-0.0214005,0.3827972-0.0429001,0.4706955-0.0653992c0.0547028-0.0107002,0.1211014-0.0439148,0.1758041-0.0439148
          c0.0546951,0,0.0643997,0.0439148,0.0643997,0.0986137c0,0.0770988-0.0547028,0.2080002-0.0868988,0.7108994
          c-0.010704,0.1094017-0.0322037,0.5909004-0.0547028,0.722702c-0.0106888,0.0546989-0.0332031,0.1200981-0.1092987,0.1200981
          c-0.0772018,0-0.0987015-0.0546989-0.0987015-0.1533165c0-0.0761833-0.0106888-0.2626839-0.0653992-0.3935814
          c-0.0772018-0.1972008-0.1865005-0.3281021-0.7333984-0.3935013c-0.1856003-0.0224991-1.3339996-0.0439987-1.5527039-0.0439987
          c-0.0546951,0-0.0771866,0.032299-0.0771866,0.1093979v2.767601c0,0.0770836,0.010788,0.1200829,0.0771866,0.1200829
          c0.2402039,0,1.4980011,0,1.75-0.0214844c0.2617035-0.0215149,0.4267044-0.0546989,0.5252991-0.1639977
          c0.0762024-0.0879021,0.1202011-0.142601,0.1749039-0.142601c0.0438995,0,0.0760956,0.0223999,0.0760956,0.0878983
          c0,0.065403-0.0546951,0.2401848-0.0868988,0.7988014c-0.0224991,0.2187996-0.0438995,0.6562996-0.0438995,0.7323837
          c0,0.0879173,0,0.2080002-0.0986862,0.2080002c-0.0761108,0-0.0986099-0.0438843-0.0986099-0.0985832
          c-0.010704-0.109417-0.010704-0.2509995-0.0438995-0.3936005c-0.0547028-0.2187004-0.2070999-0.3828163-0.6124039-0.4267006
          c-0.2080002-0.0214996-1.2910004-0.0321999-1.5536957-0.0321999c-0.0546036,0-0.0653915,0.0429001-0.0653915,0.1092987v0.8642998
          c0,0.3721008-0.0107117,1.377903,0,1.6953011c0.0224915,0.7549019,0.1962891,0.9188995,1.3134918,0.9188995
          c0.2831955,0,0.7430954,0,1.027298-0.1307983c0.2852097-0.1318016,0.4160004-0.3613014,0.4922104-0.8096008
          c0.0224915-0.1200981,0.0448914-0.1640015,0.1210861-0.1640015c0.0879135,0,0.0879135,0.0869026,0.0879135,0.1962013
          c0,0.2519989-0.0879135,0.9961014-0.1426086,1.214901c-0.0761032,0.2841988-0.1758041,0.2841988-0.5908051,0.2841988
          c-1.652298,0-2.3856964-0.0654984-3.0302963-0.0654984c-0.2518997,0-0.9413986,0.0331993-1.3788986,0.0331993
          c-0.142601,0-0.2187042-0.0331993-0.2187042-0.109314c0-0.0546875,0.0429001-0.0878868,0.1748047-0.0878868
          c0.1639938,0,0.2958984-0.0214996,0.3935089-0.0439987c0.2187881-0.0438995,0.2734909-0.2841148,0.3183899-0.6015015
          c0.0546951-0.4599991,0.0546951-1.3241997,0.0546951-2.330101V53.6758003z"></path>
        <path fill="#397DDA" d="M124.0064926,53.6758003c0-1.6631012,0-1.9697151-0.0225143-2.3193016
          c-0.0223999-0.3721008-0.1093979-0.5469017-0.4706955-0.6240005c-0.0868988-0.0214996-0.2733994-0.032299-0.4267044-0.032299
          c-0.1201935,0-0.1856003-0.0223999-0.1856003-0.0986023c0-0.077198,0.0762024-0.0985985,0.240303-0.0985985
          c0.5800018,0,1.2695007,0.0331993,1.4990005,0.0331993c0.3720016,0,1.2041016-0.0331993,1.5419998-0.0331993
          c0.6893997,0,1.4227982,0.0653992,2.0135956,0.4706993c0.3067017,0.2080002,0.7442017,0.7656021,0.7442017,1.4990005
          c0,0.8096008-0.3399048,1.5527-1.444397,2.4501991c0.9736938,1.2256012,1.7285919,2.1991844,2.3740997,2.877903
          c0.613205,0.6337967,1.0614929,0.7108994,1.2256012,0.7431984c0.1201172,0.0223999,0.2187042,0.0331993,0.305603,0.0331993
          c0.0878906,0,0.131897,0.0331993,0.131897,0.0878868c0,0.0869141-0.0771942,0.109314-0.2080994,0.109314h-1.0390015
          c-0.6132965,0-0.8867035-0.0545998-1.1708984-0.2080002c-0.469696-0.2518997-0.8858032-0.7655983-1.4990005-1.6083984
          c-0.4375-0.6015015-0.9404984-1.3457031-1.0830002-1.5097008c-0.0547028-0.0655022-0.1202011-0.0772171-0.1963043-0.0772171
          l-0.9521942-0.0214844c-0.0547028,0-0.0868912,0.0214844-0.0868912,0.0879021v0.1532974
          c0,1.0167007,0,1.8809013,0.0546875,2.3409004c0.0322037,0.3173866,0.0976028,0.5576019,0.4258041,0.6015015
          c0.1639938,0.0224991,0.4042969,0.0439987,0.5360947,0.0439987c0.0878983,0,0.1318054,0.0331993,0.1318054,0.0878868
          c0,0.0761147-0.0771027,0.109314-0.2187042,0.109314c-0.6347961,0-1.4452972-0.0331993-1.6093979-0.0331993
          c-0.2070007,0-0.8964996,0.0331993-1.3339996,0.0331993c-0.1425018,0-0.2187042-0.0331993-0.2187042-0.109314
          c0-0.0546875,0.0429001-0.0878868,0.1748047-0.0878868c0.1640015,0,0.2958984-0.0214996,0.3935013-0.0439987
          c0.2187958-0.0438995,0.2734985-0.2841148,0.3183975-0.6015015c0.0547104-0.4599991,0.0547104-1.3241997,0.0547104-2.330101
          V53.6758003z M125.297493,54.5400848c0,0.1201134,0.021492,0.1639977,0.0976868,0.1972122
          c0.2304001,0.0762024,0.5586014,0.1094017,0.8320007,0.1094017c0.4375,0,0.580101-0.0438995,0.7763977-0.1865005
          c0.3281021-0.2402,0.6455002-0.7441978,0.6455002-1.6405983c0-1.5538025-1.0283966-2.0020027-1.6738968-2.0020027
          c-0.2733994,0-0.4706879,0.0107002-0.5800018,0.0430031c-0.0761948,0.0223999-0.0976868,0.0663986-0.0976868,0.1532974V54.5400848
          z"></path>
        <path fill="#397DDA" d="M132.6079865,58.6425972c-0.175705-0.0761986-0.2080078-0.1308975-0.2080078-0.372097
          c0-0.6015015,0.0440063-1.2578011,0.0547028-1.4326019c0.0108185-0.1640015,0.0440063-0.285099,0.1309052-0.285099
          c0.098587,0,0.1102905,0.0985985,0.1102905,0.1865005c0,0.1415977,0.0429993,0.372097,0.097702,0.5575981
          c0.2402039,0.8096008,0.8867035,1.1054993,1.5644073,1.1054993c0.9843903,0,1.4667969-0.6679993,1.4667969-1.247097
          c0-0.5361023-0.1640015-1.0508003-1.0722046-1.7510033l-0.503891-0.3934975
          c-1.2032013-0.9414024-1.6192017-1.7070999-1.6192017-2.5928001c0-1.2041016,1.0058899-2.0684013,2.5273895-2.0684013
          c0.710907,0,1.1699066,0.1094017,1.4551086,0.1865997c0.0986023,0.0214005,0.1532898,0.0545998,0.1532898,0.1308022
          c0,0.1425972-0.0439911,0.4599991-0.0439911,1.3125c0,0.2411995-0.0332031,0.3290977-0.1201019,0.3290977
          c-0.0771027,0-0.1094055-0.0663986-0.1094055-0.1972008c0-0.0986977-0.0555878-0.4375-0.2840881-0.7216988
          c-0.1641083-0.2080002-0.4825134-0.5361977-1.1934052-0.5361977c-0.8085938,0-1.3017883,0.469799-1.3017883,1.1259995
          c0,0.5038986,0.2519989,0.8866997,1.1591797,1.5761986l0.3067017,0.2294998
          c1.3134003,1.017601,1.7949066,1.75,1.7949066,2.7900009c0,0.6348-0.2411957,1.389698-1.0292969,1.9033012
          c-0.5469055,0.3505821-1.1592102,0.4482994-1.7382965,0.4482994
          C133.5708771,58.9267998,133.0787811,58.8505974,132.6079865,58.6425972z"></path>
        <path fill="#397DDA" d="M139.5699768,53.6758003c0-1.6631012,0-1.9697151-0.0224915-2.3193016
          c-0.0214996-0.3721008-0.1309052-0.5575981-0.371109-0.6123009c-0.1210938-0.0331993-0.2637024-0.0439987-0.3945007-0.0439987
          c-0.1093903,0-0.1747894-0.0223999-0.1747894-0.1094017c0-0.0663986,0.0868988-0.0877991,0.2626953-0.0877991
          c0.4160004,0,1.1053925,0.0331993,1.4217987,0.0331993c0.2734985,0,0.9199982-0.0331993,1.3350067-0.0331993
          c0.1415863,0,0.2294922,0.0214005,0.2294922,0.0877991c0,0.0870018-0.0653992,0.1094017-0.1748047,0.1094017
          c-0.1093903,0-0.1962891,0.0107994-0.3280945,0.032299c-0.2958984,0.0545845-0.3827972,0.2411995-0.405304,0.6240005
          c-0.0214996,0.3495865-0.0214996,0.6562004-0.0214996,2.3193016v1.9257965c0,1.0604858,0,1.9248009,0.0440063,2.3954849
          c0.032196,0.2959175,0.1092987,0.492218,0.4375,0.5361176c0.1533051,0.0224991,0.3934937,0.0439987,0.5576172,0.0439987
          c0.1210785,0,0.1757812,0.0331993,0.1757812,0.0878868c0,0.0761147-0.0879059,0.109314-0.2089996,0.109314
          c-0.7207031,0-1.410202-0.0331993-1.7061005-0.0331993c-0.2510071,0-0.9403992,0.0331993-1.3778992,0.0331993
          c-0.1425934,0-0.2187958-0.0331993-0.2187958-0.109314c0-0.0546875,0.0429993-0.0878868,0.1738892-0.0878868
          c0.1640015,0,0.2958984-0.0214996,0.3945007-0.0439987c0.2187042-0.0438995,0.2842102-0.2294998,0.3173981-0.5468025
          c0.0547028-0.4599991,0.0547028-1.3243141,0.0547028-2.3848V53.6758003z"></path>
        <path fill="#397DDA" d="M144.7056732,53.6758003c0-1.6631012,0-1.9697151-0.0214996-2.3193016
          c-0.0214996-0.3721008-0.1093903-0.5469017-0.4706879-0.6240005c-0.0879059-0.0214996-0.273407-0.032299-0.426712-0.032299
          c-0.1201935,0-0.1855927-0.0223999-0.1855927-0.0986023c0-0.077198,0.0762024-0.0985985,0.2411957-0.0985985
          c0.5791168,0,1.2686005,0.0331993,1.5742035,0.0331993c0.3399048,0,1.0292969-0.0331993,1.6856079-0.0331993
          c1.3671875,0,3.1943054,0,4.3866882,1.2470016c0.5469055,0.569397,1.0615082,1.4776001,1.0615082,2.7792969
          c0,1.3780022-0.5800018,2.4287033-1.1923981,3.0517883c-0.5039062,0.5146141-1.6406097,1.2909966-3.6650085,1.2909966
          c-0.7987976,0-1.7841949-0.1308823-2.3309937-0.1308823c-0.2510071,0-0.9404907,0.0331993-1.378006,0.0331993
          c-0.1416016,0-0.2187042-0.0331993-0.2187042-0.109314c0-0.0546875,0.0438995-0.0878868,0.1748047-0.0878868
          c0.1640015,0,0.2949066-0.0214996,0.3945007-0.0439987c0.2187958-0.0438995,0.273407-0.2841148,0.3164062-0.6015015
          c0.0546875-0.4599991,0.0546875-1.3241997,0.0546875-2.330101V53.6758003z M146.072876,54.8788986
          c0,1.160183,0.0117035,2.0019989,0.0225067,2.2099991c0.0106964,0.2733994,0.032196,0.7119026,0.1201172,0.8320007
          c0.1424866,0.2080002,0.5692749,0.4375,1.4335785,0.4375c1.1152039,0,1.8592987-0.2187004,2.5156097-0.7881012
          c0.7011871-0.6014977,0.9198914-1.5965996,0.9198914-2.7235985c0-1.3896141-0.5800934-2.2871017-1.0507965-2.735302
          c-1.0057983-0.9628983-2.2539062-1.0937996-3.1074066-1.0937996c-0.218689,0-0.6230011,0.0321999-0.7108765,0.0762024
          c-0.0977173,0.0438995-0.1309204,0.0985985-0.1309204,0.2187004c-0.0117035,0.372097-0.0117035,1.3241997-0.0117035,2.1884995
          V54.8788986z"></path>
        <path fill="#397DDA" d="M155.3931732,55.9189987c-0.0546875,0-0.0761871,0.0214996-0.0976868,0.0868988l-0.5917053,1.5643997
          c-0.1094055,0.2735023-0.164093,0.5362015-0.164093,0.6680031c0,0.1962967,0.0995941,0.3388977,0.4375,0.3388977h0.1649933
          c0.1309052,0,0.164093,0.0214996,0.164093,0.0878868c0,0.0869141-0.0653992,0.109314-0.1864929,0.109314
          c-0.3495941,0-0.820282-0.0331993-1.1591949-0.0331993c-0.1200867,0-0.7217102,0.0331993-1.2910004,0.0331993
          c-0.1426086,0-0.2080078-0.0223999-0.2080078-0.109314c0-0.0663872,0.0439148-0.0878868,0.1307983-0.0878868
          c0.0996094,0,0.2519989-0.0107994,0.3399048-0.0214996c0.5028992-0.0663986,0.7108917-0.4384995,0.9297028-0.9854012
          l2.7460022-6.8700981c0.1308899-0.3174019,0.1972961-0.4375,0.3066864-0.4375c0.0976105,0,0.1641083,0.0976982,0.273407,0.3495979
          c0.2617035,0.6016006,2.0126953,5.0655022,2.7129059,6.7285004c0.4160004,0.9844017,0.7323914,1.1377029,0.9628906,1.1924019
          c0.1641083,0.0331993,0.3281097,0.0439987,0.4589996,0.0439987c0.0879059,0,0.1426239,0.0107002,0.1426239,0.0878868
          c0,0.0869141-0.0977173,0.109314-0.503006,0.109314c-0.3935089,0-1.1923065,0-2.0673065-0.0223999
          c-0.1973114-0.0107994-0.3282013-0.0107994-0.3282013-0.0869141c0-0.0663872,0.0429993-0.0878868,0.1523895-0.0986862
          c0.0761108-0.0223999,0.1533051-0.1201019,0.0986023-0.2518997l-0.875-2.319416
          c-0.0224915-0.0545845-0.0547028-0.0760841-0.1102905-0.0760841H155.3931732z M157.6255798,55.3710823
          c0.0547028,0,0.065506-0.0321846,0.0547028-0.0653839l-0.9844055-2.702198
          c-0.0117035-0.0439034-0.0223846-0.0986023-0.0545959-0.0986023c-0.0332947,0-0.0547028,0.0546989-0.0664978,0.0986023
          l-1.0057983,2.6914978c-0.0117035,0.0438995,0,0.0760841,0.0429993,0.0760841H157.6255798z"></path>
        <path fill="#397DDA" d="M163.4244995,53.6758003c0-1.6631012,0-1.9697151-0.022522-2.3193016
          c-0.0224915-0.3721008-0.1093903-0.5469017-0.4707031-0.6240005c-0.0868988-0.0214996-0.2733917-0.032299-0.4267883-0.032299
          c-0.1201019,0-0.1855011-0.0223999-0.1855011-0.0986023c0-0.077198,0.0762024-0.0985985,0.2401886-0.0985985
          c0.5801086,0,1.2696075,0.0331993,1.5762024,0.0331993c0.3388977,0,1.027298-0.0331993,1.6846008-0.0331993
          c1.3670959,0,3.1943054,0,4.3867035,1.2470016c0.5478058,0.569397,1.0614929,1.4776001,1.0614929,2.7792969
          c0,1.3780022-0.5800934,2.4287033-1.1923981,3.0517883c-0.5028992,0.5146141-1.6416016,1.2909966-3.6649933,1.2909966
          c-0.7987976,0-1.7832031-0.1308823-2.3301086-0.1308823c-0.2518921,0-0.941391,0.0331993-1.378891,0.0331993
          c-0.1426086,0-0.2187958-0.0331993-0.2187958-0.109314c0-0.0546875,0.0429993-0.0878868,0.1748962-0.0878868
          c0.1640015,0,0.2958984-0.0214996,0.3934937-0.0439987c0.218811-0.0438995,0.273407-0.2841148,0.3184052-0.6015015
          c0.054718-0.4599991,0.054718-1.3241997,0.054718-2.330101V53.6758003z M164.7915802,54.8788986
          c0,1.160183,0.0108032,2.0019989,0.0214996,2.2099991c0.0117035,0.2733994,0.0332031,0.7119026,0.1210938,0.8320007
          c0.1416016,0.2080002,0.5684052,0.4375,1.4326019,0.4375c1.1162109,0,1.8603973-0.2187004,2.5166016-0.7881012
          c0.6993103-0.6014977,0.9179993-1.5965996,0.9179993-2.7235985c0-1.3896141-0.5791016-2.2871017-1.0497894-2.735302
          c-1.0068054-0.9628983-2.2528992-1.0937996-3.1063995-1.0937996c-0.218811,0-0.6240997,0.0321999-0.7110138,0.0762024
          c-0.0995941,0.0438995-0.1317902,0.0985985-0.1317902,0.2187004c-0.0108032,0.372097-0.0108032,1.3241997-0.0108032,2.1884995
          V54.8788986z"></path>
        <path fill="#397DDA" d="M82.8228836,73.4843979c-1.0723038-0.9403992-1.3564987-2.1660004-1.3564987-3.2275009
          c0-0.7432022,0.2294998-2.0341949,1.2802963-2.9971008c0.7871017-0.7227097,1.8272018-1.1601944,3.4570999-1.1601944
          c0.6777115,0,1.0830002,0.0439987,1.5858994,0.1093979c0.4160004,0.0546951,0.7666016,0.1532974,1.0947037,0.1865005
          c0.1201096,0.0107956,0.1641083,0.0654984,0.1641083,0.1308975c0,0.0878983-0.0322113,0.218689-0.0547104,0.6016006
          c-0.0224991,0.3612976-0.0108032,0.9627991-0.0322037,1.1816025c-0.011795,0.1641006-0.0331955,0.2519989-0.131897,0.2519989
          c-0.0878983,0-0.1092987-0.0878983-0.1092987-0.2413025c-0.0108032-0.3387985-0.1534042-0.7215958-0.4268036-1.0057983
          c-0.3612976-0.3827972-1.214798-0.6787033-2.2206955-0.6787033c-0.9522018,0-1.5762024,0.2412033-2.0566025,0.6787033
          c-0.7988968,0.732399-1.006897,1.7723999-1.006897,2.8330002c0,2.6044998,1.9794998,3.8955002,3.4462967,3.8955002
          c0.9736023,0,1.5644989-0.1093979,2.0019989-0.6133041c0.1855011-0.2080002,0.3281021-0.5243988,0.3720016-0.7216949
          c0.0323029-0.1748047,0.0547028-0.2187042,0.1416016-0.2187042c0.0772095,0,0.0996017,0.0762024,0.0996017,0.1640015
          c0,0.1308975-0.1318054,1.0830994-0.2412033,1.455101c-0.0547028,0.1856003-0.0986023,0.240303-0.2841873,0.3173981
          c-0.4375153,0.1748047-1.2695084,0.2509995-1.9697113,0.2509995C85.0767822,74.676796,83.818985,74.3487015,82.8228836,73.4843979
          z"></path>
        <path fill="#397DDA" d="M92.7134933,71.6689987c-0.0547104,0-0.07621,0.0214996-0.0986099,0.0868988l-0.5908051,1.5643997
          c-0.1093979,0.2734985-0.164093,0.5362015-0.164093,0.6679993c0,0.1963043,0.0985947,0.3389053,0.4375076,0.3389053h0.1640854
          c0.130806,0,0.1640015,0.0214996,0.1640015,0.087883c0,0.0869141-0.0653992,0.109314-0.1855011,0.109314
          c-0.3495865,0-0.8202972-0.0332031-1.1601944-0.0332031c-0.1201019,0-0.7217026,0.0332031-1.2910004,0.0332031
          c-0.142601,0-0.2070007-0.0223999-0.2070007-0.109314c0-0.0663834,0.0429001-0.087883,0.1307983-0.087883
          c0.0987015,0,0.2519989-0.0108032,0.3388977-0.0215073c0.5029144-0.0663986,0.7119141-0.4384995,0.9307022-0.9853973
          l2.7461014-6.8701019c0.1307983-0.3173981,0.1961975-0.4375,0.3055954-0.4375c0.0987015,0,0.1641006,0.097702,0.2735138,0.3496017
          c0.2626877,0.6016006,2.0126877,5.0654984,2.7137909,6.7285004c0.4160004,0.9843979,0.7324982,1.1377029,0.9628983,1.1923981
          c0.1641006,0.0332031,0.3281021,0.0440063,0.4589996,0.0440063c0.0878983,0,0.142601,0.0106964,0.142601,0.087883
          c0,0.0869141-0.0987015,0.109314-0.5038986,0.109314c-0.3936005,0-1.1923904,0-2.0673904-0.0223999
          c-0.196312-0.0108032-0.3281097-0.0108032-0.3281097-0.0869141c0-0.0663834,0.0438995-0.087883,0.1532974-0.0986862
          c0.0762024-0.0223999,0.152298-0.1201019,0.0976028-0.2518997l-0.875-2.3194122
          c-0.0214005-0.0545883-0.0546036-0.076088-0.1092987-0.076088H92.7134933z M94.9458847,71.1210861
          c0.0546951,0,0.0644989-0.0321884,0.0546951-0.0653915l-0.9852982-2.7021942
          c-0.010788-0.0438995-0.0224991-0.0986023-0.0547028-0.0986023c-0.032196,0-0.0546951,0.0547028-0.0653992,0.0986023
          l-1.006897,2.6914978c-0.010704,0.0438995,0,0.076088,0.0439987,0.076088H94.9458847z"></path>
        <path fill="#397DDA" d="M101.4790802,66.8652954l-1.6307983,0.0438995
          c-0.6337891,0.0225067-0.8964996,0.0772018-1.0606003,0.3174057c-0.1092987,0.1641006-0.1640015,0.2958984-0.1855011,0.3827972
          c-0.0224991,0.0878983-0.0546875,0.1317978-0.1211014,0.1317978c-0.0761948,0-0.0976944-0.0545959-0.0976944-0.1756973
          c0-0.1747971,0.2071075-1.1699982,0.2285995-1.2578964c0.0331955-0.1425171,0.0663986-0.2080002,0.1317978-0.2080002
          c0.0868988,0,0.1962967,0.1093979,0.4707108,0.131897c0.3173904,0.032196,0.7333908,0.0547028,1.093689,0.0547028h4.3330994
          c0.6992111,0,0.9627991-0.1094055,1.0497971-0.1094055c0.0771027,0,0.0869064,0.0653992,0.0869064,0.2294998
          c0,0.2294998-0.0323029,0.9844055-0.0323029,1.2685013c-0.0106964,0.1093979-0.0321884,0.1758041-0.0986023,0.1758041
          c-0.0878983,0-0.1093979-0.0547028-0.1200943-0.2187042l-0.010704-0.1211014
          c-0.0214996-0.2841949-0.3173981-0.5907974-1.2910995-0.6122971l-1.3788986-0.0332031v4.4863052
          c0,1.0058975,0,1.8700943,0.0546951,2.3407974c0.0332031,0.3066864,0.0986023,0.5468979,0.4268036,0.5907974
          c0.1532974,0.0224991,0.3935013,0.0440063,0.5576019,0.0440063c0.121109,0,0.1757965,0.0331955,0.1757965,0.087883
          c0,0.0761108-0.0878983,0.109314-0.2080002,0.109314c-0.7216873,0-1.4112015-0.0332031-1.7177963-0.0332031
          c-0.2519989,0-0.9413986,0.0332031-1.378891,0.0332031c-0.1416092,0-0.218811-0.0332031-0.218811-0.109314
          c0-0.0546875,0.0439987-0.087883,0.1758118-0.087883c0.1640854,0,0.2948914-0.0215073,0.3935852-0.0440063
          c0.2187042-0.0438995,0.2842026-0.284111,0.3174133-0.6014938c0.0545883-0.4600067,0.0545883-1.3242035,0.0545883-2.330101
          V66.8652954z"></path>
        <path fill="#397DDA" d="M106.7320786,70.3886948c0-1.8710938,1.2363052-4.289093,4.5185013-4.289093
          c2.7246017,0,4.4199142,1.586998,4.4199142,4.0917969c0,2.5058975-1.75,4.4853973-4.529213,4.4853973
          C108.0005798,74.676796,106.7320786,72.3251953,106.7320786,70.3886948z M114.1392822,70.5966949
          c0-2.4511948-1.4121017-3.9716949-3.2061005-3.9716949c-1.2577972,0-2.6913986,0.7001953-2.6913986,3.4247971
          c0,2.2753983,1.2587967,4.080101,3.3583984,4.080101C112.3667831,74.1298981,114.1392822,73.7577972,114.1392822,70.5966949z
          M113.0122833,64.5136948c0.0654984,0.1309052,0,0.1641006-0.0546036,0.1641006c-0.0546951,0-0.1533966-0.0224991-0.3721008,0
          c-0.6026001,0.0761032-1.4657974,0.4267044-1.8710861,0.6455002c-0.0869141,0.0438995-0.1641083,0.0869064-0.1865082,0.0869064
          c-0.021492,0-0.0644913-0.0215073-0.0644913-0.0654068c0-0.0439987,0.0429993-0.086998,0.0976868-0.1415939
          c0.4375-0.4161148,1.5858994-1.2696037,1.7617035-1.3790016c0.1093979-0.0761147,0.1855011-0.0986023,0.2186966-0.0986023
          c0.0762024,0,0.0987015,0.0331993,0.1534042,0.1319008C112.7818832,64.0097961,112.8814926,64.2177963,113.0122833,64.5136948z"></path>
        <path fill="#397DDA" d="M119.3697815,71.3516006c0,1.4003983,0,2.1552963,0.2304001,2.3516006
          c0.1856003,0.1639938,0.4698029,0.2411957,1.3233109,0.2411957c0.5800858,0,1.0067902-0.0107956,1.2802887-0.3067017
          c0.1307983-0.1416092,0.2617111-0.4481964,0.2841034-0.6561966c0.0107956-0.0987015,0.0322952-0.1641006,0.1200943-0.1641006
          c0.0772018,0,0.0879059,0.0546875,0.0879059,0.1856003c0,0.1210861-0.0761032,0.9413986-0.1640015,1.2586975
          c-0.066391,0.240303-0.1211014,0.2950058-0.6894989,0.2950058c-1.5752029,0-2.2968903-0.065506-3.1836014-0.065506
          c-0.2518997,0-0.9413986,0.0332031-1.3788986,0.0332031c-0.1416016,0-0.2187042-0.0332031-0.2187042-0.109314
          c0-0.0546875,0.0438995-0.087883,0.1758041-0.087883c0.1639938,0,0.294899-0.0215073,0.3935089-0.0440063
          c0.2187881-0.0438995,0.2733917-0.284111,0.3173904-0.6014938c0.0546951-0.4600067,0.0546951-1.3242035,0.0546951-2.330101
          v-1.9258041c0-1.6631012,0-1.9697113-0.0214996-2.3192978c-0.0224991-0.3721008-0.1093979-0.5468979-0.4706955-0.6240005
          c-0.0878906-0.0214996-0.2188034-0.0323029-0.3613052-0.0323029c-0.1210938,0-0.185585-0.0223999-0.185585-0.0985947
          c0-0.0772018,0.0761871-0.0986023,0.2402878-0.0986023c0.5136032,0,1.2031021,0.0332031,1.4990005,0.0332031
          c0.2627106,0,1.0615005-0.0332031,1.4872971-0.0332031c0.1543045,0,0.2304153,0.0214005,0.2304153,0.0986023
          c0,0.0761948-0.0664139,0.0985947-0.1972122,0.0985947c-0.1201019,0-0.294899,0.0108032-0.4267883,0.0323029
          c-0.2959137,0.0545883-0.3828125,0.2412033-0.4052124,0.6240005c-0.0214996,0.3495865-0.0214996,0.6561966-0.0214996,2.3192978
          V71.3516006z"></path>
        <path fill="#397DDA" d="M124.9693832,69.4257965c0-1.6631012,0-1.9697113-0.0214996-2.3192978
          c-0.0224991-0.3721008-0.1318054-0.5576019-0.3731003-0.6122971c-0.1191025-0.0332031-0.2617035-0.0440063-0.3935013-0.0440063
          c-0.1093979,0-0.1747894-0.0223999-0.1747894-0.1093979c0-0.0663986,0.0878906-0.0877991,0.2626877-0.0877991
          c0.4150009,0,1.1044998,0.0332031,1.4218979,0.0332031c0.2744141,0,0.9188995-0.0332031,1.3348999-0.0332031
          c0.142601,0,0.2305145,0.0214005,0.2305145,0.0877991c0,0.086998-0.0664139,0.1093979-0.1758118,0.1093979
          s-0.1972961,0.0108032-0.3281021,0.0323029c-0.294899,0.0545883-0.3827972,0.2412033-0.4042969,0.6240005
          c-0.0224991,0.3495865-0.0224991,0.6561966-0.0224991,2.3192978v1.9258041c0,1.0604858,0,1.9247971,0.0439987,2.3954849
          c0.0332031,0.2959137,0.1092987,0.4922104,0.4375,0.5361099c0.1532974,0.0224991,0.3935013,0.0440063,0.5586014,0.0440063
          c0.1190948,0,0.1737976,0.0331955,0.1737976,0.087883c0,0.0761108-0.0868988,0.109314-0.2070007,0.109314
          c-0.722702,0-1.4121857-0.0332031-1.7070999-0.0332031c-0.2518997,0-0.9413986,0.0332031-1.3788986,0.0332031
          c-0.1416016,0-0.2186966-0.0332031-0.2186966-0.109314c0-0.0546875,0.0438995-0.087883,0.1757965-0.087883
          c0.1640015,0,0.294899-0.0215073,0.3935013-0.0440063c0.2187958-0.0438995,0.2842102-0.2294998,0.3173981-0.5467987
          c0.0547028-0.4599991,0.0547028-1.3243103,0.0547028-2.3847961V69.4257965z"></path>
        <path fill="#397DDA" d="M129.9986877,73.4843979c-1.0723114-0.9403992-1.3574066-2.1660004-1.3574066-3.2275009
          c0-0.7432022,0.2303925-2.0341949,1.2802124-2.9971008c0.7880859-0.7227097,1.8271942-1.1601944,3.4570923-1.1601944
          c0.6786957,0,1.0829926,0.0439987,1.5869141,0.1093979c0.4159851,0.0546951,0.7655792,0.1532974,1.0936737,0.1865005
          c0.1201019,0.0107956,0.1641083,0.0654984,0.1641083,0.1308975c0,0.0878983-0.0332031,0.218689-0.0547028,0.6016006
          c-0.0214996,0.3612976-0.0117035,0.9627991-0.0332031,1.1816025c-0.010788,0.1641006-0.0331879,0.2519989-0.1308899,0.2519989
          c-0.0879059,0-0.1092987-0.0878983-0.1092987-0.2413025c-0.0118103-0.3387985-0.1534119-0.7215958-0.4268036-1.0057983
          c-0.3612976-0.3827972-1.2138977-0.6787033-2.2217102-0.6787033c-0.9511871,0-1.5741882,0.2412033-2.0565948,0.6787033
          c-0.7987976,0.732399-1.0059052,1.7723999-1.0059052,2.8330002c0,2.6044998,1.9805145,3.8955002,3.4463196,3.8955002
          c0.973587,0,1.5644836-0.1093979,2.0019989-0.6133041c0.1864929-0.2080002,0.3280945-0.5243988,0.371994-0.7216949
          c0.0331879-0.1748047,0.0546875-0.2187042,0.1425934-0.2187042c0.0762024,0,0.097702,0.0762024,0.097702,0.1640015
          c0,0.1308975-0.1309052,1.0830994-0.2402954,1.455101c-0.0547028,0.1856003-0.0986023,0.240303-0.2851105,0.3173981
          c-0.4375,0.1748047-1.2686005,0.2509995-1.9687958,0.2509995
          C132.2515869,74.676796,130.9937744,74.3487015,129.9986877,73.4843979z"></path>
        <path fill="#397DDA" d="M139.8882751,71.6689987c-0.0546875,0-0.0770874,0.0214996-0.098587,0.0868988l-0.5908051,1.5643997
          c-0.1093903,0.2734985-0.1641083,0.5362015-0.1641083,0.6679993c0,0.1963043,0.0986023,0.3389053,0.4375,0.3389053h0.1650085
          c0.1309052,0,0.164093,0.0214996,0.164093,0.087883c0,0.0869141-0.066391,0.109314-0.1864929,0.109314
          c-0.3506012,0-0.8213043-0.0332031-1.1591949-0.0332031c-0.121109,0-0.722702,0.0332031-1.2910004,0.0332031
          c-0.1426086,0-0.2089996-0.0223999-0.2089996-0.109314c0-0.0663834,0.0448914-0.087883,0.1318054-0.087883
          c0.0986938,0,0.2509918-0.0108032,0.3388824-0.0215073c0.5039062-0.0663986,0.710907-0.4384995,0.9297028-0.9853973
          l2.746109-6.8701019c0.1317902-0.3173981,0.1973114-0.4375,0.3065948-0.4375c0.0987091,0,0.164093,0.097702,0.2734985,0.3496017
          c0.2626953,0.6016006,2.0135956,5.0654984,2.7129059,6.7285004c0.4160004,0.9843979,0.7333984,1.1377029,0.9627991,1.1923981
          c0.164093,0.0332031,0.3282013,0.0440063,0.4599915,0.0440063c0.0868988,0,0.1416016,0.0106964,0.1416016,0.087883
          c0,0.0869141-0.0975952,0.109314-0.5018921,0.109314c-0.3946075,0-1.1934052,0-2.0684052-0.0223999
          c-0.1972961-0.0108032-0.3280945-0.0108032-0.3280945-0.0869141c0-0.0663834,0.0428925-0.087883,0.152298-0.0986862
          c0.0771942-0.0223999,0.1542969-0.1201019,0.0995941-0.2518997l-0.875-2.3194122
          c-0.0223999-0.0545883-0.0555878-0.076088-0.1103058-0.076088H139.8882751z M142.1197815,71.1210861
          c0.054718,0,0.0664062-0.0321884,0.054718-0.0653915l-0.9844208-2.7021942
          c-0.0108032-0.0438995-0.0214996-0.0986023-0.0547028-0.0986023c-0.0331879,0-0.0547028,0.0547028-0.0653992,0.0986023
          l-1.006897,2.6914978c-0.0106964,0.0438995,0,0.076088,0.0440063,0.076088H142.1197815z"></path>
        <path fill="#397DDA" d="M82.658783,85.1757965c0-1.6631012,0-1.9697113-0.0214996-2.3192978
          c-0.0214005-0.3721008-0.1092987-0.5468979-0.4707031-0.6240005c-0.0877991-0.0214996-0.2733994-0.0323029-0.4266968-0.0323029
          c-0.1201019,0-0.1856003-0.0223999-0.1856003-0.0985947c0-0.0772018,0.07621-0.0986023,0.2412109-0.0986023
          c0.5790863,0,1.2685852,0.0332031,1.5742874,0.0332031c0.339798,0,1.0293045-0.0332031,1.6855011-0.0332031
          c1.3672104,0,3.1942978,0,4.3866959,1.2470016c0.5469055,0.569397,1.0616074,1.4776001,1.0616074,2.7792969
          c0,1.3779984-0.5800934,2.4287033-1.1924057,3.0517883c-0.5038986,0.5146103-1.640686,1.2910004-3.6651001,1.2910004
          c-0.7987976,0-1.7840958-0.1308899-2.3310013-0.1308899c-0.2509995,0-0.9404984,0.0332031-1.3779984,0.0332031
          c-0.1415863,0-0.2186966-0.0332031-0.2186966-0.109314c0-0.0546875,0.0438995-0.0878906,0.1747971-0.0878906
          c0.1641006,0,0.294899-0.0214996,0.3945007-0.0439987c0.218811-0.0438995,0.2734985-0.284111,0.3163986-0.6015015
          c0.0547028-0.4599991,0.0547028-1.3241959,0.0547028-2.3300934V85.1757965z M84.0259857,86.3788986
          c0,1.1601868,0.0116959,2.0019989,0.0225067,2.2099991c0.0106888,0.2733994,0.0321884,0.7118988,0.1200867,0.8320007
          c0.142601,0.2080002,0.5693054,0.4375,1.4336014,0.4375c1.1152039,0,1.8594055-0.2187042,2.5156021-0.7881012
          c0.7012024-0.6015015,0.919899-1.5965958,0.919899-2.7236023c0-1.3896103-0.5800018-2.2870941-1.0506973-2.7352982
          c-1.0059052-0.9628983-2.2540054-1.0937958-3.1074905-1.0937958c-0.2187119,0-0.6230011,0.032196-0.7109146,0.0761948
          c-0.0976944,0.0438995-0.1308975,0.0986023-0.1308975,0.2187042c-0.0116959,0.3721008-0.0116959,1.3241959-0.0116959,2.1884995
          V86.3788986z"></path>
        <path fill="#397DDA" d="M92.7867813,85.1757965c0-1.6631012,0-1.9697113-0.0224991-2.3192978
          c-0.0214996-0.3721008-0.1093979-0.5468979-0.4707031-0.6240005c-0.0868988-0.0214996-0.2733994-0.0323029-0.4257965-0.0323029
          c-0.1211014,0-0.1855011-0.0223999-0.1855011-0.0985947c0-0.0772018,0.0761032-0.0986023,0.2402115-0.0986023
          c0.5800858,0,1.2694855,0.0332031,1.5751877,0.0332031c0.3506012,0,2.527298,0,2.7352982-0.0118027
          c0.2070999-0.0214005,0.3829041-0.0429001,0.4708023-0.0653992c0.0546036-0.010704,0.1201019-0.0439148,0.1747971-0.0439148
          c0.0546036,0,0.0654068,0.0439148,0.0654068,0.0986099c0,0.0771027-0.0547028,0.2080002-0.0879059,0.7108994
          c-0.0106964,0.1094055-0.032196,0.5909042-0.0546951,0.722702c-0.010704,0.0547028-0.0322037,0.1201019-0.1094055,0.1201019
          c-0.0760956,0-0.0975952-0.0547028-0.0975952-0.1533127c0-0.0761871-0.0117035-0.2626877-0.0663986-0.3935852
          c-0.0762024-0.1972046-0.1856003-0.3281021-0.7324905-0.3935013c-0.1865158-0.0224991-1.3349152-0.0439987-1.5537109-0.0439987
          c-0.0547028,0-0.0771027,0.0322952-0.0771027,0.1093979v2.767601c0,0.0770874,0.0117035,0.1200867,0.0771027,0.1200867
          c0.2411957,0,1.4990997,0,1.7509995-0.0214844c0.2617111-0.0215149,0.4257965-0.0547028,0.5243988-0.1640015
          c0.0772018-0.0879059,0.1201019-0.142601,0.1747971-0.142601c0.0440063,0,0.0772018,0.0223999,0.0772018,0.0878983
          c0,0.0653992-0.055687,0.2401886-0.0878983,0.7987976c-0.0224991,0.2188034-0.0439987,0.6563034-0.0439987,0.7323914
          c0,0.0879135,0,0.2080002-0.0976028,0.2080002c-0.0771866,0-0.0996017-0.0438919-0.0996017-0.098587
          c-0.0097961-0.1094131-0.0097961-0.2509995-0.0429993-0.3936005c-0.0546951-0.2187042-0.2080002-0.3828125-0.6132965-0.4267044
          c-0.2070007-0.0214996-1.2910004-0.032196-1.5527039-0.032196c-0.0546951,0-0.0663986,0.0429001-0.0663986,0.1092987v0.8643036
          c0,0.3721008-0.0107956,1.3778992,0,1.6952972c0.0223999,0.7548981,0.1973038,0.9188995,1.3134995,0.9188995
          c0.2841034,0,0.743103,0,1.0283127-0.1307983c0.2841873-0.1317978,0.4160004-0.3612976,0.4921875-0.8096008
          c0.0214996-0.1201019,0.0438995-0.1640015,0.1201019-0.1640015c0.0878983,0,0.0878983,0.0868988,0.0878983,0.1961975
          c0,0.2520065-0.0878983,0.9961014-0.142601,1.2149048c-0.0761948,0.2841949-0.1747971,0.2841949-0.5907974,0.2841949
          c-1.6523972,0-2.3847885-0.0654984-3.0302963-0.0654984c-0.2510071,0-0.9404068,0.0332031-1.3779068,0.0332031
          c-0.1425858,0-0.2187958-0.0332031-0.2187958-0.109314c0-0.0546875,0.0429993-0.0878906,0.1738968-0.0878906
          c0.1640015,0,0.2959061-0.0214996,0.3945007-0.0439987c0.2187042-0.0438995,0.2733994-0.284111,0.3173981-0.6015015
          c0.0547028-0.4599991,0.0547028-1.3241959,0.0547028-2.3300934V85.1757965z"></path>
        <path fill="#397DDA" d="M103.6949844,89.2343979c-1.0723038-0.9403992-1.3564911-2.1660004-1.3564911-3.2275009
          c0-0.7432022,0.2294922-2.0342026,1.2792892-2.9971008c0.7881012-0.7227097,1.8281021-1.1601944,3.4570007-1.1601944
          c0.6787949,0,1.0839996,0.0439987,1.586998,0.1093979c0.4160004,0.0546951,0.7666016,0.1532974,1.0947113,0.1865005
          c0.1190872,0.0107956,0.1640015,0.0654984,0.1640015,0.1308975c0,0.0878983-0.0332108,0.218689-0.0546112,0.6016006
          c-0.0224991,0.3612976-0.0118027,0.9627991-0.0332031,1.1816025c-0.011795,0.1641006-0.0331879,0.2519989-0.1308975,0.2519989
          c-0.0878983,0-0.1093979-0.0878983-0.1093979-0.2413025c-0.0117035-0.3387985-0.1543045-0.7215958-0.4277039-1.0057983
          c-0.3612976-0.3827972-1.2138977-0.6787033-2.2206955-0.6787033c-0.9512024,0-1.5752029,0.2412033-2.0567017,0.6787033
          c-0.79879,0.732399-1.0057907,1.7723999-1.0057907,2.8330002c0,2.6044998,1.9794922,3.8955002,3.4452896,3.8955002
          c0.9746017,0,1.5644989-0.1093979,2.0019989-0.6133041c0.1865005-0.2080002,0.3281021-0.5243988,0.3730011-0.7216949
          c0.032196-0.1748047,0.0547104-0.2187042,0.1416016-0.2187042c0.0771103,0,0.0985947,0.0762024,0.0985947,0.1640015
          c0,0.1308975-0.1307983,1.0830994-0.2401962,1.455101c-0.0547028,0.1856003-0.0996017,0.240303-0.285202,0.3173981
          c-0.4375,0.1748047-1.2695007,0.2509995-1.9686966,0.2509995
          C105.9478836,90.426796,104.6900787,90.0987015,103.6949844,89.2343979z"></path>
        <path fill="#397DDA" d="M118.1900787,85.4824982c0.0332031,0,0.0654144-0.0224991,0.0654144-0.0772018v-0.2294998
          c0-1.6631012,0-1.9697113-0.0215149-2.3192978c-0.0223999-0.3721008-0.1093979-0.5468979-0.4706955-0.6240005
          c-0.0868988-0.0214996-0.2733994-0.0323029-0.4267044-0.0323029c-0.1201935,0-0.1856003-0.0223999-0.1856003-0.0985947
          c0-0.0772018,0.0762024-0.0986023,0.2295151-0.0986023c0.5907898,0,1.2802887,0.0332031,1.5751877,0.0332031
          c0.2627029,0,0.9522018-0.0332031,1.3788986-0.0332031c0.1524048,0,0.2294998,0.0214005,0.2294998,0.0986023
          c0,0.0761948-0.0653992,0.0985947-0.1962967,0.0985947c-0.0878983,0-0.1865005,0.0108032-0.3182907,0.0323029
          c-0.2949982,0.0545883-0.3829117,0.2412033-0.4043121,0.6240005c-0.0214996,0.3495865-0.0214996,0.6561966-0.0214996,2.3192978
          v1.9258041c0,1.0058975,0,1.8700943,0.0547028,2.3407974c0.032196,0.3066864,0.0975952,0.5468979,0.4256973,0.5907974
          c0.1543045,0.0224991,0.3945999,0.0439987,0.5586014,0.0439987c0.1202011,0,0.1748123,0.0332031,0.1748123,0.0878906
          c0,0.0761108-0.0869141,0.109314-0.2187119,0.109314c-0.7108994,0-1.4003983-0.0332031-1.7061005-0.0332031
          c-0.2518997,0-0.9413986,0.0332031-1.368103,0.0332031c-0.1532974,0-0.2294998-0.0332031-0.2294998-0.109314
          c0-0.0546875,0.0429001-0.0878906,0.1748047-0.0878906c0.1640015,0,0.2958984-0.0214996,0.3935013-0.0439987
          c0.2187958-0.0438995,0.2734985-0.284111,0.3173981-0.6015015c0.0547104-0.4599991,0.0547104-1.3241959,0.0547104-2.3300934
          v-0.9629059c0-0.0438995-0.0322113-0.0653992-0.0654144-0.0653992h-4.1796951
          c-0.0322037,0-0.0653992,0.010704-0.0653992,0.0653992v0.9629059c0,1.0058975,0,1.8700943,0.0545959,2.3407974
          c0.0333023,0.3066864,0.0987015,0.5468979,0.4268036,0.5907974c0.1532974,0.0224991,0.3945007,0.0439987,0.5585938,0.0439987
          c0.1191025,0,0.1748047,0.0332031,0.1748047,0.0878906c0,0.0761108-0.0878983,0.109314-0.2197037,0.109314
          c-0.7109985,0-1.4003983-0.0332031-1.7061005-0.0332031c-0.2509995,0-0.9403992,0.0332031-1.3778992,0.0332031
          c-0.1425858,0-0.2187958-0.0332031-0.2187958-0.109314c0-0.0546875,0.0429993-0.0878906,0.1738968-0.0878906
          c0.1640015,0,0.2958984-0.0214996,0.3945007-0.0439987c0.218811-0.0438995,0.2733994-0.284111,0.3173981-0.6015015
          c0.0547028-0.4599991,0.0547028-1.3241959,0.0547028-2.3300934v-1.9258041c0-1.6631012,0-1.9697113-0.0224991-2.3192978
          c-0.0214996-0.3721008-0.1093979-0.5468979-0.4707031-0.6240005c-0.0868988-0.0214996-0.2733994-0.0323029-0.4257965-0.0323029
          c-0.1211014,0-0.1855011-0.0223999-0.1855011-0.0985947c0-0.0772018,0.07621-0.0986023,0.2285004-0.0986023
          c0.5917969,0,1.2811966,0.0332031,1.5762024,0.0332031c0.2616959,0,0.9510956-0.0332031,1.3788986-0.0332031
          c0.152298,0,0.2285004,0.0214005,0.2285004,0.0986023c0,0.0761948-0.0644989,0.0985947-0.1963043,0.0985947
          c-0.0868988,0-0.1865005,0.0108032-0.3173981,0.0323029c-0.294899,0.0545883-0.3827972,0.2412033-0.4042969,0.6240005
          c-0.0223999,0.3495865-0.0223999,0.6561966-0.0223999,2.3192978v0.2294998c0,0.0547028,0.0331955,0.0772018,0.0653992,0.0772018
          H118.1900787z"></path>
        <path fill="#397DDA" d="M123.4253845,85.1757965c0-1.6631012,0-1.9697113-0.0223999-2.3192978
          c-0.021492-0.3721008-0.1309052-0.5576019-0.3711014-0.6122971c-0.1211014-0.0332031-0.2637024-0.0440063-0.3945999-0.0440063
          c-0.1092987,0-0.1747894-0.0223999-0.1747894-0.1093979c0-0.0663986,0.086998-0.0877991,0.2626877-0.0877991
          c0.4160995,0,1.1054993,0.0332031,1.4218979,0.0332031c0.2734146,0,0.919899-0.0332031,1.3349991-0.0332031
          c0.1416016,0,0.2294998,0.0214005,0.2294998,0.0877991c0,0.086998-0.0654984,0.1093979-0.1747971,0.1093979
          c-0.1093979,0-0.1962891,0.0108032-0.3282013,0.0323029c-0.2958984,0.0545883-0.3827972,0.2412033-0.4051971,0.6240005
          c-0.0214996,0.3495865-0.0214996,0.6561966-0.0214996,2.3192978v1.9258041c0,1.0604858,0,1.9247971,0.0438995,2.3954849
          c0.032196,0.2959137,0.1093979,0.4922104,0.4375,0.5361099c0.1532974,0.0224991,0.3936005,0.0439987,0.5576019,0.0439987
          c0.1210938,0,0.1757965,0.0332031,0.1757965,0.0878906c0,0.0761108-0.0878983,0.109314-0.2089996,0.109314
          c-0.7207031,0-1.4101028-0.0332031-1.7060013-0.0332031c-0.2509995,0-0.9403992,0.0332031-1.3778992,0.0332031
          c-0.142601,0-0.2188034-0.0332031-0.2188034-0.109314c0-0.0546875,0.0430069-0.0878906,0.1738052-0.0878906
          c0.1641006,0,0.2958984-0.0214996,0.3945999-0.0439987c0.2186966-0.0438995,0.284111-0.2294998,0.3172989-0.5467987
          c0.0547028-0.4599991,0.0547028-1.3243103,0.0547028-2.3847961V85.1757965z"></path>
        <path fill="#397DDA" d="M129.9302826,87.1016006c0,1.4003983,0,2.1552963,0.2294922,2.3516006
          c0.1865082,0.1639938,0.4697113,0.2411957,1.3232117,0.2411957c0.5800934,0,1.006897-0.0107956,1.2812958-0.3067017
          c0.1309052-0.1416092,0.2617035-0.4481964,0.2832031-0.6561966c0.0116882-0.0987015,0.0332031-0.1641006,0.1210938-0.1641006
          c0.0762024,0,0.0879059,0.0546875,0.0879059,0.1856003c0,0.1210861-0.0772095,0.9413986-0.1641083,1.2586975
          c-0.066391,0.240303-0.1210938,0.2949982-0.6893921,0.2949982c-1.5762024,0-2.2978973-0.0654984-3.183609-0.0654984
          c-0.2519989,0-0.941391,0.0332031-1.3788834,0.0332031c-0.1426086,0-0.218811-0.0332031-0.218811-0.109314
          c0-0.0546875,0.0429993-0.0878906,0.1748123-0.0878906c0.1640854,0,0.2958832-0.0214996,0.3935852-0.0439987
          c0.2187042-0.0438995,0.273407-0.284111,0.3182983-0.6015015c0.0547028-0.4599991,0.0547028-1.3241959,0.0547028-2.3300934
          v-1.9258041c0-1.6631012,0-1.9697113-0.0223999-2.3192978c-0.0224915-0.3721008-0.1094055-0.5468979-0.4707031-0.6240005
          c-0.086998-0.0214996-0.2187958-0.0323029-0.3603973-0.0323029c-0.1210861,0-0.1865005-0.0223999-0.1865005-0.0985947
          c0-0.0772018,0.0771027-0.0986023,0.2412033-0.0986023c0.5137024,0,1.2030945,0.0332031,1.4980011,0.0332031
          c0.2626953,0,1.0615997-0.0332031,1.4882965-0.0332031c0.1533051,0,0.2295074,0.0214005,0.2295074,0.0986023
          c0,0.0761948-0.0653992,0.0985947-0.1963043,0.0985947c-0.121109,0-0.2958984,0.0108032-0.4277039,0.0323029
          c-0.2948914,0.0545883-0.3827972,0.2412033-0.4042969,0.6240005c-0.0214996,0.3495865-0.0214996,0.6561966-0.0214996,2.3192978
          V87.1016006z"></path>
        <path fill="#397DDA" d="M135.5298767,85.1757965c0-1.6631012,0-1.9697113-0.0214996-2.3192978
          c-0.0223999-0.3721008-0.1092987-0.5468979-0.4707031-0.6240005c-0.0878906-0.0214996-0.2733917-0.0323029-0.4266968-0.0323029
          c-0.1201019,0-0.1864777-0.0223999-0.1864777-0.0985947c0-0.0772018,0.0760803-0.0986023,0.2411804-0.0986023
          c0.5791016,0,1.2684937,0.0332031,1.5751953,0.0332031c0.3496246,0,2.5273132,0,2.7353058-0.0118027
          c0.2079926-0.0214005,0.3827972-0.0429001,0.469696-0.0653992c0.0547028-0.010704,0.121109-0.0439148,0.1757965-0.0439148
          c0.0547028,0,0.0654144,0.0439148,0.0654144,0.0986099c0,0.0771027-0.0545959,0.2080002-0.0869141,0.7108994
          c-0.0116882,0.1094055-0.0331879,0.5909042-0.0546875,0.722702c-0.0117035,0.0547028-0.0332031,0.1201019-0.1092987,0.1201019
          c-0.0772095,0-0.0996094-0.0547028-0.0996094-0.1533127c0-0.0761871-0.0097961-0.2626877-0.0644989-0.3935852
          c-0.0771027-0.1972046-0.1864929-0.3281021-0.7344055-0.3935013c-0.1854858-0.0224991-1.3338928-0.0439987-1.5526886-0.0439987
          c-0.0547028,0-0.0762024,0.0322952-0.0762024,0.1093979v2.767601c0,0.0770874,0.0097961,0.1200867,0.0762024,0.1200867
          c0.2401886,0,1.4980011,0,1.75-0.0214844c0.2626953-0.0215149,0.4267883-0.0547028,0.5253906-0.1640015
          c0.0762024-0.0879059,0.121109-0.142601,0.1758118-0.142601c0.0428925,0,0.0760956,0.0223999,0.0760956,0.0878983
          c0,0.0653992-0.0545959,0.2401886-0.0877838,0.7987976c-0.0215149,0.2188034-0.0430145,0.6563034-0.0430145,0.7323914
          c0,0.0879135,0,0.2080002-0.0996094,0.2080002c-0.0761871,0-0.097702-0.0438919-0.097702-0.098587
          c-0.0116882-0.1094131-0.0116882-0.2509995-0.0438995-0.3936005c-0.0546875-0.2187042-0.2079926-0.3828125-0.6122894-0.4267044
          c-0.2089996-0.0214996-1.2910004-0.032196-1.5547028-0.032196c-0.0547028,0-0.0644989,0.0429001-0.0644989,0.1092987v0.8643036
          c0,0.3721008-0.0117035,1.3778992,0,1.6952972c0.0214996,0.7548981,0.1963043,0.9188995,1.3125,0.9188995
          c0.2842102,0,0.7442169,0,1.0283051-0.1307983c0.2841949-0.1317978,0.4151001-0.3612976,0.4921875-0.8096008
          c0.0225067-0.1201019,0.0440063-0.1640015,0.1202087-0.1640015c0.0877991,0,0.0877991,0.0868988,0.0877991,0.1961975
          c0,0.2520065-0.0877991,0.9961014-0.1425018,1.2149048c-0.0762024,0.2841949-0.1747894,0.2841949-0.5899048,0.2841949
          c-1.652298,0-2.3856964-0.0654984-3.031189-0.0654984c-0.2520142,0-0.9414062,0.0332031-1.3789062,0.0332031
          c-0.1416016,0-0.2187958-0.0332031-0.2187958-0.109314c0-0.0546875,0.0440063-0.0878906,0.1757965-0.0878906
          c0.164093,0,0.2948914-0.0214996,0.3936005-0.0439987c0.2187042-0.0438995,0.2733917-0.284111,0.3172913-0.6015015
          c0.0547028-0.4599991,0.0547028-1.3241959,0.0547028-2.3300934V85.1757965z"></path>
      </g>
      <g>
        <path fill="#397DDA" d="M66.8882828,41.596199c1.0243988-0.2021141,1.9237976-1.2153015,1.9237976-2.4301987
          c0-0.7475014-0.3036957-1.8261032-1.7411957-2.7192001c-0.2118988-0.1309013-0.3886032-0.2246017-0.5693054-0.3071022
          c1.0488052-0.3833008,1.6006012-1.1142998,1.6025009-2.1426163c0-0.6698837-0.5467987-1.2157822-1.2206955-1.2167816
          c-0.3252029,0-0.6297989,0.1259995-0.8642044,0.3578987c-0.1952972,0.1991997-0.3173981,0.4536018-0.3456879,0.7280006
          c-0.1397095,0.0546989-0.5303116,0.1553001-1.5147095,0.1553001c-0.3661995,0-0.7588005-0.0596008-1.1679001-0.1786995
          c0.1953011-0.0811005,0.9296112-0.6914024,1.1865005-0.9395027c0.2557983-0.2489967,0.9618988-0.4422989,1.2705002-0.9672146
          c0.8134995-1.3950844,1.1191025-2.125,1.419899-3.3969994c0.1523972-0.6376839,0.2276001-1.2113838,0.2276001-1.7509995
          c0-0.4800014-0.0615997-0.8676853-0.1143036-1.2030849l-0.0712967-0.5429993
          c-0.0381012-0.4375-0.1396027-1.5781002-1.1387024-1.9136009c0,0-1.7372971-2.6054001-1.7889977-2.6898994
          c-0.0536995-0.0839996-0.1425896-0.1084156-0.2070999-0.0342007c-0.0634003,0.0742016-0.7450981,0.8653011-0.7450981,0.8653011
          c-0.1601028,0.0067997-0.4658012,0.0375996-0.7343903,0.1605988c-0.0507126-0.0668983-0.1054115-0.1273994-0.1669121-0.1791992
          c-0.0508003-0.0443993-0.1074982-0.0849991-0.1748009-0.125l-0.3085976-1.9867992l-0.219799,0.0375996
          c0,0-1.0498009,0.1758003-1.0615005,0.1802006c-0.0488014-0.0025005-0.2929993-0.0523014-0.6025009-0.1953011
          c-0.0791016-0.0376167-0.2461014-0.1739006-0.3291016-0.2656994c0.0116997-0.0986004,0.0195007-0.1963005,0.0195007-0.2905006
          c0-0.9521999-0.4756012-1.7001991-1.3378983-2.1049995c-0.5596008-0.2593002-1.6366997-0.5306997-2.6826019,0.5214996
          c-0.0751991-0.0112-0.2070007-0.0375996-0.4306984-0.0986004c-0.3036995-0.110899-0.4472008-0.2530003-0.4638023-0.2773991
          l-0.6747971-1.1626005l-1.6054916,0.9609995c-0.9551086-0.7861996-1.3906097-1.0513153-1.9922104-0.8183994
          c-0.2343979,0.0894003-0.4032974,0.25-0.6288986,0.4990005c0.0586014-0.1219997,0.107399-0.2680006,0.107399-0.4062004
          c0-0.6216002-0.4794998-1.1665001-1.1445007-1.1665001c-0.077198-0.0479002-0.1562996-0.0937996-0.2421989-0.1343002
          c-0.1357002-0.3324995-0.518589-1.2529154-0.5546989-1.3393993c-0.0371017-0.0864-0.1738014-0.1098003-0.253891-0.0462999
          c-0.0791092,0.0639-1.9053116,1.5302-1.9053116,1.5302c-0.2479973-1.1552-1.0653992-2.0024166-2.0760994-2.2334166v-1.823184
          h-0.9228973v-0.0473995c0-2.4883003-1.6875-4.2944164-4.0136909-4.2944164c-0.0986099,0-0.2324104,0.0176163-0.375,0.0409999
          V4.5678997h1.7714882V1.7968999h-1.7714882V0h-2.8691101v1.7968999H33.613884v2.7709999h1.7714996v1.6581836
          c-0.2041016-0.0355835-0.3983994-0.0619998-0.5527-0.0619998c-2.3642998,0-4.0146999,1.7656164-4.0126991,4.3418159H29.733984
          v1.8369007c-1.061491,0.3085995-1.8740005,1.3285999-2.0058002,2.5956993l-0.8418007-0.7055998
          C26.8433838,14.1973,25.2818832,12.8848,25.2818832,12.8848l-0.1709003-0.1436005l-3.3085995,3.9433994
          c-0.3827991-0.2593002-0.8887005-0.3895988-1.5126991-0.3906002c-1.0596008,0-2.1543007,0.403801-2.392601,0.403801
          c-1.0732002-1.1044998-2.187499-0.8285999-2.7616997-0.5615005c-0.6278915,0.2929001-1.378891,0.9017849-1.378891,2.1386013
          c0,0.1069984,0.0106916,0.2216988,0.0253916,0.3393993c-0.0576,0.0541992-0.1709003,0.1508999-0.4091997,0.3153992
          c-0.0039005,0.0029011-0.1914005,0.1333008-0.1934004,0.1352997c-1.1777,0.8593006-1.8203001,1.700201-1.9160004,2.5350857
          H6.1138844l0.0028996,4.750515h3.5625c-0.2215996,0.1944008-1.5965996,1.2754002-1.5965996,3.4696999
          c0,2.6031017,2.1473999,3.8701992,2.3983994,4.0132008c-0.4237995,0.1264992-0.8203001,0.1879997-1.2002001,0.1879997
          c-0.9638996,0-1.3622994-0.0986023-1.5126996-0.1543007c-0.0674-0.6102982-0.5858998-1.0868988-1.2108998-1.0868988
          c-0.7031999,0-1.2158999,0.5907822-1.2158999,1.2167816c0,1.4707184,1.1943998,2.0063171,1.5781999,2.1479187
          c-0.1729002,0.0800819-2.2958999,1.0063972-2.2958999,3.0058975c0,1.2871017,0.9003997,2.25,1.9315996,2.4473
          c-4.1669998,5.9956017-6.5985994,13.2758865-6.5546994,21.1147003c0.1201,20.2445984,16.6895008,36.618187,36.9346008,36.4990005
          c20.247097-0.1201019,36.6210976-16.6893997,36.5009956-36.9340019
          C73.3912811,54.5956993,70.9732819,47.4736977,66.8882828,41.596199z M8.8316841,38.6758003
          c-0.0370998-0.054718-0.0635004-0.1147156-0.1104002-0.1626015c-0.1864996-0.1879997-0.3612995-0.2846985-0.5917912-0.2846985
          c-0.1416087,0,0.0800915-0.1069031,0.2968912-0.1933022c0.2178001-0.0863991,1.4131088-0.517601,1.4131088-0.517601
          C9.3023844,38.0751991,9.3316841,38.0859985,8.8316841,38.6758003z M13.1734924,25.1498985
          c-0.0615082,0-1.0235081,0.0220013-2.1397085,0.5713005c0-0.4911995,0-1.2539005,0-1.2915001c0.3417997,0,1.0410004,0,1.9521999,0
          c0.0819998,0.1089001,0.3642006,0.4820004,0.6328001,0.839901C13.4820843,25.1996994,13.3355837,25.1508999,13.1734924,25.1498985
          z M25.5406837,25.4375c0.0018997,0.0009995,0.0018997,0.0019989,0.0039005,0.0030003
          c-0.5741997-0.0039005-1.0928001-0.0191002-1.5440006-0.0367012c-0.0380917-0.2553005-0.0536995-0.4281998-0.1210918-0.8192997
          c0.5702915,0,0.9794922,0,1.1484928,0C25.0982838,24.8251991,25.2593842,25.1576996,25.5406837,25.4375z M24.118784,18.3285999
          c0.0282993,0.0234833,0.6035004,0.5063992,0.9804993,0.8227997c-1.1288986,0.4799995-1.9179993,1.2476006-2.0214996,1.597683
          l-0.2157993-0.9248829C23.4497833,19.1259995,23.8990841,18.5888996,24.118784,18.3285999z M15.5650845,30.0977001
          c0-0.0029011,0-0.0059013,0-0.0088005c-0.0322008-0.3936005-0.1905003-0.7681007-0.3155003-0.9580002
          c0.1075001-0.1303997,0.3702002-0.4424,0.7382994-0.8808994c0.2627001,0.3100834,0.3720999,0.4462986,0.5430002,0.6396999
          C16.243784,29.2598,15.922492,29.6655998,15.5650845,30.0977001z M16.8951836,31.6138
          c0.5545998-0.6558018,1.0185013-1.2559013,1.3886013-1.7349014c0.3437996-0.4433002,0.8564987-1.1029987,1.0370998-1.2474995
          c0.2607994-0.0986996,0.6630993,0.2563,0.7490997,0.3540001c0.2762985,0.3110008,0.7255077,0.4066849,1.1103001,0.2563
          C19.6949844,29.9379997,18.2632847,30.7314987,16.8951836,31.6138z M21.2876835,29.1918983
          c0.3194008-0.1752987,0.522501-0.5106983,0.522501-0.8724995c0-0.0478992-0.0186005-0.2328987-0.0186005-0.3023148
          c0-0.2113857,0.0391006-0.539484,0.224699-0.6415844c0.2236004-0.0454159,1.0566006-0.0132008,1.6201,0.0102997
          c0.6044998,0.0233994,1.3564014,0.0478001,2.1884003,0.0478001
          C24.2632847,27.9200001,22.7495842,28.5116997,21.2876835,29.1918983z M28.3775845,18.8985004
          c-0.3700924-0.1153011-0.7422009-0.1924171-1.0928001-0.1924171c-0.5302925,0-0.9980011,0.0801163-1.4248009,0.1909161
          L28.0044918,16.3062c0,0,0.3583927,0.9813995,1.218792,1.5200005
          C28.8980827,18.2675991,28.5943832,18.6450005,28.3775845,18.8985004z M34.5542831,16.9814987
          c0.7080002,0.4970016,1.45121,0.8032017,2.1748009,0.8032017c0.7178001,0,1.4532013-0.2998009,2.1543007-0.7881012
          c0.1523972,0.2632008,0.2949982,0.5307999,0.4062996,0.8076c-0.9434013,0.3486996-1.8994026,0.7910004-2.5761909,1.4682999
          c-0.675808-0.6772995-1.6289101-1.1205997-2.5722084-1.4696999C34.2544937,17.5214996,34.3990822,17.25,34.5542831,16.9814987z
          M30.3921833,14.3373995c0.1513996,0,0.277401,0.3653002,0.277401,0.4892998c0,0.2441006-0.0771999,0.6538-0.3848,1.2895002
          c-0.3047009-0.3105001-0.3584003-0.5927-0.3584003-0.8197994C29.926384,14.8051996,30.2056828,14.3373995,30.3921833,14.3373995z
          M30.1285839,19.9091988c0.2616997-0.3153992,0.5585003-0.6967983,0.8584003-1.1185989
          c0.2616997,0.1044006,0.5116997,0.2020988,0.7616997,0.2904835c-0.085001,0.5298157-0.1415997,1.0679169-0.1415997,1.6147156
          c0,0.2046013,0.0135994,0.4120998,0.0419998,0.6172009c-0.2187996,0.0610828-0.2578011,0.0713005-0.4815006,0.1322994
          C30.7222843,20.3575001,30.1568832,19.9365997,30.1285839,19.9091988z M31.1470833,24.8437996
          c0.1729012-0.4384995,0.2705002-0.9130993,0.3008003-1.410717c0.25-0.0668831,0.5634995-0.1522827,0.8339996-0.2264824
          c0.1991997,0.3597984,0.4433022,0.7093983,0.7304001,1.0482998
          C32.5318832,24.4491997,31.9751835,24.6425991,31.1470833,24.8437996z M32.2632828,17.1493988
          c-0.0770988-0.0287991-0.152298-0.0570984-0.2313995-0.0877991c0.3633003-0.7383003,0.5663986-1.4063005,0.6113014-1.9990997
          c0.1366997,0.1840992,0.2793083,0.3622999,0.4267998,0.5351992C32.7515831,16.094799,32.4751854,16.6084003,32.2632828,17.1493988
          z M33.8501854,20.7001991c-0.065403,0.0181007-0.1425018,0.0405998-0.2422028,0.0688839
          c-0.0018997-0.0243835-0.0077972-0.0497837-0.0077972-0.0732841c0-0.3232002,0.0214996-0.6371994,0.0566978-0.9467163
          c0.5770988,0.1894169,1.1631012,0.4272175,1.1631012,0.4272175S34.2095833,20.1093998,33.8501854,20.7001991z M34.7691841,23.1924
          c-0.1748009-0.1811008-0.3311005-0.3633175-0.4678001-0.5513172c1.2793007-0.3363838,1.4179993-1.2080002,1.4179993-1.2080002
          S35.8101845,22.2773991,34.7691841,23.1924z M36.7134933,24.6728992
          c-0.1055107-0.0580997-0.2324104-0.1313992-0.3740005-0.2178154c0.1337891-0.1371841,0.261692-0.2792835,0.3740005-0.4257851
          c0.1181908,0.1455002,0.2421913,0.2866001,0.3778915,0.4239006C36.9468842,24.5405998,36.818985,24.6147995,36.7134933,24.6728992
          z M38.6558838,23.1924c-1.0438995-0.9150009-0.9520988-1.7593174-0.9520988-1.7593174s0.1405983,0.8716164,1.419899,1.2080002
          C38.9869843,22.8290825,38.8306847,23.0112991,38.6558838,23.1924z M39.8150826,20.769083
          c-0.0976982-0.0282841-0.1748009-0.0507832-0.2402992-0.0688839c-0.3622894-0.5907993-0.972599-0.5238991-0.972599-0.5238991
          s0.5858994-0.2378006,1.1660004-0.4272175c0.0321999,0.3095169,0.0546989,0.6235161,0.0546989,0.9467163
          C39.8228836,20.7192993,39.8169823,20.7446995,39.8150826,20.769083z M40.791584,15.0962
          c0.0508003,0.5844994,0.2519989,1.2417002,0.6093979,1.9653997c-0.0809975,0.0317001-0.1562004,0.0599995-0.2383003,0.0897999
          c-0.2069969-0.5342007-0.4774971-1.0405006-0.7899971-1.5313158C40.5171852,15.4511995,40.6578827,15.2774,40.791584,15.0962z
          M40.4185829,24.2549c0.2871017-0.3389015,0.5312004-0.6885014,0.7304993-1.0482998
          c0.2705002,0.0741997,0.5839005,0.1595993,0.8339005,0.2264824c0.0303001,0.4976177,0.1280022,0.9722176,0.3008003,1.410717
          C41.4556847,24.6425991,40.8990822,24.4491997,40.4185829,24.2549z M42.2632828,21.4452991
          c-0.2235985-0.0609989-0.2626991-0.0712166-0.482399-0.1322994c0.0293007-0.205101,0.0429993-0.4125996,0.0429993-0.6172009
          c0-0.5467987-0.0566978-1.0848999-0.142601-1.6147156c0.2509995-0.0883846,0.5009995-0.1860828,0.7627029-0.2904835
          c0.2987976,0.4218006,0.597599,0.8031998,0.8593979,1.1185989C43.2749825,19.9365997,42.7066841,20.3575001,42.2632828,21.4452991
          z M43.1470833,16.1161995c-0.3085899-0.6357002-0.3857002-1.0453997-0.3857002-1.2895002
          c0-0.1239996,0.1259003-0.4892998,0.2783012-0.4892998c0.1865005,0,0.4648094,0.4678001,0.4648094,0.9590006
          C43.5044937,15.5234995,43.4517822,15.8056993,43.1470833,16.1161995z M48.4751816,21.4921989
          c0.6553116-0.5126991,1.0948029-1.0107002,1.3858032-1.3402996l0.1093979-0.038599l0.1962013-0.0376015l0.0136986,0.0771999
          c0.0243988,0.1341839,0.0488014,0.2714005,0.0653992,0.3803005l0.0069008,0.0450001l-0.0341988,0.0272999
          c-0.6044998,0.4902-1.2686005,1.1019993-1.5009995,2.1728001l-0.0508003,0.2339001l-0.0965996-0.2182007l-0.0323029-0.0683994
          c-0.009697-0.0536995-0.0194969-0.3759995-0.0194969-0.3759995c0.0019989-0.0487995,0.0019989-0.0937004,0.0019989-0.137701
          c0-0.2132988-0.0245018-0.4286995-0.0732994-0.6406002l-0.0116997-0.0487995L48.4751816,21.4921989z M44.6314926,24.8251991
          c-0.5918083-0.5629997-0.6709099-1.3715992-0.6709099-1.6928005c0-0.0053997,0-0.0093155,0-0.0131989
          c0-0.25,0.0400009-0.4829006,0.0996017-0.7056007c0.1688995,0.1138,0.3661995,0.1786995,0.5811005,0.1899014
          c0.0653992,0.0664997,0.2382088,0.2368984,0.2440987,0.2412987c-0.1464996,0.3192844-0.4716988,0.7236004-0.4716988,0.7236004
          l-0.1221008,0.5556831C44.291584,24.1240826,44.5445824,24.5303001,44.6314926,24.8251991z M45.0581818,18.8993988
          c-0.2167969-0.2533989-0.5243988-0.6297989-0.847599-1.0731983c1.0674019-0.8447018,1.2666016-1.6514015,1.2666016-1.6514015
          s0.3895988,0.4244003,0.9598999,0.5258999c0.1651001,0.3862,0.4482994,0.7222004,0.7216988,0.9790001
          c0.0038986,0.0146999,0.0038986,0.0156002,0.0078011,0.0298004c-0.107399,0.1513996-0.6922989,0.6884995-1.0810013,1.0044003
          C45.6890831,18.7250843,45.3023834,18.8237991,45.0581818,18.8993988z M46.5454941,25.178299
          c-0.0312119-0.3204002-0.0673103-0.6162987-0.1162109-0.8959999c0.0186005-0.0170994,0.0224991-0.1308994,0.0205002-0.2950001
          c0.1865997-0.2285004,0.4043007-0.4951,0.4043007-0.4951l0.0224991,0.2910004
          c0.0469093,0.0557003,0.2490005,0.3017998,0.2490005,0.3017998s0.1064987,0.0205002,0.1913986,0.0375996
          c0.0284004,0.0112,0.1280022,0.0532017,0.1953011,0.1050014l0.7159004,0.645483c0,0,0.4150009,0.072216,0.5956993,0.0986156
          c0.0018997,0.0020008,0.0126991,0.0132008,0.0283012,0.0298004c0.0634995,0.1459999,0.1630974,0.2763996,0.2440987,0.4144993
          c-0.6805992,0.022501-1.4883003,0.0367012-2.3515015,0.011301C46.7447815,25.4272995,46.6206818,25.2705002,46.5454941,25.178299z
          M47.613884,27.4335995c0.8280983,0,1.5761986-0.0244007,2.1777-0.0478001
          c0.4639091-0.0181007,1.0889091-0.0415001,1.4277992-0.0254002c0.1347008,0.0946846,0.2588005,0.1889,0.3701096,0.2806835
          c0.0370903,0.1241169,0.0302887,0.3760014,0.031189,0.6812172c0.0020027,0.3051987,0.1905022,0.6708984,0.4961014,0.8500996
          C50.6646843,28.4980831,49.1617813,27.9155998,47.613884,27.4335995z M52.291584,29.2509995
          c0.3759995,0.1299,0.8409081-0.0785999,1.0694008-0.2665997c0.2304001-0.1893997,0.3319969-0.2744007,0.4393997-0.3213158
          c0.1445999,0.0313148,0.2968979,0.067915,0.4491997,0.1099148c0.2324982,0.2510014,0.6162987,0.743084,0.8955994,1.1058998
          c0.3817978,0.4921856,0.847599,1.0883999,1.3885994,1.7290001C55.1753845,30.7333984,53.7622833,29.9424,52.291584,29.2509995z
          M58.4986839,30.8349991c-0.4921913-0.5580997-0.9336014-1.0981998-1.3164024-1.5855007
          c0.2256012,0.0450001,0.4277,0.0450001,0.6201019,0.0215015c0.0517006,0.0893993,0.0713005,0.125,0.1083984,0.1830826
          c0,0.2481174,0.01371,0.5957165,0.2744026,0.8438168l0.5527,0.2064991
          C58.7525826,30.5043983,58.6206818,30.673399,58.4986839,30.8349991z M64.6480865,38.6811981
          c-0.5010071-0.5878983-0.6943054-0.7963982-1.2285042-1.3516006l1.5625038,0.6753006
          c0.2929001,0.1128006,0.398407,0.2315025,0.3632965,0.2349014C64.8560791,38.2861977,64.6802826,38.6343002,64.6480865,38.6811981
          z"></path>
        <g>
          <path fill="#FDD800" d="M36.931282,98.3261948C17.1734924,98.4423981,1.0035844,82.4644012,0.8872844,62.7080002
            c-0.048792-7.9111023,2.4913001-15.2436028,6.8154998-21.2031021c0.2646003-0.0995979,0.5127082-0.2450981,0.7177081-0.4365005
            c0.276392-0.2558975,0.5840006-0.7025986,0.6796923-1.3705978c6.5323076-7.898901,16.3788986-12.9678001,27.4053078-13.0337162
            C47.681282,26.5977001,57.7076836,31.6840839,64.3316803,39.6875c0.180603,1.3364983,1.1347046,1.7187996,1.3993988,1.8173981
            c4.2432022,5.847702,6.7734146,13.0186005,6.8184128,20.7783012
            C72.6675797,82.040596,56.6861839,98.2084961,36.931282,98.3261948z"></path>
          <g>
            <path fill="#FDD800" d="M22.5806847,19.0243988c0.0224991,0.0191002,0.0272999,0.0352001,0.0429993,0.0538006
              l1.660099-1.9785995L24.9097843,17.625l-0.2480011,0.2939987l0.6748009,0.5654011l0.9638004-1.1478996l-0.6728001-0.5664997
              l-0.2460918,0.2949982l-0.6259995-0.5269146l0.7274914-0.8695841l0.6289997,0.5258989l-0.2481003,0.2949009l0.674799,0.5653992
              l0.9639015-1.1503992l-0.6748009-0.5664005l-0.2481003,0.2948999l-0.6268997-0.5252991L26.2007847,14.8125l-0.9795017-0.8237
              l-3.1708984,3.7787991C22.0142841,18.4272995,22.1841831,18.6856003,22.5806847,19.0243988z"></path>
            <path fill="#FDD800" d="M8.5035839,24.5844994H8.1177845v-0.8188h1.1367083v0.8188H8.8697844v0.8803997l1.4989996-0.0009995
              v-0.8794003H9.9869843v-0.8188h2.6639996c-0.0067997-0.5366993-0.0936995-0.8316154-0.7529001-1.2803001H7.0025845v1.2803001
              h0.3827996l0.0019002,0.8188H7.0025845v0.8794003h1.4989996L8.5035839,24.5844994z"></path>
            <path fill="#FDD800" d="M25.5269833,23.987299c0,0.7061005,0.5615082,1.466301,1.4678001,1.466301
              c0.9472008,0,1.2919998-0.7835999,1.3612995-0.9721012c0.1855011-0.0678997,0.9560013-0.4053001,0.9560013-1.3574162
              c0-0.951683-0.7705002-1.288084-0.9570007-1.355484c-0.0692997-0.1879997-0.4160004-0.9716988-1.3603001-0.9716988
              c-0.8085995,0-1.4678001,0.6576996-1.4678001,1.4657993c0,0.0459003,0.0059013,0.0918007,0.0156002,0.1445999
              l-2.3505993-0.0009995l-0.0174923,0.0517006c-0.175808,0.4819984-0.0713081,0.8696995,0.3377914,1.2558994
              c0.0372086,0.0347004,0.0566998,0.0629997,0.0723,0.0918007l0.0234013,0.0375996h1.9345989
              C25.5328846,23.8906994,25.5269833,23.9384995,25.5269833,23.987299z M26.6050835,22.4701996
              c0-0.3261166,0.1494083-0.5438995,0.4307003-0.5438995c0.4638004,0,0.3877087,0.487299,0.5478001,0.6469994
              c0.1602001,0.1592007,0.6406994,0.1084003,0.6406994,0.5517006c0,0.4496994-0.474699,0.3857994-0.6406994,0.5513
              c-0.1660004,0.1669998-0.1131992,0.6478996-0.5478001,0.6478996c-0.2842007,0-0.4307003-0.2290001-0.4307003-0.5438995
              V22.4701996z"></path>
            <path fill="#FDD800" d="M15.3521843,26.7743988c-0.2813005-0.3109989-0.6776915-0.3626995-0.6776915-0.3626995
              l-1.4307089,1.7308998c-0.0332003-0.0370998-0.0654001-0.0723-0.1006002-0.1030006
              c-0.2636909-0.2227001-0.5995998-0.3446999-0.9442997-0.3446999c-0.0401001,0-0.0820999,0.0019016-0.1240997,0.0053005
              c-0.3916006,0.0342007-0.7460003,0.2182999-0.9980001,0.5195999c-0.2519999,0.2998009-0.3720999,0.6811008-0.3379002,1.0727005
              c0.0166082,0.1938992,0.1620998,0.4981003,0.21,0.5937996c-0.026392,0.0883007-0.0977001,0.3437004-0.0977001,0.5267849
              c0,0.7705154,0.6698999,1.4224148,1.4639006,1.4224148c0.2958994,0,0.6152-0.1567993,0.7118998-0.2084999
              c0.1006002,0.0263004,0.4198999,0.1058998,0.6122999,0.1058998c0.6610994,0,1.3428001-0.5926991,1.3428001-1.5853996
              c0-0.3589001-0.1885004-0.7227001-0.5195923-1.0004997c-0.0341082-0.0293007-0.0742083-0.0562-0.1181078-0.081501
              l1.2743998-1.5444984C15.618784,27.5209999,15.5845842,27.0297985,15.3521843,26.7743988z M13.7857838,30.4780827
              c-0.2948999,0.3569164-0.6230993-0.0122833-0.8477001,0.0092163c-0.2245913,0.0214996-0.4921999,0.4247837-0.8339996,0.1420994
              c-0.3465919-0.2865982,0.0059004-0.6107998-0.016592-0.8446999c-0.0224075-0.2348995-0.4267082-0.4994984-0.1504078-0.8349991
              c0.1816998-0.2196999,0.4521999-0.1870003,0.6944084,0.0142002l1.0097914,0.8339996
              C13.8921843,30.0063992,13.9644928,30.2602997,13.7857838,30.4780827z"></path>
            <path fill="#FDD800" d="M20.3335838,17.1723995c-1.079092,0-1.9520912,0.4141006-2.5195007,0.4141006
              c-0.1806984,0-0.3417988-0.0459003-0.4413986-0.1626015C17.0152836,17.0098,16.4292831,16.5098,15.5103836,16.9375
              c-0.9277,0.4335842-0.9198914,1.2021999-0.8319998,1.7441998c0.0586004,0.3793831-0.4658918,0.7430992-0.8134995,0.9833832
              l-0.1729002,0.1219997c-1.2772999,0.9312172-1.8408003,1.7709999-1.4667997,2.3863163
              c1.3710995,2.2636013,5.1513987,6.6665001,5.1620989,6.6758003c0,0,1.3155003-1.2856998,2.7637005-1.9605007
              c1.6611004-0.7758999,3.2705078-0.8544998,3.2705078-0.8544998c-0.0029087-0.0116997-0.925808-5.7294998-1.7978077-8.2421989
              C21.4751835,17.3700829,21.0025845,17.1723995,20.3335838,17.1723995z M13.4722843,21.7504997
              c0.0772085-0.1304169,0.3095999-0.4360008,0.9843998-0.9306011l0.1484003-0.1035995
              c0.4452991-0.3060989,1.3730993-0.9447994,1.3730993-1.9687004c0-0.0893993-0.0087996-0.1796989-0.0225-0.2743988
              c-0.0145998-0.0894012-0.0205002-0.1616001-0.0205002-0.2198009c0-0.097599,0-0.097599,0.1220999-0.1556988
              c0.0488014-0.0220013,0.0976009-0.0444012,0.1201-0.0444012c0.0116997,0,0.0713005,0.0151005,0.2392006,0.2075005
              c0.3437996,0.3983994,0.8223,0.5995998,1.4200001,0.5995998c0.4619083,0,0.8983994-0.1210995,1.1611004-0.1928997
              l0.1768074-0.0468998c0.4618912-0.1137009,0.8417912-0.1718006,1.1288929-0.1718006
              c0.0995998,0,0.1688995,0.0072842,0.2176991,0.0151005c0.0468998,0.1474991,0.1201992,0.4120998,0.2354088,0.8745003
              c-0.8311081,0.1679993-1.6631088,0.4106827-2.478508,0.7250996c-0.2256012-0.2357998-0.5293007-0.3682003-0.8544922-0.3682003
              c-0.1729088,0-0.3418083,0.0375996-0.5,0.1119003c-0.4169998,0.1952991-0.6875076,0.6170998-0.6875076,1.0765991
              c0,0.0410004,0.0057983,0.0796013,0.0145988,0.115284c-0.8036995,0.4384174-1.5625,0.9404163-2.2577991,1.4956169
              C13.712492,22.1084003,13.5552845,21.8818989,13.4722843,21.7504997z M14.9566841,23.8066998
              c0.8603001-0.6968002,1.8671989-1.3443012,2.910099-1.830616c1.0459003-0.486784,2.1963005-0.8397846,3.2764015-1.049284
              c0,0,0.2157993,0.9722004,0.2480984,1.1063995c-0.6503983,0.1259995-1.2988987,0.3062-1.9355984,0.5366993
              c-0.2256012-0.2460995-0.5449009-0.3901997-0.8789005-0.3901997c-0.1728992,0-0.3379002,0.0366001-0.4941006,0.1093845
              c-0.4189987,0.1953163-0.6894989,0.620615-0.6894989,1.0830002c0,0.0508156,0.0077991,0.0977154,0.0185986,0.1406155
              c-0.6328983,0.3570004-1.224699,0.7583008-1.7656994,1.1977997L14.9566841,23.8066998z M21.926384,24.7276001
              c-0.4375,0.0868988-0.8701,0.2035999-1.2910004,0.3500996c-0.2266006-0.2621994-0.5536995-0.4160004-0.9013996-0.4160004
              c-0.1709003,0-0.3379002,0.0366001-0.4941006,0.1098003c-0.2910004,0.1352997-0.5087986,0.3740005-0.6161995,0.673399
              c-0.0468998,0.125-0.0713005,0.264101-0.0713005,0.4096012c0,0.0611,0.0097008,0.1182003,0.0223999,0.1709003
              c-0.4228001,0.2489986-0.8281002,0.5273991-1.2059994,0.8324986l-0.7129002-0.8745003
              c0.6914005-0.5653992,1.5166092-1.1019993,2.3789005-1.5038986c0.8603992-0.4013004,1.8008003-0.6894169,2.674799-0.8558998
              L21.926384,24.7276001z"></path>
            <path fill="#FDD800" d="M66.6861801,37.0683975c-0.3330002-0.2074966-0.5566864-0.3003159-0.8945999-0.4394989
              c-0.0038986-0.0028992-0.0057983-0.0028992-1.4090881-0.6211014c-0.026413-0.0121994-0.3662109-0.1875-0.5879097-0.3011971
              l0.3680992,0.0204849c0.9599991,0,3.2070999,0,3.2089996-1.7300034c0-0.2689972-0.219696-0.4877815-0.4882965-0.4877815
              c-0.1297989,0-0.2518921,0.0507851-0.3457031,0.1436005c-0.0907974,0.0921822-0.1445007,0.2142982-0.1445007,0.3431816
              c0,0.3511162-0.2538986,0.7549171-2.2343979,0.7549171c-1.2285004,0-2.3838005-0.5331993-3.172802-1.0098
              c0.7666016-0.0672989,1.5420036-0.4007988,2.1982002-0.946701c0.8399048-0.7006989,1.3359985-1.6127987,1.4297028-2.6396999
              c0.0106964-0.1210995,0.0126953-0.2421989,0.0126953-0.3641987c0-0.9019012-0.2987976-1.7763996-0.8036957-2.339901
              c-0.1709023-0.1889992-0.3437996-0.2856159-0.5147018-0.2866154c-0.1170998,0-0.2254982,0.0469151-0.3232002,0.1465149
              c-0.0692978,0.0742016-0.1054878,0.1821003-0.1054878,0.3008003c0,0.1630001,0.0693893,0.3295002,0.1972885,0.4696846
              c0.2645988,0.2939167,0.5751991,0.9223156,0.5751991,1.7236156c0,0.0863838-0.0038986,0.1748009-0.0116997,0.2637005
              c-0.0694008,0.7616844-0.4413986,1.4438-1.0820999,1.9755993c-0.2176895,0.1820831-1.0458984,0.7015991-1.9668007,0.7015991
              c-0.0711975,0-0.1444969-0.0028992-0.2069969-0.008297c0,0-0.1063995-0.0078011-0.1240005-0.0122185l-0.2344017-0.044384
              c0,0,0.1309013,0.5663834-0.0555992,0.8115005c-0.1836014,0.2397995-0.8320999,0.0131989-0.8320999,0.0131989
              l-0.0917892,0.0741997l0.3691902,0.2886009c0.9315987,0.8358841,1.8368988,1.5244026,2.794899,2.1191025L62.2466812,36.0098
              h0.0039024c0.2364006,0.1210976,1.6963005,0.8745003,1.705101,0.8783989l1.4540977,0.6386986
              c0.3368988,0.1391029,0.4912033,0.2036018,0.7598038,0.3711014c0.6366959,0.392601,0.9315948,0.7890015,0.9315948,1.2495003
              c0,0.4921989-0.3701019,0.7943993-0.6347961,0.7943993c-0.1748047,0-0.4716034-0.1352005-0.4716034-0.6405983
              c0-0.2686005-0.2167969-0.4858017-0.4853973-0.4858017c-0.2753983,0-0.4902039,0.2132988-0.4902039,0.4858017
              c0,0.9496994,0.6094055,1.6137848,1.4804001,1.6137848c0.9434052,0,1.5782013-0.9140854,1.5782013-1.7685852
              C68.0777817,38.3404007,67.6099854,37.6406975,66.6861801,37.0683975z"></path>
            <path fill="#FDD800" d="M19.5796833,27.9580002c0.5342007,0.0859985,0.9619007,0.5576992,0.9864006,0.5829983
              c0.097599,0.1109009,0.2558002,0.1445999,0.3906002,0.0820847c0.1318073-0.0614834,0.2089996-0.2060833,0.1865005-0.3495846
              c0-0.0077991-0.0918007-0.6728992,0.2030983-1.1479988C20.8033848,27.2568989,20.0210838,27.6299,19.5796833,27.9580002z"></path>
            <path fill="#FDD800" d="M16.9566841,29.1918983c-0.2481003,0.3271847-1.2207003,1.5303001-1.9551001,2.3277016
              l-0.1260004,0.1034832c-0.6385994,0.7617168-1.3544998,1.1469173-2.125,1.1469173
              c-0.9237995,0-1.6659994-0.5550995-1.869091-0.7260017c-0.6387081-0.5307999-1.0127087-1.2139149-1.0819998-1.9755993
              c-0.0791082-0.8754997,0.253891-1.6182003,0.5633917-1.9883156c1.2021999-1.4286842,2.7900991-1.4447842,2.8066998-1.4447842
              c0.2684994-0.0020008,0.4872999-0.2207012,0.4872999-0.4888c0-0.267601-0.2167997-0.4873009-0.4853916-0.4883003
              c-0.0859089,0-2.0664082,0.0205002-3.5556087,1.7940006c-0.5732994,0.6821003-0.8759995,1.7181988-0.7890997,2.7035999
              c0.0917997,1.0258999,0.5859003,1.9379997,1.4277086,2.6386986c0.6572914,0.5468025,1.4316912,0.8794022,2.1992912,0.946701
              c-0.7872,0.4766006-1.9424,1.0098-3.1729002,1.0098c-0.4228992,0-1.7040997,0-2.1073914-0.403801
              c-0.0889082-0.0902977-0.1299081-0.1982002-0.1299081-0.3501167c0-0.2689972-0.2178001-0.4877815-0.4863-0.4877815
              c-0.3027921,0-0.4893003,0.2533989-0.4893003,0.4877815c0,1.179718,1.0205083,1.7300034,3.2188001,1.7300034l0.343708-0.0180855
              l-0.5859079,0.3027L7.6538844,36.623085c-0.3418002,0.1401138-0.5673003,0.2324142-0.9091001,0.4453125
              c-0.9229002,0.5732002-1.3926001,1.2666016-1.3926001,2.0591011c0,1.0219002,0.7929997,1.7855835,1.5018997,1.7855835
              c0.7754083,0,1.5586004-0.4984818,1.5586004-1.6117821c0-0.2686005-0.2196999-0.4858017-0.4881921-0.4858017
              c-0.1329083,0-0.255908,0.0507011-0.3418083,0.1401024c-0.0937996,0.0902977-0.1464996,0.213398-0.1464996,0.3456993
              c0,0.2377815-0.0694003,0.6376991-0.5303001,0.6376991c-0.2402,0-0.5497999-0.3227997-0.5762-0.721199
              c-0.0282998-0.4902,0.2666001-0.9092026,0.9316998-1.3208008c0.2646003-0.1636009,0.415-0.2270012,0.7645998-0.3711014
              l1.4375086-0.6322975l-0.0019083-0.0054016c0.1064005-0.0477982,0.4540997-0.2265015,1.7880001-0.9160004
              c1.0508003-0.6557999,2.0859995-1.4561157,3.0381002-2.3521156c0.0225-0.0121841,0.0225-0.0121841,0.2031994-0.1839981
              c1.4658003-1.392086,2.4170008-2.6357861,3.1796083-3.5966854
              C18.0064926,29.4165001,17.2046833,28.8632984,16.9566841,29.1918983z"></path>
            <path fill="#FDD800" d="M49.6773834,25.9286995c-0.5546989-0.0039005-2.5937996,0.0235004-2.5937996,0.0235004v0.158699
              l0.0019989,0.0311852l0.0078011,0.0264149c0.0303001,0.0805836,0.0468979,0.1669998,0.0468979,0.2544003
              c0,0.0928001-0.0224991,0.1924-0.0654984,0.2900009l-0.0946999,0.2153988l2.8955002-0.0620995
              C50.4048843,26.8569984,50.2300835,25.9335995,49.6773834,25.9286995z"></path>
            <path fill="#FDD800" d="M58.3208847,31.4053001l-0.225502-0.2593002
              c-0.6767998-0.7695007-1.2949982-1.5586014-1.8173981-2.2339172c-0.1690025-0.2167835-1.2080002,0.0347157-0.7734909,0.5967159
              c0.4472885,0.580101,1.0292892,1.325201,1.6718903,2.0674l0.1221085,0.075201l0.1122894-0.0629997
              c0.0703011-0.0405006,0.3232002-0.1738014,0.5674019-0.1830997H58.3208847z"></path>
            <path fill="#FDD800" d="M45.3218842,26.2875996c0,0-0.2490005-0.2094002-0.3262024-0.2826996l-0.0545998-0.3794003
              l-0.0732994-0.0356007c-1.375-0.6830997-1.4160004-2.2763996-1.4160004-2.4566002
              c-0.0019989-0.4736004,0.1016006-0.9292164,0.3057098-1.352499l0.0282898-0.0566006l-0.0205002-0.0601158
              c-0.0165977-0.0487843-0.0341988-0.1220837-0.0341988-0.1474838c0-0.4365158,0.2988014-0.8042011,0.6523018-0.8042011h0.0995979
              c0,0,0.1094017-0.5043983,0.830101-1.0463982l0.5536995-0.467701c0,0-0.9140015,0.2572994-0.9746017,0.280201
              c-0.2020988-0.2216015-0.7958984-0.8964176-1.3934975-1.7680016c1.0184975-0.6376991,1.4930992-1.4053001,1.4930992-2.4140987
              c0-1.3247004-0.8955002-2.4433165-1.9520988-2.4433165c-1.0098,0-1.7626991,1.0424156-1.7626991,1.9736156
              c0,0.9673004,0.5917969,2.0936995,0.8251991,2.5033846c-0.2578011,0.1079159-0.7803001,0.3203163-1.2383003,0.4697151
              c-0.1113014-0.3373985-0.4267998-1.1791992-1.0088005-2.0375996l-0.107399-0.1587l-0.1318016,0.1373005
              C39.4566841,15.9024,39.2984924,16.0562,39.1353836,16.1973l-0.0996017,0.0888996l0.0713005,0.1128006
              c0.3476028,0.5531998,0.672802,1.2201996,0.8379021,1.7132988c-1.2530022,0.450201-2.5419998,0.9169998-3.2314911,1.9327011
              c-0.6572113-0.9619999-1.9873085-1.506916-3.231411-1.9371014c0.0869026-0.2670994,0.3291016-0.9046993,0.8496017-1.7294998
              l0.0692978-0.1126995L34.301384,16.178299c-0.160099-0.1425991-0.3193016-0.2958994-0.4756012-0.458499l-0.1347008-0.1387167
              l-0.1074982,0.1597166c-0.5576019,0.8247004-0.8730011,1.629283-1.0166016,2.0570993
              c-0.4256973-0.1436005-0.8378983-0.2998161-1.2391987-0.4668159c0.2362995-0.4067841,0.8242016-1.519084,0.8242016-2.504384
              c0-0.9312-0.7529011-1.9736156-1.7598019-1.9736156c-1.0576,0-1.9520988,1.1186161-1.9520988,2.4433165
              c0,1.4032993,1.0721989,2.1742992,1.4862995,2.4203987c-0.2168007,0.3130016-0.772501,1.0908012-1.3808918,1.7588005
              c-0.2089081-0.0874004-0.7011089-0.2621994-1.2607079-0.2621994c-2.3446999,0-3.6074009,1.5077991-3.6582012,1.5697994
              c-0.0830002,0.0995998-0.1239986,0.2261009-0.112299,0.3560009c0.0126991,0.1303997,0.0732079,0.2470989,0.1709003,0.3295994
              c0.0879002,0.0741997,0.1991997,0.1147003,0.3153992,0.1147003c0.1445999,0,0.2831993-0.0629997,0.3740005-0.1748009
              c0.0107994-0.0121994,1.0254002-1.2181988,2.9102001-1.2181988c0.4804993,0,1.2637081,0.2215824,1.8857079,0.846199
              c0.5371914,0.5389996,0.8193913,1.264101,0.8193913,2.0976009c0,1.3607826-0.7987995,2.8139992-3.0440006,2.8139992
              l-0.2303905,0.0009003c-0.7950001,0-2.3770008,0.0068836-2.6992092-0.0009003
              c-0.3232994-0.0083008-0.2685986,0.9330997-0.0224991,0.9403992c0.6835995,0.019001,1.6016006,0.0356007,2.3682003,0.0356007
              c4.6933002,0,7.7342987-1.5214005,8.2870979-1.8202991c0.4004097,0.2875996,1.6268997,1.144083,2.0605011,1.144083
              c0.4062996,0,1.6493988-0.8500843,2.0634995-1.1410847c0.4414101,0.2724018,2.4346008,1.3657017,6.4932022,1.706501
              l0.4364967,0.0365849l-0.198288-0.1870003L45.3218842,26.2875996z M43.0396843,13.8290834
              c0.5,0,0.9755974,0.7129164,0.9755974,1.4673166c0,0.8764992-0.7343979,1.4174995-1.0341988,1.6005993
              c-0.1991997-0.3407993-0.7255898-1.3076-0.7255898-2.0703001
              C42.2554932,14.4745998,42.5523834,13.8290834,43.0396843,13.8290834z M42.6255836,18.1689987
              c0.5069084,0.7793007,1.1299095,1.5029011,1.3876991,1.7949009c-0.3397903,0.2471008-1.1278992,0.9301834-1.4188995,2.1005993
              c0,0-0.8945999-0.2441998-1.3866997-0.3788986c0.044899-0.1905174,0.1102982-0.5401001,0.1102982-0.9898014
              c0-0.9637985-0.1308975-1.6654987-0.1952972-1.9540997C41.3931847,18.6490002,42.0210838,18.4291992,42.6255836,18.1689987z
              M29.416584,15.2964001c0-0.7544003,0.4736996-1.4673166,0.9755993-1.4673166
              c0.4864006,0,0.7832012,0.6455164,0.7832012,0.9976158c0,0.7637005-0.5263004,1.7295008-0.7256012,2.0703001
              C30.1509838,16.7138996,29.416584,16.1728992,29.416584,15.2964001z M29.4185829,19.9638996
              c0.2578011-0.2919998,0.8789101-1.0156002,1.3867016-1.7949009c0.6044998,0.2602005,1.2324009,0.4800014,1.5028973,0.5727005
              c-0.0653992,0.2886009-0.1952972,0.9903011-0.1952972,1.9540997c0,0.4497013,0.0634995,0.799284,0.1103973,0.9898014
              c-0.4921989,0.1346989-1.1083984,0.3036995-1.3876991,0.3788986
              C30.5454922,20.894083,29.7583828,20.2110004,29.4185829,19.9638996z M30.1704922,25.5830002
              c0.3300915-0.4404011,0.8076916-1.2998009,0.7890911-2.5444012c0.880909-0.2339001,1.3408012-0.355999,1.5811005-0.4370003
              c0.4120979,0.8818016,1.0205002,1.5386009,1.3115005,1.8232002
              C33.3843842,24.6567993,32.0767822,25.237299,30.1704922,25.5830002z M34.7300835,23.8920841
              c-0.5410004-0.4911842-0.9511986-1.010685-1.2255898-1.5487843l0.9638901-0.278801
              c0.5849991-0.2124157,0.9150009-0.6982994,0.7929993-1.1602001c-0.0586014-0.2152996-0.253891-0.363699-0.4716988-0.363699
              c-0.0429993,0-0.0858994,0.0058002-0.1259995,0.0170002c-0.2139015,0.0581989-0.364193,0.2509995-0.364193,0.4706993
              c0,0.017601,0.0018921,0.0356998,0.003891,0.0517845c-0.0341988,0.0166149-0.0849991,0.0381165-0.1543007,0.0625153
              l-0.9785881,0.2793007c-0.0546112-0.2406998-0.0810013-0.4839001-0.0810013-0.7261009
              c0-0.5590992,0.0507889-1.1103001,0.1503906-1.6390991c1.5438995,0.5370998,2.9179993,1.1317997,2.9179993,2.4213829
              C36.1578827,22.3892002,35.6783829,23.2001991,34.7300835,23.8920841z M36.7154922,25.2460842
              c-0.4678078-0.2357845-0.9473076-0.5516853-1.1963081-0.7225857c0.2723999-0.2177982,0.8017006-0.694416,1.1943092-1.4004154
              c0.3886909,0.695816,0.9267883,1.1806164,1.2021904,1.4004154
              C37.6734924,24.6952991,37.2017822,25.0136986,36.7154922,25.2460842z M38.6978836,23.8920841
              c-0.9453011-0.6908855-1.4277-1.5028839-1.4277-2.4140015c0-1.2895832,1.375-1.8842831,2.9188995-2.4213829
              c0.0996017,0.5307007,0.1504097,1.0819988,0.1504097,1.6390991c0,0.2422009-0.0264091,0.4864006-0.0811081,0.7261009
              l-0.9843025-0.2826996c-0.0673981-0.0209999-0.1142998-0.0425014-0.1484985-0.0591164
              c0.0019989-0.0160847,0.0039101-0.0341835,0.0039101-0.0517845c0-0.2196999-0.1504097-0.4125004-0.364212-0.4706993
              c-0.0409966-0.0112-0.0829964-0.0170002-0.1259995-0.0170002c-0.2177887,0-0.4130974,0.1483994-0.4716988,0.3607998
              c-0.1220894,0.4647999,0.2070007,0.9506836,0.7831993,1.1602001l0.9717102,0.2817001
              C39.6499825,22.8813992,39.238884,23.4018993,38.6978836,23.8920841z M39.5767822,24.424799
              c0.2929993-0.2845993,0.9014015-0.9403992,1.3134995-1.8221989c0.2383003,0.0790997,0.7002106,0.2020988,1.580101,0.4359989
              c-0.017601,1.2446003,0.4598999,2.1040001,0.788002,2.5444012C41.3521843,25.237299,40.0464935,24.6567993,39.5767822,24.424799
              z"></path>
            <path fill="#FDD800" d="M59.2691841,21.7138996l-0.0059013-0.0459003
              c-0.3768997-0.0863991-0.6932983-0.2245998-0.8222008-0.2847004c-0.4169998-0.1933002-1.4960976-0.9232998-1.4960976-1.9799995
              c0-0.0750999,0.0038986-0.1532993,0.0175095-0.2376995c0.010788-0.0713005,0.018589-0.1259995-0.0156097-0.2133999
              c-0.0166016,0.0088005-0.055603,0.0404987-0.1387024,0.1362c-0.3232002,0.3740005-0.7665977,0.5629997-1.3172989,0.5629997
              c-0.407299,0-0.7871017-0.1044998-1.1367989-0.2012005c-0.163002-0.0576-0.5517006-0.2133999-0.9277-0.5072994
              l-0.0283012,0.0170994c0,0,0.25,1.1890011-0.268589,1.9414005c-0.7099113,1.0277996-1.5234108,1.2880993-1.946312,2.0165997
              c-0.4227982,0.7290001,0.0889015,1.4346008,0.4639015,1.7304993c0.0321999,0.0234013,0.6484108,0.4715843,0.6953011,0.5044003
              c0.4941978,0.3383007,0.9316978,0.6527996,1.2402992,1.0341015l0.0283012-0.0019169
              c0.527298,0,1.1102982,0.1684151,1.5643997,0.2998161l0.7871094,0.2056007
              c0.1337891,0.0272999,0.2646904,0.0414982,0.3906898,0.0414982c0.3613091,0,0.9305992-0.1332989,1.2870979-0.7880993
              c0.2822113-0.5205002-0.1611977-1.8344994,0.3983994-2.9701996
              C58.5210838,21.9946995,59.2691841,21.7138996,59.2691841,21.7138996z M55.7525826,23.553299l-0.1913986,1.5507011
              l-0.7686005-1.3696003l-1.5683022,0.2978992l1.0946999-1.1367989l-0.7382965-1.3466015l-0.0059013-0.0088158
              l1.4131012,0.6548157l1.0908966-1.1420994l-0.202198,1.5498009l1.4277,0.6844826L55.7525826,23.553299z"></path>
            <path fill="#FDD800" d="M59.4380836,33.0849991l0.1504097-0.0586014
              c-0.0732117-0.3256989-0.3359108-0.6669998-0.4248123-0.7748985l0.6875-1.2152996
              c0.0488014-0.0049,0.1543121-0.0039005,0.2256012,0.0995998c0.1221008,0.1781998,0.2421989,0.3838005,0.2421989,0.3838005
              l0.3877029-0.8667011L60.4634933,31.9473c0.0448914,0.0282993,0.3720894,0.3013,0.3740005,0.3031998
              c0.0038872-0.0009995,0.0058899-0.0009995,0.010788-0.0018997l0.4570007-0.0884018
              c0.0098-0.0033989,0.0205002-0.0053978,0.0303001-0.0083008c0.0019112-0.0151978,0.2929993-1.3648148,0.2871017-1.5082989
              c-0.0078011-0.1166992,0.0038986-0.5907993,0.0098076-0.7646999c0.0575905,0,0.1444893,0,0.2460899-0.0038986
              c0.3418007-0.014101,0.8339119-0.5946999,1.0683022-0.7241001c-0.0879021,0.2411842-0.2363014,0.6693993-0.2383003,0.8285999
              c0,0.2006989-0.0615005,0.5303001-0.0713005,0.5767002c-0.1035004,0.2748833-0.2334023,0.6103001-0.2967911,0.8988838
              c-0.005909,0.0288162-0.0117111,0.0752163-0.0147095,0.1201153c0.4785004-0.4291992,0.7627106-0.9589996,0.8163986-1.5454006
              l0.0098-0.2178001c0-0.6112995-0.2314873-1.1459999-0.4452972-1.3851986
              c-0.2139015-0.2354012-0.3291016-0.522501-0.3291016-0.8080997c0-0.2451,0.0869102-0.4751015,0.2411995-0.6455002
              c0.1836014-0.198801,0.4393997-0.3125,0.7021027-0.3101158c0.219799,0.0009995,0.5507965,0.0811157,0.886795,0.4551144
              c0.5957031,0.6621017,0.9375,1.6396008,0.9375,2.6797009l-0.0186005,0.4095993
              c-0.0224991,0.2642002-0.0770874,0.5185852-0.1464996,0.7675858c0.5166016-0.932085,0.7871017-1.5961857,1.0332031-2.6332855
              c0.3964996-1.6737995,0.1035004-2.3104992,0.0224991-3.2133999c-0.0468979-0.5135994-0.1035004-1.1878986-0.8212891-1.2005997
              c-0.7451096-0.0157166-0.63871,1.8675995-1.7959099,1.9183998c-0.1220894,0.0064011-0.3398018-0.1156998-0.4229012-0.1674995
              l0.0664024-0.5340996c0.1553001-0.0459003,0.6425972-0.229517,0.6162987-0.7525158
              c-0.0058899-0.0941849-0.0303001-0.169384-0.0546989-0.2162838c0.4510994-0.3652,0.5126991-0.7202015,0.5049019-1.0527172
              l0.5869064-0.1952839l-0.308609-0.1718998l0.0731964-0.2714996l-0.3173981-0.0487995l0.0489044-0.3496017l-0.285202,0.0160847
              l-0.0752029-0.4247837l-0.4188995,0.5014c-0.1728973-0.0599995-0.2901001-0.0195007-0.4570999,0.0219994
              c-0.0713005-0.0067997-0.3885994-0.0272999-0.5028992,0.2207012l-0.1133003,0.2441998
              c0.3661995-0.1240997,0.5342026,0.0629005,0.544899,0.2665997c0.0079002,0.1756992,0.1651001,0.4228001,0.2295036,0.5184994
              l-0.1220016,0.1636009c-0.1240997-0.0547009-0.4609985-0.1914005-0.7383003-0.1797009
              c-0.2881012,0.0116997-0.5283012,0.2480011-0.7461014,0.4033012c-0.0751991-0.0078163-0.2803001-0.0151005-0.4356003,0.1210995
              l-0.1258888,0.1117992c-0.0811081-0.0790997-0.2089996-0.2064991-0.2089996-0.4413986
              c0-0.2368011,0.6835899-0.4717007,0.7802887-0.9526005c-0.3515968,0.1542835-0.8066978,0.4911995-1.0390968,0.6473999
              c-0.4414024,0.3003006-0.5195007,0.5361996-0.5195007,1.7798004c0,1.3676987-0.2226906,1.7670841-0.2226906,1.7670841
              s0.0771904,1.0362148,0.0243912,1.149416c-0.0976028,0.2070999-0.9013023,0.8041992-1.017601,0.9067993l-0.4531021-0.8164005
              c0.0147018-0.0580997,0.0283127-0.1259995,0.0371017-0.1973c0.032299-0.0272999,0.0723-0.0438995,0.1054993-0.0732002
              c0.3496017-0.3080997,0.5811005-0.6264992,0.7294998-0.9477997c0.2891006-0.6182003,0.2705002-1.2455997,0.2705002-1.8232002
              c0-1.7915001,0.0839996-1.7831993,1.8086014-2.7158985l-0.3437996-2.1865005l-0.4433022,0.0741997
              c-0.2519989,0.0429993-0.7245979-0.1044998-1.0780983-0.2695007c-0.2705994-0.1268997-0.9091988-0.6303997-0.8516006-0.9853001
              c0.1299095-0.8154984-0.1309013-1.364315-0.7979012-1.6767998c-0.6727982-0.3129997-1.2607002-0.1602001-1.8017006,0.4647999
              c-0.0829964,0.0976849-0.2782974,0.1445999-0.4921989,0.1445999c-1.3505974,0-1.8974991-1.2079983-1.8974991-1.2079983
              l-1.4413986,0.8652c-0.0595016-0.0659008-0.3241997-0.4341164-0.691391-0.6641178
              c-0.2158089-0.1351833-0.5459099-0.5653839-0.8281097-0.4554825c-0.1366997,0.0541992-0.4902,0.5096989-0.6348,0.6346989
              c-0.0830002,0.0733013-0.3437004,0.3882008-0.4326019,0.4961014c-0.0713005-0.1616001-0.6425972-0.7363167-0.97649-0.9824009
              c-0.1846085-0.1352005-0.4004097-0.2714996-0.4024086-0.3486004c-0.0018997-0.0913162,0.8134995-0.2388,0.7030983-0.6201
              c-0.0643997-0.2242155-0.4852982-0.2495995-0.5917969-0.2535c-0.1914024-0.1278992-0.3496017-0.2264996-0.5634003-0.2621994
              l-0.3047028-0.7342997L47.2701836,14.8448l-0.3155022-0.3150005L46.9126816,15.0323l-0.4081879-0.2046003l0.135788,0.3989
              L46.1294937,15.1348l0.4335899,0.3905993l-0.672802,0.0904007c0.0957031,0.3255997,0.8095016,0.4901991,1.0595016,0.5166006
              c0.0079002,0.0423985,0.024498,0.1097984,0.0469017,0.1908989c0.1083984,0.3773994,0.4570999,0.7495003,0.7763977,1.019001
              l0.1054993,0.5092983c-0.0576973,0.1030006-0.3808975,0.6508999-1.0079002,1.1024837
              c-0.2080002,0.1504173-0.3778992,0.2989178-0.5331879,0.4405174c-0.3046112,0.2801991-0.5058098,0.5165997-0.5449104,0.5643997
              c-0.0751991,0.0464001-0.5370903,0.3565006-0.7480011,0.7441998c-0.1366997,0.2479992-0.2178001,0.5487995-0.1933975,0.8388004
              c-0.1221008,0.0404987-0.3135033,0.0883999-0.3555031-0.0272999c-0.0126991-0.0347004-0.0047989-0.086401,0-0.1231003
              c0.0284004-0.2480011-0.1649971-0.1991997-0.2235985-0.0185165c-0.0253983,0.0796165-0.0391006,0.1924171,0.0019989,0.2889996
              c0.1416016,0.316,0.4326019,0.3291168,0.6786995,0.2530003c0.0859032,0.1616173,0.2177124,0.2881165,0.3359032,0.3984165
              c0.0908089,0.0844994,0.2421989,0.2129002,0.2841988,0.3320007c0.1161995,0.3149986-0.3311005,0.9506989-0.5321999,1.2167988
              l-0.0391006,0.1631012c0,0,0.1464996,0.2509995,0.2421989,0.5175991c0.1445007,0.3892002,0.172802,0.8110008,0.235302,1.2178001
              l0.3154984,0.3031998l0.0820007,0.2642002l0.1416016,0.0926991c0.1405983,0.091301,0.3378983,0.1953011,0.5087967,0.1446018
              c0.1221008-0.0381012,0.1162033-0.1548004,0.0937004-0.2198009c-0.0410004-0.1098003-0.3807983-0.4668007-0.4715996-0.6049004
              c-0.1015892-0.1499996-0.1992989-0.2563992-0.2364006-0.2973995c-0.0360985-0.4696999-0.0956993-0.9365005-0.1952972-1.398901
              c0.1142998-0.094799,0.5088005-0.4369984,0.7364006-0.7817993c0.2137985-0.3237,0.4842987-0.6893997,0.5760994-0.8134995
              c0.0713005,0.0396004,0.2080002,0.1328831,0.2753983,0.3139992c0.0811005,0.2207012,0.0381012,0.5053997,0.0342102,0.5356998
              l0.156189,0.1904011c0.4600029,0.1201,1.0479012,0.776783,1.0479012,0.776783l0.6142998,0.090416l0.1884003,0.2015839
              l0.0674019,0.0030003c0.1933975,0.5419998,0.5312996,1.0361156,1.0312996,1.4336166l0.0605087,0.0438995l0.9501915,0.6772003
              c0.2598076,0.1807003,0.5088081,0.3662834,0.7030983,0.5468998c0.2061005,0.4463005,0.1474991,0.8696995,0.1367111,1.0156002
              c-0.0097122,0.1474991,0.0546875,0.2901001,0.1874886,0.3521004c0.1319008,0.0620003,0.2833023-0.0029011,0.3876991-0.0806007
              c0.1055031-0.0770988,0.6475029-0.4969997,0.9131012-0.5580997c0.2588005,0.0429993,0.5429993,0.1172009,0.8282013,0.1973
              l1.134697,0.3003006l0.0147018,0.0028992c0.5116997,0.1078854,1.0088005,0.1006012,1.467701-0.0131989
              c-0.0018997,0.0058994-0.0078011,0.0200825-0.0097008,0.0200825l0.0643997,0.1040001
              c0.2969017,0.0669174,0.5147095,0.0376167,0.8526001-0.0795841l0.4375,0.7465
              c0.017498,0.1280003-0.0371017,0.5869999,0.1396103,0.578701c0.1777878-0.0083008,0.8652878-0.2621994,0.9501877-0.2656994
              c0.0508118-0.0009174,0.0098114,0.1274986,0.0186005,0.2739983c0.0195007,0.3833008-0.2402992,0.4257011-0.2177887,0.9506016
              l-0.6650009,1.2954845c-0.3339996,0.0029144-0.4844093-0.2065849-0.8077087-0.1913853
              c-0.1318016,0.0053005-0.3310013,0.1181011-0.3310013,0.1181011l0.0058022,0.125c0,0,0.2236977-0.0102005,0.3379097-0.0151024
              c0.0585899-0.0019989,0.1171875,0.0547028,0.1581879,0.1063843c-0.3046989,0.0459175-0.6035004,0.330616-0.6035004,0.330616
              l0.0488014,0.1416016c0.0723-0.0357018,0.3857994-0.1973,0.5713005-0.2066002
              c0.1240997-0.0047989,0.2294998,0.0195999,0.2949982,0.0396004c-0.1240997,0.1903992-0.1835976,0.3833008-0.1973,0.4311981
              l0.1396027,0.0760994c0.0391006-0.0668983,0.3339996-0.3843002,0.4941978-0.391098
              C59.1773834,32.6562996,59.4048843,33.0253983,59.4380836,33.0849991z M50.4234924,17.7167988
              c0.1034889-0.3002987,0.271492-0.4769993,0.3915901-0.4769993c0.2744102,0,0.8213005,0.6875,0.8701019,0.7602005
              c0,0,0.2061005,0.799799,0.2061005,1.4667988c0,0.4155998-0.4073029,0.8091011-0.5391006,0.9444008
              c0,0,0.0077972-1.3017998-0.0986023-1.5986996c-0.0761986-0.2114162-0.9150887-0.1899014-1.319397-0.1879997
              l-0.0019035-0.0903015C49.9771843,18.4384995,50.3257828,17.9820004,50.4234924,17.7167988z M49.2203827,23.5014992
              c0,0.1420994,0.0223999,0.2777996,0.0390015,0.4160004c-0.0018921-0.0015011-0.0058022-0.0024166-0.0088005-0.0034161
              c-0.0966988-0.0321846-0.6200905-0.1815834-0.8838005-0.3925838c-0.1425018-0.1112995-0.4315987-0.5703011-0.4315987-0.8721008
              c0-0.3613148,0.0508003-0.8045998-0.160202-1.3319988c0.1114006-0.0928001,0.8819008-0.6553173,1.795002-1.7196999
              l0.9032974-0.1807003c0.2031021-0.0419998,0.1767006,0.019083,0.20121,0.168499l0.1874924,1.0565853
              c-0.0205994,0.1026154-0.0937996,0.2715149-0.0937996,0.2715149S49.2203827,21.8990002,49.2203827,23.5014992z
              M57.6802826,26.8295994c-0.3671989,0.3232994-0.8251991,0.4932003-1.3270988,0.4932003
              c-0.1651001,0-0.3379021-0.0181007-0.5107994-0.0552006L55.0269814,27.0562
              c-0.4394875-0.1270008-0.9726982-0.2803001-1.4159966-0.2803001l-0.3124924,0.0170994l-0.1084099-0.1494007
              c-0.2578011-0.3578987-0.6894989-0.6659985-1.1816978-1.0038986c-0.0078011-0.0039005-0.7108917-0.5151997-0.7108917-0.5166168
              c-0.5273094-0.4135838-0.8223114-0.9828835-0.8223114-1.6196842c0.0108032-0.3495998,0.0655022-0.6040001,0.1729012-0.8333988
              c0.1973-0.4214001,0.5312004-0.6919174,0.9188995-1.0049171c0.3174019-0.2519989,0.6679993-0.5307827,0.9561005-0.9252834
              c0.0390015-0.0517998,0.0751991-0.1040001,0.110302-0.1587162L52.738884,20.3848
              c0.0918007-0.1942997,0.1406097-0.4351006,0.1406097-0.7168007c0-0.2694988-0.0449104-0.5298004-0.0937119-0.7739162
              l-0.044899-0.230999l0.2030983-0.1185837c0.118103-0.0703011,0.3017006-0.1792011,0.3281021-0.1963005l0.2128983-0.1343002
              l0.1895103,0.1655006c0.3495903,0.3154984,0.7586899,0.4731998,0.8740005,0.5136833
              c0.2850914,0.0781155,0.6122894,0.1689167,0.9423904,0.1689167c0.3778992,0,0.663002-0.1171017,0.8701019-0.3579178
              c0.1465073-0.1688824,0.3115082-0.3339996,0.544899-0.3339996c0.1221008,0,0.2157974,0.0450001,0.2929993,0.080616
              c0.1318016,0.0620003,0.3720016,0.1748009,0.3720016,0.5449009c0,0.0664005-0.0078011,0.1484833-0.0243912,0.2544003
              c-0.0097122,0.0600834-0.0117111,0.1078987-0.0117111,0.1532993c0,0.7549,0.9111023,1.3311005,1.1543007,1.4444008
              c0.1025009,0.0478001,0.4599991,0.2080002,0.8544998,0.275383l0.2421989,0.0415173l0.1201019,0.8178997l-0.1991997,0.1644993
              c-0.4229012,0.2299995-0.8975029,0.5156002-1.125,1.0098l-0.0771904,0.1991997l-0.0097122-0.0039158
              c-0.0186005,0.072216-0.0351982,0.1429996-0.0488968,0.2124157c-0.0820007,0.3905849-0.0937004,0.7089996-0.0937004,0.9863014
              l0.0077972,0.5805988c0,0.4018002-0.0321999,0.7362995-0.184597,1.0625
              C58.0669823,26.424799,57.9068832,26.6323986,57.6802826,26.8295994z"></path>
            <rect x="30.3970833" y="11.1700001" fill="#FDD800" width="12.5029001" height="0.9653"></rect>
            <path fill="#FDD800" d="M32.8794937,10.5058994c0.0390892-1.1210995,0.6122894-2.2743998,2.1376915-2.2743998
              c0.8848,0,1.4179993,0.5478001,1.7099991,1.0088005C37.0464935,8.7837,37.6060829,8.2314997,38.443985,8.2314997
              c1.4218979,0,2.0907974,1.1562004,2.1357002,2.2743998h1.1611977c0-0.0160999,0.0038986-0.0321999,0.0038986-0.0473995
              c0-1.9984999-1.2812881-3.3945003-3.1152878-3.3945003c-0.4668121,0-0.8603096,0.0903001-1.2002106,0.2280002V3.7421999
              h1.7714996V2.6236h-1.7714996V0.8267h-1.2157898v1.7969h-1.7715111v1.1185999h1.7715111v3.6195998
              c-0.3740005-0.1767163-0.8232117-0.2978001-1.3808098-0.2978001c-1.8633003,0-3.1142998,1.3638005-3.1142998,3.3945003
              c0,0.0151997,0.0019989,0.0312996,0.0019989,0.0473995H32.8794937z"></path>
            <path fill="#FDD800" d="M32.3755836,12.7988997c1.005909,2.1469002,2.9151001,4.0493002,4.3535004,4.0493002
              c1.443409,0,3.3516006-1.9024,4.3584099-4.0493002h-1.2724113c-0.8095894,1.5448999-2.0976982,2.8828001-3.0859985,2.8828001
              c-0.9842987,0-2.2733994-1.3379002-3.0830002-2.8828001H32.3755836z"></path>
          </g>
        </g>
        <polygon fill="#397DDA" points="10.3160839,30.0458984 10.3140841,30.0580826 10.3120842,30.0458984 		"></polygon>
        <polygon fill="#397DDA" points="10.3160839,30.0458984 10.3140841,30.0580826 10.3120842,30.0458984 		"></polygon>
        <g>
          <path fill="#397DDA" d="M9.6382837,62.5190849c0,14.9668159,12.1757994,27.1445122,27.1464996,27.1445122
            c14.9678001,0,27.1464996-12.1776962,27.1464996-27.1445122c0-14.9682846-12.1786995-27.1459999-27.1464996-27.1459999
            C21.8140831,35.373085,9.6382837,47.5508003,9.6382837,62.5190849z"></path>
        </g>
        <g>
          <path fill="#FFFFFF" d="M38.7818832,73.1122971c0,1.1103973,0.8993988,2.0137024,2.0126991,2.0137024
            c1.110302,0,2.0135994-0.9033051,2.0135994-2.0137024H38.7818832z"></path>
          <path fill="#FFFFFF" d="M45.693985,73.5424957c-0.4307022-0.1016006-0.7608032-0.2435989-0.9854012-0.3588943
            c-0.3203011,0.4882965-2.8973999,4.5912933-3.2285004,5.0956955c0.193409,0.1156998,0.4854088,0.2641983,0.8848,0.3740005
            c0.5858994,0.1616974,1.2402,0.1054993,1.8740005,0.0517883c0.5663986-0.0468903,1.107399-0.1190872,1.5341988,0.0293121
            c0.4921989,0.1708984,0.7861023,0.5269012,1.0185013,1.0679016c0,0,0.277401-0.2320023,0.4639091-0.2593002l-0.4277115-5.9121017
            C46.4644928,73.6474991,46.0904922,73.6391983,45.693985,73.5424957z"></path>
          <path fill="#FFFFFF" d="M56.7329826,68.1103973c0.0079002,0,0.0166016,0.0014038,0.0245094,0.0014038
            c0.4452896,0,0.8114891-0.3642044,0.8114891-0.8115005c0-0.4487-0.3661995-0.8115005-0.8114891-0.8115005
            c-0.45121,0-0.8135109,0.3628006-0.8135109,0.8115005c0,0.0190964,0.0049019,0.0385971,0.0049019,0.056694
            c-1.4678001,0.1030045-4.4570999,0.1850052-6.5126991-1.3256989c0.1893997-0.2045975,0.3065987-0.4809952,0.3065987-0.7832108
            c0-0.6396866-0.517601-1.1572876-1.1562004-1.1572876c-0.6386986,0-1.157299,0.517601-1.157299,1.1572876
            c0,0.3022156,0.1172104,0.5766144,0.3046989,0.7822113c-2.0497971,1.5087891-5.0312004,1.4297028-6.5028992,1.3266983
            c0.0018997-0.0170975,0.0049019-0.0366974,0.0049019-0.056694c0-0.4487-0.3633003-0.8115005-0.8114929-0.8115005
            c-0.4473076,0-0.8106079,0.3628006-0.8106079,0.8115005c0,0.4472961,0.3633003,0.8115005,0.8106079,0.8115005
            c0.0067902,0,0.0165901-0.0014038,0.0223923-0.0014038h6.7891006l1.0575981,14.8169022
            c0,0.1605988,0.1299095,0.2914963,0.2929993,0.2914963c0.1621017,0,0.2929115-0.1308975,0.2929115-0.2914963
            l1.0507889-14.8169022H56.7329826z"></path>
          <path fill="#FFFFFF" d="M51.693985,74.6030807c-0.3457031-0.2387848-0.642601-0.4443817-1.0518036-0.5405807
            c-0.1219978-0.028801-0.2294998-0.0390015-0.3300972-0.0429001l-0.4619026,6.214798
            c0.6153107,0.294899,1.1387024,0.7636871,1.4883118,0.8500977c0.3603897,0.0884018,0.7509995,0.057106,0.7509995,0.057106
            s2.1093903-4.760704,2.4208908-5.5643997c-0.2910004-0.0152054-0.9071999-0.1382065-1.3164024-0.2368011
            C52.5269814,75.1825943,52.0826836,74.8744965,51.693985,74.6030807z"></path>
          <path fill="#FFFFFF" d="M56.378582,75.1259995c1.1113014,0,2.0116997-0.9033051,2.0116997-2.0137024h-4.0253983
            C54.3648834,74.2226944,55.2671814,75.1259995,56.378582,75.1259995z"></path>
        </g>
        <g>
          <path fill="#FFFFFF" d="M48.7632828,45.7236977c1.1894989,0,2.1533012-0.9628983,2.1533012-2.1513977
            c0-1.1889992-0.9638023-2.1528015-2.1533012-2.1528015c-1.1884995,0-2.1493988,0.9638023-2.1493988,2.1528015
            C46.613884,44.7607994,47.5747833,45.7236977,48.7632828,45.7236977z M48.7632828,42.809597
            c0.4209023,0,0.7636986,0.341301,0.7636986,0.7612038c0,0.4203987-0.3427963,0.7607994-0.7636986,0.7607994
            c-0.4188995,0-0.7597008-0.3404007-0.7597008-0.7607994C48.003582,43.150898,48.3443832,42.809597,48.7632828,42.809597z"></path>
          <polygon fill="#FFFFFF" points="43.1158829,59.4794998 44.613884,59.4794998 44.613884,55.7861977 43.1158829,58.4048004 			"></polygon>
          <path fill="#FFFFFF" d="M46.4322815,52.6098976L45.5640831,54.125v5.3544998h1.4990005v-5.5498009
            c0-0.2636986,0.2128983-0.4761009,0.4756012-0.4761009c0.264698,0,0.4776001,0.2124023,0.4776001,0.4761009v5.5498009h1.4969978
            v-5.5498009c0-0.2636986,0.2139015-0.4761009,0.4756012-0.4761009c0.2626991,0,0.4766083,0.2124023,0.4766083,0.4761009
            v5.5498009h1.4990005v-5.3740005l-0.8565102-1.4956017H46.4322815z"></path>
          <path fill="#FFFFFF" d="M47.5142822,46.3339996c-0.3847885-0.1781998-0.7255974-0.4346008-1.0067902-0.7475014
            c-1.8204079,4.6366997-5.9219093,15.0795021-5.9161072,15.0805016c0.0019989,0,0.2178001,0.1161995,0.2178001,0.1161995
            l7.488308-13.0830002L47.5142822,46.3339996z"></path>
          <path fill="#FFFFFF" d="M42.7046852,52.2773972c0-0.8500977-0.4433022-1.3593979-1.1816025-1.3593979
            c-0.3554993,0-0.7421989,0.2050858-0.7421989,0.652298c0,0.4414024,0.3153992,0.5079002,0.5059013,0.5079002
            c0.2440987,0,0.4413986,0.1972008,0.4413986,0.4423027c0,0.2436981-0.1973,0.4433975-0.4413986,0.4433975
            c-0.8174019,0-1.3907013-0.5728149-1.3907013-1.3936005c0-0.8764,0.6992989-1.5379982,1.6269989-1.5379982
            c0.8906021,0,1.5634995,0.472599,1.8730011,1.2352982l0.8564987-2.1791992h-2.4473
            c-1.7118988,0-2.7733994,0.9985008-2.7733994,2.6049995c0,1.1665001,0.9560013,2.1152,2.1328011,2.1152
            C42.0581818,53.8085976,42.7046852,53.1660004,42.7046852,52.2773972z"></path>
          <polygon fill="#FFFFFF" points="50.5992813,51.7241974 49.0923843,49.088398 48.4478836,49.088398 46.9390831,51.7241974 			"></polygon>
          <polygon fill="#FFFFFF" points="52.9136848,59.4794998 54.4126816,59.4794998 54.4126816,58.3866997 52.9136848,55.7670822 			
            "></polygon>
          <path fill="#FFFFFF" d="M58.4986839,51.6933975c0-1.6064987-1.0615997-2.6049995-2.7744026-2.6049995H53.283783
            l0.8526001,2.1690025c0.3125-0.7564011,0.9842987-1.2251015,1.8681107-1.2251015
            c0.9296875,0,1.6298904,0.6615982,1.6298904,1.5379982c0,0.8207855-0.5741997,1.3936005-1.3935013,1.3936005
            c-0.2421989,0-0.441391-0.1996994-0.441391-0.4433975c0-0.2451019,0.199192-0.4423027,0.441391-0.4423027
            c0.1913986,0,0.5068016-0.0664978,0.5068016-0.5079002c0-0.4472122-0.3848-0.652298-0.7431908-0.652298
            c-0.7402115,0-1.1806107,0.5093002-1.1806107,1.3593979c0,0.8886032,0.6483994,1.5312004,1.5410004,1.5312004
            C57.5406837,53.8085976,58.4986839,52.8598976,58.4986839,51.6933975z"></path>
          <path fill="#FFFFFF" d="M48.7632828,46.6093979c-0.0615005,0-0.1161995-0.0141983-0.1767006-0.0175972l8.1240005,14.1913986
            l0.2158012-0.1161995c0.0077972-0.0009995-4.0878906-10.4326019-5.9121017-15.0732002
            C50.4566841,46.2128983,49.6597824,46.6093979,48.7632828,46.6093979z"></path>
        </g>
        <g>
          <path fill="#FFFFFF" d="M17.7398834,51.2245979c0,1.2012024,0.9297009,1.8682022,1.7490005,2.4561005
            c0.5370998,0.3862,1.2157993,0.733902,1.4990005,1.1743011l1.7284985-0.4706993c0,0,0.6993008-0.4639015,1.0352001-0.7036018
            c0.8174-0.5878983,1.7471008-1.2548981,1.7471008-2.4561005v-0.8833008h-7.7588005V51.2245979z"></path>
          <path fill="#FFFFFF" d="M20.3941841,56.6293983l2.625-0.7318993c0.2343998-0.0654144,0.3701-0.3096008,0.3055992-0.545414
            c-0.0634003-0.2358856-0.3094997-0.3740005-0.544899-0.3080864l-2.6240005,0.7333984
            c-0.2353916,0.0639-0.3721008,0.3081017-0.3085995,0.5449028C19.9136848,56.5571976,20.1597843,56.694397,20.3941841,56.6293983z
            "></path>
          <path fill="#FFFFFF" d="M22.8628845,56.8574982l-2.6259003,0.7328987
            c-0.2334003,0.0649033-0.3721008,0.3100014-0.3047009,0.5449028c0.0634003,0.2357826,0.3094997,0.373497,0.5449009,0.3070984
            l2.6239986-0.7308998c0.2354012-0.0665016,0.3740005-0.3101006,0.3067017-0.5464134
            C23.3443832,56.9291992,23.0982838,56.7919998,22.8628845,56.8574982z"></path>
          <polygon fill="#FFFFFF" points="30.5904922,39.2992973 28.1646843,39.2992973 28.1646843,44.2114983 23.9019833,44.2114983 
            23.9019833,46.6366997 28.1646843,46.6366997 28.1646843,60.6215973 30.5904922,60.6215973 30.5904922,46.6366997 
            34.8511848,46.6366997 34.8511848,44.2114983 30.5904922,44.2114983 			"></polygon>
          <path fill="#FFFFFF" d="M20.5992832,48.0214996c-0.5879002-0.1494179-1.1044998-0.1938019-1.7734985-0.1938019
            c-1.4258003,0-3.1172009,1.1948013-3.1172009,3.3901024c0,2.5351982,1.9785004,3.6850967,3.6826992,4.3237
            c0.1464005-0.1943016,0.3476009-0.3388023,0.5839005-0.4048004l0.1221008-0.0336151
            c-0.2646008-0.2216873-0.9111004-0.509285-1.8467007-1.2089996c-0.8134995-0.6078873-1.6133003-1.2973862-1.6133003-2.6762848
            c0-1.591301,1.2413006-2.4395027,2.1827011-2.4395027c0.5595074,0,1.1551991,0.0792007,1.6229992,0.2294998
            c0.4433994,0.1436005,0.9902,0.5689011,1.6387005,0.5689011c1.102499,0,1.4764996-0.4658012,1.4764996-0.4658012
            l0.0733089-0.4218979c0,0-0.8057079-0.7974014-1.5918083-0.7974014
            C21.3433838,47.8915977,20.613884,48.0214996,20.5992832,48.0214996z M22.4136829,48.5912971
            c0,0.1231003-0.1005993,0.2237015-0.2245998,0.2237015c-0.1239986,0-0.2255917-0.1006012-0.2255917-0.2237015
            c0-0.1239967,0.101593-0.2250977,0.2255917-0.2250977C22.3130836,48.3661995,22.4136829,48.4673004,22.4136829,48.5912971z"></path>
          <path fill="#FFFFFF" d="M22.1245842,58.6733971l-1.2080002,0.3353996
            c-0.4638004,0.4761009-1.5780926,1.035202-1.5780926,1.4981003v0.1093025h4.5614929V60.506897
            C23.8999844,59.9291992,22.158783,59.1977997,22.1245842,58.6733971z"></path>
        </g>
        <g>
          <path fill="#397DDA" d="M14.943984,82.0209961c-0.0966997-0.1118011-0.3223-0.4115982-0.5771999-0.7074966
            c-0.0507002-0.0601044-0.1211004-0.0815048-0.1620998-0.0458984c-0.0419998,0.0356979-0.0185003,0.0976944,0.0225,0.1454849
            c0.3095999,0.3609085,0.3095999,0.4331131-0.3731003,1.0176086l-2.2147999,1.9018021
            c-0.0605001,0.0508041-0.0888996,0.0650024-0.2461004-0.1180954c-0.3817997-0.4444046-0.2880993-0.9468002,0.2227001-1.3838043
            c0.4375-0.3765106,0.9589005-0.3090973,1.2020998-0.0243988c0.0967007,0.1128006,0.1524,0.1166992,0.1934004,0.0814972
            c0.0713005-0.0615005-0.0410004-0.1810989-0.0771999-0.2226105c-0.5595999-0.6523895-1.4101-0.7446899-2.0497999-0.1952896
            c-0.5508003,0.4726028-0.3681002,1.1903992,0.125,1.7651062c0.1777,0.2074966,0.4141083,0.4574966,0.5712996,0.6415939
            c0.1073999,0.1240005,0.3340006,0.41259,0.5371008,0.6499023c0.0761995,0.0894012,0.125,0.0986023,0.1601992,0.0679016
            c0.0420008-0.0356979,0.04-0.0855026-0.0156994-0.1503983c-0.2392006-0.2788162-0.2577915-0.3964996,0.0507994-0.6612015
            L14.4644928,82.9375c0.5624914-0.4834137,0.6669998-0.4814148,0.8710909-0.243103
            c0.0449085,0.0526886,0.0928001,0.084404,0.1348,0.0487976c0.0419006-0.0356979,0.0410004-0.0966949-0.0312996-0.1689987
            C15.2925844,82.3910828,15.0347843,82.1279984,14.943984,82.0209961z"></path>
          <path fill="#397DDA" d="M11.7281837,77.4849014c-0.7725-1.2935028-2.1728992-1.5669022-3.3320999-0.8745041
            c-1.1542997,0.6884995-1.4081998,1.9227982-0.6512995,3.1899033c0.9131002,1.5292969,2.3739996,1.4477844,3.2391996,0.931694
            C11.8824921,80.1949005,12.6060839,78.9532013,11.7281837,77.4849014z M8.1148844,79.7798004
            c-0.4843922-0.8110046-0.1856003-1.8628006,0.9208994-2.5233994c1.428709-0.8535004,2.0693007-0.1445999,2.2734003,0.197197
            c0.5684004,0.9526978,0.0977001,2.0058975-0.9276915,2.6192017C9.1587839,80.8119965,8.4507837,80.3432999,8.1148844,79.7798004z
            "></path>
          <path fill="#397DDA" d="M9.7730837,74.5014954c0.0283003,0.0815048,0.0625,0.1108017,0.1074085,0.0947037
            c0.0663919-0.0223999,0.0702915-0.0825043,0.0292921-0.1753006c-0.1006002-0.2875977-0.2080002-0.5478134-0.2245998-0.5926971
            c-0.0381002-0.1094055-0.1006002-0.3365021-0.2275-0.6973038c-0.0331917-0.0957031-0.0831003-0.1445007-0.1426001-0.1240005
            c-0.0362005,0.0130997-0.0459003,0.0576019-0.016592,0.1380997c0.1171913,0.3394012,0.079092,0.5102997-0.4551086,0.7138977
            l-2.2353997,0.824707l1.9268003-3.977005c0.0419998-0.0894012,0.0761995-0.1840973,0.0527077-0.25
            c-0.0205078-0.0590973-0.0947075-0.0835114-0.2275076-0.0365982l-3.4902,1.1640015
            c-0.3495998,0.114296-0.4776001,0.1020813-0.5762-0.1787033c-0.0313001-0.0884018-0.0683999-0.125-0.1132998-0.1099014
            c-0.0586004,0.0214996-0.0576,0.0956879-0.0264001,0.1841049c0.0956998,0.2724991,0.1924,0.4789963,0.2139001,0.5381012
            c0.0400996,0.1176987,0.1113,0.3900986,0.2118998,0.6776962c0.0458999,0.1080017,0.0918083,0.1669998,0.1436,0.1490021
            c0.0585999-0.0205002,0.0546999-0.0776978,0.031208-0.1436005c-0.1308079-0.376503-0.076108-0.4790039,0.3417921-0.6333008
            l2.4052997-0.9082031l-1.9863,3.9228897c-0.0439997,0.0908127-0.1054997,0.2441101-0.0723,0.3398132
            c0.0234003,0.0669022,0.1152,0.0679016,0.2402,0.0233994l3.3848004-1.134697
            C9.6236839,74.123085,9.672492,74.2148972,9.7730837,74.5014954z"></path>
          <path fill="#397DDA" d="M4.5865841,69.9589996c0.0615001-0.0098038,0.1220002-0.0283051,0.1093001-0.1054993
            c-0.0096998-0.0537033-0.0527-0.0781021-0.1073918-0.0761032c-0.2139082,0.0028992-0.3613081-0.1231003-0.4521079-0.5746994
            l-0.1612003-0.8286133l2.8662002-0.4760895c0.9101996-0.1503983,0.9951997-0.1099091,1.0498996,0.2148056
            c0.0107002,0.0689011,0.0351,0.1196976,0.0957003,0.1026001c0.0556002-0.0019989,0.0790997-0.052803,0.0615082-0.153801
            c-0.0381079-0.2305145-0.1153083-0.597702-0.1367083-0.7291031c-0.0283999-0.1694107-0.0732918-0.5335999-0.1357999-0.9121017
            c-0.0137-0.0843964-0.0546999-0.141098-0.1073999-0.1239929c-0.0625,0.0024948-0.0674,0.0663986-0.0576,0.1283951
            c0.0760999,0.4624023,0.0321999,0.5326996-0.9307003,0.6923981l-2.8270998,0.4697037l-0.1015999-0.703598
            c-0.0780919-0.5175018,0.0156-0.6679001,0.2520001-0.7230988c0.1064-0.0254059,0.1317997-0.0620041,0.1240001-0.1157074
            c-0.0156002-0.0449982-0.0508003-0.0629959-0.1367002-0.0561981c-0.1475,0.0166016-0.5508001,0.0673981-0.6670001,0.0864029
            c-0.1143,0.0195999-0.1493919,0.0488968-0.1415999,0.103096c0.0088,0.0468063,0.0927,0.1748047,0.1552081,0.5449066
            l0.4316919,2.6039963c0.0469,0.2846985-0.0225,0.4872971-0.012692,0.5410004
            c0.007792,0.0541992,0.0410001,0.064003,0.147392,0.078598C3.9439843,69.9550858,4.4410844,69.9833984,4.5865841,69.9589996z"></path>
          <path fill="#397DDA" d="M3.3140843,64.8310852c-0.0145919-0.3280869,0.0771999-0.3710861,0.2948999-0.3954849
            c0.1953001-0.0243988,0.3506-0.0235138,1.2324002-0.0689011l1.0214996-0.0438995
            c0.5616083-0.0234985,1.0225-0.0435028,1.2569003-0.0224991c0.2597079,0.0121994,0.3027081,0.0966949,0.3125,0.3388977
            c0.0028996,0.0698013,0.0204997,0.1240005,0.0752082,0.1221008c0.0545917-0.0025024,0.0917916-0.0508041,0.0868917-0.1529007
            c-0.0176001-0.233902-0.048892-0.6069031-0.0546999-0.7402c-0.0068002-0.1553001,0-0.5307999-0.0156002-0.9131165
            c-0.0049-0.1015854-0.0450001-0.1464844-0.0995917-0.1444817c-0.0557079,0.0023994-0.0752082,0.0663986-0.0723081,0.1283989
            c0.0127001,0.3275986-0.0186,0.4931984-0.2529001,0.5184975c-0.2490997,0.0264015-0.7079997,0.0538025-1.2695999,0.0782013
            l-1.0223999,0.0438995c-0.8809001,0.0376015-1.0450001,0.0517998-1.2246001,0.0434875
            c-0.2588-0.0116882-0.3243001-0.0873871-0.336-0.3525848c-0.0019-0.0537033-0.0215001-0.1240005-0.0985999-0.1211014
            c-0.0469,0.0102997-0.0684001,0.0580978-0.0635002,0.1596832c0.0098002,0.2256165,0.0391002,0.5684166,0.0459082,0.7168198
            c0.0077918,0.1718979,0.0077918,0.539093,0.017592,0.7655945c0.0038998,0.1172028,0.0381,0.1700058,0.0848999,0.1679993
            C3.3033843,64.9560852,3.3160844,64.8852997,3.3140843,64.8310852z"></path>
          <path fill="#397DDA" d="M3.1568844,61.3964996c0.0468998,0.0028992,0.0741999-0.0419998,0.0809999-0.1268997
            c0.0303001-0.4131012,0.1045001-0.4629021,0.6963-0.4180031l2.6084001,0.1938019
            c0.8095999,0.0601006,0.8339996,0.1484985,0.8125,0.4444008c-0.0049,0.0702858,0.0058999,0.1180992,0.0694079,0.1219978
            c0.061492,0.0054016,0.0877919-0.0394974,0.0956917-0.141098c0.0166001-0.2256165,0.0293083-0.6010017,0.04-0.7485008
            c0.0127001-0.1557999,0.0547004-0.5205002,0.0820003-0.894001c0.0088-0.1093979-0.0272999-0.1581993-0.0742002-0.1625977
            c-0.0614915-0.0039024-0.0809999,0.0487976-0.0858998,0.1190987c-0.0323,0.4360008-0.0479002,0.528801-0.8887,0.4658012
            l-0.9267998-0.0683022c-0.0234003-0.0019989-0.0293002-0.018116-0.0273004-0.0498009
            c0.0068002-0.0933151,0.0508003-0.6840973,0.0771003-0.8315964c0.0401082-0.2158012,0.21-0.2900009,0.3887-0.2768021
            c0.0546999,0.0042992,0.1122999-0.0219994,0.1152997-0.0689163c0.0058002-0.0785828-0.0634999-0.0917816-0.1338-0.0966835
            c-0.4443998-0.0326996-0.6650915-0.1200981-0.7802997-0.1283989c-0.04-0.0029144-0.0801001,0.0093002-0.0840001,0.0561981
            C5.2163844,58.8725967,5.3882842,58.8848,5.3677845,59.157299l-0.0635004,0.9580002
            c-0.0008998,0.016098-0.0039001,0.0394974-0.0263,0.0375977l-1.6894917-0.1264992
            c-0.031208-0.0019989-0.045908-0.0181007-0.0439081-0.0415001l0.0810001-0.8866997
            c0.0165999-0.2182999,0.1104-0.3203011,0.1835999-0.3466988c0.0653999-0.0347023,0.1426001-0.0283012,0.2128999-0.0234146
            c0.085,0.0063133,0.1269999-0.0209999,0.1308999-0.0761871c0.0039001-0.0536995-0.0409999-0.0816002-0.1025996-0.0932999
            c-0.0693002-0.0215149-0.4111001-0.0541992-0.4648001-0.0663986c-0.0772002-0.0131989-0.1465001-0.0347023-0.2227001-0.0555992
            c-0.0067999-0.0083008-0.0302-0.0102997-0.039-0.0102997c-0.0469-0.0044022-0.0869,0.016098-0.0899,0.0629997
            c-0.0028999,0.0233994,0.0117002,0.0477829,0.0176001,0.0639992l0.0067999,0.0243988
            c0.0264001,0.0639,0.0098002,0.1810989,0.0118001,0.2509003c-0.0088,0.1324005-0.0908999,1.3242989-0.1064999,1.5498009
            c-0.0137,0.1718979-0.0566001,0.5361977-0.0781,0.839901C3.0757842,61.3354988,3.0953844,61.3910828,3.1568844,61.3964996z"></path>
          <path fill="#397DDA" d="M3.6997843,56.9150848c0.0634999-0.3222847,0.1622-0.3427849,0.3799-0.3163872
            c0.1953001,0.0223846,0.3467002,0.0600014,1.2149,0.2206993l1.0039082,0.1953011
            c0.551692,0.1078987,1.0048919,0.1963005,1.2284918,0.2714996c0.2480001,0.0722008,0.2705083,0.1640015,0.2235999,0.4018021
            c-0.0135999,0.0688972-0.0078001,0.1259995,0.0459085,0.1362c0.0536919,0.0102997,0.1005917-0.0283012,0.1200914-0.127903
            c0.0372-0.2308998,0.0928001-0.6019974,0.1182003-0.732399c0.0303001-0.1532974,0.125-0.517601,0.1982002-0.892601
            c0.0195999-0.0995979-0.0097008-0.1537971-0.0634003-0.1639977c-0.0537004-0.0098-0.0888996,0.0467834-0.1006002,0.1078987
            c-0.0634918,0.3222008-0.1327996,0.4760017-0.3652,0.4463005c-0.2480998-0.032299-0.7031999-0.1128006-1.2549-0.2202988
            l-1.0038996-0.1953011c-0.8662-0.1688995-1.0283999-0.1932983-1.2012-0.2430992
            c-0.2500002-0.0723-0.2949002-0.160717-0.2442002-0.4209023c0.0098-0.0541992,0.0079083-0.1259995-0.0683-0.1406975
            c-0.0479-0.0018997-0.0790999,0.0401001-0.0995998,0.139698c-0.043,0.2226028-0.0938001,0.5620003-0.1221001,0.7084999
            c-0.0321999,0.1688995-0.1181998,0.5259018-0.1620917,0.7485008c-0.0225081,0.1147995-0.0020001,0.1738014,0.0438919,0.1831017
            C3.6607842,57.0341988,3.6900842,56.9678001,3.6997843,56.9150848z"></path>
          <path fill="#397DDA" d="M6.2681842,54.1035004c1.5634999,0.5683975,2.6914001-0.4375,3.1728997-1.8252029
            c0.1230001-0.3540001,0.2431002-0.7918968,0.2343006-1.0517006c-0.0117006-0.1112976-0.0361004-0.1610985-0.1211004-0.2245979
            c-0.1709003-0.125-0.6268997-0.3589172-0.7011003-0.3843002c-0.0595999-0.0200005-0.1113997-0.0131989-0.1299,0.0376015
            c-0.0204992,0.0600967-0.0028992,0.0825005,0.0898008,0.1474991c0.4120998,0.2919998,0.5058994,0.6142006,0.2314997,1.4042854
            c-0.3379002,0.9741135-1.2470918,1.4936142-2.5390921,1.0444145c-1.0195079-0.3955002-1.5576081-1.0868988-1.1904078-2.142601
            c0.2255998-0.6498985,0.7079997-1.2261009,1.29-1.0238991c0.0966997,0.033699,0.1591997,0.0224991,0.1796999-0.0376015
            c0.0194998-0.0590973-0.0234003-0.0985985-0.1171999-0.1391983c-0.1055002-0.0531998-0.4082003-0.158699-0.5898004-0.2299004
            c-0.1875-0.0816002-0.2480998-0.1202164-0.2929997-0.1352997c-0.0518003-0.017601-0.0918002,0.0019989-0.1269002,0.0800858
            c-0.1240997,0.2881126-0.4091997,0.6518135-0.6348,1.301712C4.5669842,52.2388,4.9244924,53.6366997,6.2681842,54.1035004z"></path>
          <path fill="#397DDA" d="M6.5581841,47.9423981c0.1504002-0.2919998,0.2519999-0.283699,0.4531999-0.1973152
            c0.1806002,0.0752144,0.3164001,0.1548157,1.1044998,0.5513153l0.9082003,0.4687996
            c0.5009003,0.2573013,0.9101,0.467701,1.1044998,0.6030006c0.2177,0.1381989,0.2138004,0.2328835,0.1025,0.4482002
            c-0.0321999,0.0620003-0.0429993,0.1181984,0.0059004,0.1436005c0.0487995,0.0243988,0.1043997,0.0009995,0.1512995-0.0899162
            c0.0996008-0.2113838,0.2569008-0.5516853,0.3184004-0.6698837c0.0721998-0.1391144,0.2637005-0.4613991,0.4393997-0.8012009
            c0.0459003-0.0904007,0.0332003-0.1503983-0.0155916-0.1748009c-0.0488081-0.0253983-0.0976076,0.019001-0.1269083,0.0750999
            c-0.1494999,0.2910995-0.2598,0.4190025-0.4747,0.3257027c-0.2303915-0.1001015-0.6434994-0.304203-1.1434994-0.5615005
            L8.4761839,47.594799c-0.7850995-0.4039001-0.9345999-0.471714-1.0868998-0.5674019
            c-0.2177916-0.139698-0.2382998-0.2373123-0.1162-0.4726982c0.0244083-0.0488014,0.0430002-0.1190987-0.0264001-0.1548004
            c-0.0448999-0.0140991-0.0868998,0.0161018-0.1338,0.1069984c-0.1034999,0.2011032-0.2469997,0.5131035-0.3153915,0.6455002
            c-0.0781083,0.1523018-0.2598081,0.4716873-0.3643084,0.672802c-0.0536919,0.1049995-0.0506916,0.1679993-0.0086918,0.1894989
            C6.487884,48.0458984,6.5328841,47.9911995,6.5581841,47.9423981z"></path>
          <path fill="#397DDA" d="M11.2593842,46.7782974c0.2383003,0.3682022,0.2510004,0.5401001,0.0448999,0.8334999
            c-0.0263004,0.0386009-0.0448999,0.0918007,0.0098,0.1211014c0.0517998,0.0367012,0.0937996,0.0181007,0.1522999-0.0648994
            c0.1749001-0.2490005,0.3466997-0.5205002,0.3858004-0.5786018c0.1034994-0.1464996,0.2645998-0.3490982,0.3720999-0.5024986
            c0.0448999-0.0639,0.0594997-0.1118164,0.0009003-0.1512985c-0.0506916-0.036602-0.0859003-0.0132027-0.1660004,0.1014824
            c-0.1084003,0.1534157-0.1864996,0.1568146-0.2567997,0.1070023c-0.0508003-0.0356865-0.1485004-0.1425858-0.2372999-0.2724876
            l-0.3487005-0.5390968c-0.0135994-0.0205002-0.0067997-0.0439034,0.0068998-0.0629005l0.8232088-1.1592026
            c0.0185919-0.0253983,0.0341911-0.033699,0.0507917-0.0317993l1.0360994,0.2504845
            c0.0537004,0.0191154-0.0917997,0.2793159-0.1025,0.3101158c-0.0205002,0.0424995-0.04,0.096199,0.0049,0.1278839
            c0.0448999,0.0318184,0.1220999-0.0101852,0.2080002-0.1322823c0.0625-0.0894012,0.2539005-0.4033012,0.3525-0.5440025
            c0.0908003-0.1278992,0.2568998-0.3250999,0.3428001-0.4461975c0.1436005-0.2046013,0.1436005-0.2725182,0.1044998-0.2989006
            c-0.0556993-0.0298004-0.0858994-0.0136147-0.1269999,0.0444984c-0.1620913,0.2298851-0.5174999,0.1800995-0.8563995,0.1048851
            c-0.9424-0.1932831-3.4238005-0.7801857-3.7666006-0.8490829c-0.1669998-0.0317001-0.2284994-0.0263023-0.2714996,0.0200005
            c-0.0351,0.0513-0.0088005,0.1367989,0.0986004,0.3076973L11.2593842,46.7782974z M9.9693842,44.0205002l0.0234003,0.006897
            l1.7157993,0.4506874L10.988884,45.490799l-1-1.4561005c-0.0136995-0.0093002-0.0194998-0.0141983-0.026392-0.0181007
            L9.9693842,44.0205002z"></path>
          <path fill="#397DDA" d="M18.1392841,37.219799l-0.950201-1.1509018
            c-0.3759995-0.4496994-0.4296989-0.5268974-0.1474991-0.7616997c0.0723-0.0601158,0.0820999-0.1088982,0.0479088-0.1518974
            c-0.036108-0.0415001-0.0869083-0.0293007-0.170908,0.0410004c-0.1738014,0.1444969-0.3438015,0.3070984-0.5,0.4370003
            c-0.1260014,0.1049995-0.3086014,0.2270966-0.5360928,0.4169998c-0.085,0.0702972-0.0996084,0.1239967-0.0704079,0.1606979
            c0.0352001,0.0419998,0.0917997,0.0355988,0.1573-0.0191002c0.3359003-0.2807007,0.4071989-0.2075005,0.7772989,0.2368011
            l1.0195999,1.2343826c0.7343006,0.9028168,0.8974018,1.4898186,0.2373009,2.0401154
            c-0.75,0.625-1.3535995,0.1938019-1.9444008-0.5136986l-1.0858994-1.3017998
            c-0.3701-0.4443016-0.3769999-0.5498009-0.1377001-0.7504997c0.0664005-0.0546989,0.0752001-0.1035004,0.0410004-0.1450005
            c-0.0341997-0.0429993-0.0917997-0.037117-0.1747999,0.0331993c-0.1620998,0.1352997-0.3691006,0.3389015-0.5430002,0.4844017
            c-0.2402,0.1991997-0.4355001,0.3309975-0.6758003,0.5317001c-0.0829992,0.0699005-0.0995998,0.1240997-0.0633993,0.1665001
            c0.0292997,0.0356979,0.0790997,0.0346985,0.1455002-0.0205002c0.3055992-0.2549019,0.3944998-0.2470016,0.7851,0.2206993
            l1.1044998,1.3251991c0.8720999,1.0445023,1.7354002,1.1280022,2.687499,0.3316002
            C19.1744919,39.2045975,18.9898834,38.2388,18.1392841,37.219799z"></path>
          <path fill="#397DDA" d="M20.6617832,37.0825005c0.2734013,0.5166969,0.1914005,0.5795822-0.0742912,0.7260971
            c-0.0771084,0.0405998-0.098608,0.080101-0.0771084,0.1211014c0.0342007,0.0620003,0.0928001,0.0556984,0.1786995,0
            c0.267601-0.1455002,0.5059013-0.294899,0.5469017-0.3173981c0.1025066-0.0561028,0.3163986-0.1548004,0.6512985-0.3388023
            c0.0888996-0.0489006,0.1289997-0.1054993,0.0986996-0.1606979c-0.0175991-0.0346031-0.0634899-0.036602-0.1386986,0.0053978
            c-0.3154011,0.172802-0.4902,0.161602-0.7783012-0.3306007l-1.1777-2.0702972l4.2353001,1.2495003
            c0.0957012,0.0287971,0.1943016,0.0458984,0.2568016,0.0121994c0.0546989-0.030201,0.0644989-0.1074028-0.0018921-0.2304001
            l-1.7188091-3.2520027c-0.1688995-0.3256989-0.1767006-0.4545975,0.0830002-0.5971985
            c0.0811005-0.0443993,0.1123009-0.088316,0.089901-0.1289005c-0.0303001-0.0546989-0.1035004-0.0419998-0.1856003,0.0030003
            c-0.2539005,0.1395855-0.4414005,0.2685013-0.4961014,0.297802c-0.1083908,0.0601006-0.3670998,0.1747818-0.6346989,0.3213005
            c-0.0986919,0.0634842-0.1485004,0.1171989-0.1231003,0.1649971c0.0312996,0.0547028,0.0860004,0.0424843,0.1474991,0.0088005
            c0.3496017-0.1908989,0.4590015-0.1533165,0.6797009,0.2328987l1.2880993,2.2232018l-4.1924-1.3179016
            c-0.0956993-0.0277977-0.2568989-0.0639-0.3466988-0.0150986c-0.0615005,0.0331993-0.0477924,0.1240005,0.0165997,0.2406998
            L20.6617832,37.0825005z"></path>
          <path fill="#397DDA" d="M24.541584,31.2743988c0.3095989-0.1123161,0.3769989-0.0380001,0.4668007,0.1625996
            c0.0811081,0.1788006,0.1269989,0.328701,0.4355984,1.1548023l0.3486004,0.9609985
            c0.1914005,0.5292854,0.3486996,0.9618988,0.3985004,1.1927986c0.0664082,0.2504845-0.0009995,0.3164024-0.2285919,0.3990021
            c-0.0664082,0.0243988-0.1123085,0.0565987-0.093708,0.1083984c0.0184994,0.0507011,0.0762005,0.0722008,0.1709003,0.038002
            c0.2178078-0.0878029,0.5653992-0.2299156,0.6893997-0.2749176c0.1474991-0.0536842,0.5068989-0.159584,0.8671989-0.2894821
            c0.0957012-0.0347023,0.1260014-0.0874023,0.1075001-0.1387024c-0.0185986-0.0517006-0.0849991-0.0527-0.1445999-0.0312004
            c-0.3076,0.1118011-0.4755993,0.1308022-0.5702991-0.0834999c-0.0995998-0.2299995-0.2637005-0.6596985-0.455101-1.1884995
            l-0.3485985-0.9618988c-0.3018017-0.8285999-0.3643017-0.9805012-0.4111004-1.1553001
            c-0.0655003-0.25-0.0137005-0.3354168,0.2362995-0.4263c0.0517006-0.0179996,0.1112995-0.0566006,0.085001-0.1298008
            c-0.0235004-0.0419998-0.075201-0.0478992-0.1708927-0.0131989c-0.2119999,0.0770988-0.5293083,0.2094002-0.6689987,0.2593002
            c-0.1621094,0.0584984-0.5127087,0.169899-0.7246094,0.2469997c-0.1103916,0.0395832-0.1522999,0.0873985-0.1357002,0.1308994
            C24.4195843,31.3017998,24.4908829,31.2924995,24.541584,31.2743988z"></path>
          <path fill="#397DDA" d="M31.9302845,29.240799c0.0839977-0.0186005,0.1172085-0.0489006,0.1054974-0.1030998
            c-0.0107994-0.0527-0.0712891-0.0722008-0.1708984-0.0508003c-0.1679993,0.0356998-0.3153915,0.0909004-0.5282993,0.136301
            c-0.1611996,0.0345993-0.3271999,0.0458832-0.6162014,0.1078835c-0.1298904,0.0273151-0.1845989,0.0713158-0.1640987,0.1299152
            c0.0018997,0.0478001,0.0683002,0.0580845,0.1376991,0.0429001c0.1826-0.0384998,0.2821999-0.0205002,0.2968998,0.0489006
            c0.0097008,0.0459003,0.012701,0.1332836-0.0029984,0.2084999l-0.5185013,3.0312004l-1.5186005-2.3257008
            c-0.1288986-0.1962986-0.2079983-0.3383999-0.222599-0.4077148c-0.027401-0.1303844,0.1268997-0.1547852,0.2245998-0.1830845
            c0.0692997-0.0151005,0.1093006-0.0478001,0.0986004-0.1016006c-0.0116997-0.0527-0.0946999-0.0673161-0.1786995-0.049799
            c-0.2900009,0.0620995-0.5606003,0.1445999-0.705101,0.174799c-0.2001991,0.0346851-0.5312004,0.0894012-0.8368988,0.1548004
            c-0.1064014,0.0219994-0.1768017,0.0688839-0.1660004,0.1226006c0.0116997,0.0541992,0.0557003,0.0682831,0.1161995,0.0551987
            c0.3740005-0.0790997,0.5547009,0.0580845,0.7285004,0.3080845l2.2694988,3.2671146
            c0.1026096,0.1455002,0.1807003,0.2168007,0.2422009,0.2036018c0.0839996-0.017601,0.1220989-0.105999,0.1669998-0.3389015
            l0.7959003-3.7538986C31.5826836,29.4428997,31.6558838,29.2992992,31.9302845,29.240799z"></path>
          <path fill="#397DDA" d="M36.2876816,32.2807999c-0.0633965,0.0043983-0.0760994,0.044899-0.0868988,0.1161995
            c-0.0243988,0.2358017-0.080101,0.341301-0.2157974,0.419899c-0.1444931,0.0723-0.5879021,0.108902-0.7363014,0.1177025
            c-0.4209023,0.027298-0.4756012,0.0155983-0.5019989-0.3823013c-0.0097008-0.1719017-0.0439034-0.6851006-0.0848999-1.3404007
            c-0.0010033-0.0160999,0.0057983-0.0312004,0.0292969-0.0331993c0.0459023-0.0029011,0.6943016-0.0282993,0.8027-0.0356159
            c0.2725029-0.0170841,0.3418007,0.2046165,0.3535004,0.3843155c0.002903,0.0384998,0.0293007,0.0834999,0.0830002,0.0804996
            c0.0781021-0.0052986,0.0811005-0.0834999,0.0772095-0.1455002c-0.0205078-0.3119984,0.0233917-0.7373142,0.0184898-0.8153992
            c-0.0038986-0.0541992-0.0370979-0.075201-0.0751991-0.0732002c-0.0400009,0.0018997-0.0673981,0.059-0.1415977,0.1186008
            c-0.0449028,0.0336838-0.1875,0.1210995-0.4990005,0.1415997c-0.2490005,0.0160847-0.5391006,0.0263996-0.6240005,0.0321999
            c-0.032299,0.0025005-0.0469017-0.0048008-0.0489006-0.0361156l-0.1004982-1.5590839
            c-0.0010033-0.0234013,0.0057983-0.0317001,0.0205002-0.0327015c1.1385994-0.0732155,1.1796989-0.0433998,1.2508965,0.335001
            c0.0108032,0.0464001,0.0147018,0.1084003,0.0850029,0.1044998c0.0615005-0.0039005,0.0907974-0.0459003,0.0868988-0.0995998
            c-0.0303001-0.4761162,0.0293007-0.6436005,0.0214996-0.7607994c-0.0019989-0.0468998-0.0205002-0.0840015-0.0673981-0.0810013
            c-0.0469017,0.0018997-0.1445007,0.0712013-0.3466911,0.0839005c-0.1093102,0.0074005-1.2783089,0.0825996-1.4648094,0.0947838
            c-0.1641006,0.0102158-0.5312996,0.0185165-0.8506012,0.038517c-0.1093979,0.0073986-0.1543007,0.0410995-0.1503983,0.0956993
            c0.0028992,0.0552006,0.0439987,0.0751991,0.1376991,0.0694008c0.327198-0.0205002,0.4561081,0.0487995,0.4726982,0.2904987
            c0.0107002,0.1875,0.0293007,0.3428001,0.0858994,1.2236004l0.0664024,1.0279007
            c0.0195007,0.3041992,0.0293007,0.5926991,0.044899,0.826601c0.0419998,0.6547813-0.0009003,0.7197838-0.3280983,0.7411995
            c-0.0703011,0.0039978-0.1152,0.0307999-0.1123009,0.0854988c0.0038986,0.0551987,0.044899,0.0834999,0.1464996,0.0761986
            c0.2333984-0.0141983,0.607399-0.0536995,0.7392006-0.0629997c0.6231003-0.0396004,1.202198-0.0458984,1.7714996-0.0821152
            c0.1240997-0.015583,0.1679993-0.0580826,0.1992989-0.185482c0.0156021-0.1260033,0.0361099-0.5186005,0.0283012-0.6504021
            C36.368782,32.3306007,36.3560829,32.2686005,36.2876816,32.2807999z"></path>
          <path fill="#397DDA" d="M37.681282,33.2304993c0.2334023,0.028801,0.5996017,0.0400009,0.7168007,0.0498009
            c0.0858994,0.0083008,0.5195007,0.0634995,0.8613014,0.0946999c0.1016006,0.0102997,0.1445007-0.0254021,0.1484985-0.0722008
            c0.0047989-0.0547142-0.0391006-0.0820999-0.0859985-0.0864983c-0.4668007-0.0424004-0.5107002-0.1645012-0.4541016-0.778801
            l0.0830002-0.9018002c0.0019989-0.0160999,0.0019989-0.0160999,0.0166016-0.0142002l0.5116997,0.0629997
            c0.0468979,0.0039005,0.0761986,0.0303001,0.089901,0.0546989c0.1942978,0.2768002,0.339798,0.5643997,0.4960976,0.8296013
            c0.4071999,0.6805992,0.7196999,1.0395012,1.3652,1.0985985l0.5606003,0.0517998
            c0.0937004,0.0078011,0.1425018-0.0112991,0.1473999-0.0732994c0.0049019-0.0536995-0.0400009-0.0825005-0.0937004-0.0873985
            c-0.4580002-0.0415001-0.8456993-0.3520012-1.8788986-2.0854168c0.5965996-0.3530846,0.8330002-0.7870846,0.8730011-1.222683
            c0.0751991-0.8242016-0.6553001-1.142601-1.4101028-1.2119007c-0.1787987-0.0160999-0.6240883-0.0337009-0.8261986-0.0517998
            c-0.1240005-0.0122013-0.4883003-0.0610008-0.7998009-0.0893002c-0.1171989-0.0112-0.1581993,0.0170994-0.1620903,0.0629997
            c-0.01371,0.0536842,0.0301895,0.0893002,0.1151924,0.0976009c0.452198,0.0414982,0.4589996,0.1362991,0.4110985,0.6567993
            l-0.2547989,2.7767982c-0.0538025,0.5674019-0.1044922,0.7036018-0.4384918,0.6734009
            c-0.0703087-0.0064011-0.1269989,0.0121994-0.1328087,0.0658989
            C37.5357819,33.1860847,37.5796852,33.2216988,37.681282,33.2304993z M39.1480827,29.3105984
            c0.0028992-0.0395985,0.0137024-0.0771999,0.3408012-0.0478992c0.5058975,0.0468998,0.8358994,0.4619007,0.777401,1.1073837
            c-0.0489006,0.5289173-0.2891006,0.9224167-0.8184013,0.8736172c-0.3261986-0.0293007-0.4629021-0.0816174-0.4541016-0.1831017
            L39.1480827,29.3105984z"></path>
          <path fill="#397DDA" d="M45.2329826,33.2070847c0.3232994-1.3276863-1.3456993-1.9335861-1.1405983-2.7758846
            c0.0694008-0.3203011,0.371109-0.5044003,0.7812996-0.4048004c0.4403992,0.1068993,0.6679001,0.4921989,0.592701,0.8036842
            c-0.0156021,0.0596161-0.0087013,0.1338158,0.0585976,0.1504154c0.0615997,0.0151005,0.1045113-0.0307999,0.1397018-0.1748009
            c0.1083984-0.4477997,0.1630974-0.6029987,0.1786995-0.6714001c0.0078011-0.0302982,0.0019989-0.0723-0.0536995-0.1098995
            c-0.1231003-0.0863991-0.3857994-0.1982002-0.7647018-0.2904987c-0.8045998-0.1953011-1.4608994,0.1269989-1.6112976,0.7817993
            c-0.3125,1.2821999,1.3349991,1.8426991,1.0938072,2.8369007c-0.0704079,0.2880974-0.3936081,0.5634003-0.8868103,0.4432983
            c-0.5760994-0.1400986-0.7460976-0.6312981-0.6483994-1.0341988c0.0137024-0.0595016,0.0137024-0.125-0.0536995-0.1411018
            c-0.0615005-0.0140991-0.1161995,0.044487-0.139698,0.1431007l-0.2168007,0.7915001
            c-0.0498009,0.1718864,0.3261986,0.408699,0.9034081,0.5478859
            C44.2242851,34.2880821,45.0425835,33.9960823,45.2329826,33.2070847z"></path>
          <path fill="#397DDA" d="M47.9244919,35.3428001c0.0184898-0.0517998-0.0323105-0.0957184-0.0899086-0.1172028
            c-0.3076019-0.1138-0.4482002-0.2085152-0.3807983-0.4330978c0.072197-0.2397003,0.2254982-0.6743011,0.4207993-1.2017021
            l0.3554993-0.9598999c0.3075981-0.8280983,0.3574104-0.9843979,0.4346008-1.1469975
            c0.1102982-0.2339001,0.2059975-0.2656002,0.4550972-0.1733017c0.0507126,0.0194855,0.1220016,0.0298004,0.1494026-0.0435143
            c0.0097008-0.0468845-0.0264015-0.0853844-0.1221008-0.1210842c-0.2118988-0.0781002-0.5400009-0.1830997-0.6786995-0.2348003
            c-0.1611023-0.0601158-0.5009995-0.2021999-0.7128906-0.2807999c-0.1103096-0.0405006-0.1719093-0.0303154-0.1885109,0.0131989
            c-0.0243988,0.0664005,0.0371017,0.1050014,0.0879021,0.1240005c0.3076096,0.1142998,0.3115005,0.2157993,0.25,0.4263
            c-0.0527,0.1879997-0.1152,0.3325005-0.4150009,1.1620998l-0.3554993,0.9598999
            c-0.1953011,0.5279007-0.3563995,0.9599991-0.4668007,1.168499c-0.1113014,0.233902-0.2060013,0.2402-0.4326019,0.1566849
            c-0.0663986-0.0243835-0.1229973-0.0296822-0.1415977,0.0225182c-0.0186005,0.0507965,0.0116997,0.1034813,0.1063995,0.1391983
            c0.2227097,0.0741997,0.5791016,0.1898994,0.7041016,0.2358017c0.1464996,0.0541992,0.4892998,0.2064819,0.8486977,0.339798
            C47.8482819,35.4140816,47.9058838,35.3936005,47.9244919,35.3428001z"></path>
          <path fill="#397DDA" d="M49.0191841,35.7168007c-0.0264015,0.0468979-0.0009995,0.0976982,0.0878983,0.1464996
            c0.2041016,0.1147842,0.5321999,0.2797813,0.6543007,0.3476982c0.2518997,0.1416016,0.6826019,0.4618988,1.0565987,0.671402
            c1.2842026,0.7259979,2.6836014,0.6209984,3.4453011-0.741703c0.7686005-1.3764992,0.0136986-2.4766159-1.5253983-3.336998
            c-0.3076019-0.1717987-0.6416016-0.3408012-0.797802-0.4281998c-0.143589-0.0796013-0.4599991-0.2826996-0.7255898-0.4311008
            c-0.1025085-0.0571995-0.1582108-0.0527992-0.1846085-0.0049c-0.0263023,0.0478001-0.0039024,0.0873985,0.077198,0.1328011
            c0.2793007,0.1557846,0.3574104,0.2891006,0.2402,0.5004997c-0.0917892,0.1625977-0.1650009,0.3100014-0.5956993,1.080101
            l-0.4980011,0.8934975c-0.711998,1.2738991-0.7939987,1.3262024-1.0186005,1.2006836
            C49.1119843,35.678299,49.0494919,35.6620827,49.0191841,35.7168007z M50.6900826,35.3974991l1.3241997-2.3540001
            c0.0908012-0.1636009,0.1270027-0.1973,0.4813995,0.0009995c1.1182022,0.6235008,1.5615997,1.7020988,0.9512024,2.8281021
            c-0.6387024,1.1449966-1.4131012,1.3124847-2.4892006,0.7113991
            C50.2964935,36.214901,50.3179817,36.0654984,50.6900826,35.3974991z"></path>
          <path fill="#397DDA" d="M57.1744919,40.3798981c0.2694893-0.9228134,1.0536919-3.346199,1.1502914-3.6815987
            c0.0449982-0.1660004,0.0449982-0.2271004,0.0029984-0.2724991c-0.0478973-0.0400009-0.1357994-0.0205002-0.3154984,0.0732841
            l-3.4647903,1.8618164c-0.3848114,0.2074966-0.5566101,0.2074966-0.8330002-0.0225029
            c-0.0371094-0.0298004-0.0889091-0.0531998-0.1221085,0c-0.0400009,0.0479012-0.0254021,0.091301,0.0527992,0.1566849
            c0.2343102,0.1939163,0.4911995,0.3863144,0.544899,0.4307175c0.1386986,0.1152,0.3261986,0.2919998,0.4696999,0.4120979
            c0.0596008,0.0498009,0.1064987,0.0669022,0.1514015,0.0131836c0.0400009-0.0477829,0.0195007-0.0844841-0.0879021-0.1747818
            c-0.1445007-0.1191025-0.1405983-0.1973-0.0859985-0.2632179c0.0401001-0.0477829,0.1534004-0.1366844,0.2910995-0.2157822
            l0.5653992-0.3017998c0.0205002-0.0135994,0.0438995-0.0044174,0.0615005,0.0107994l1.0879021,0.9139977
            c0.0233994,0.0195999,0.0313072,0.0357018,0.0273094,0.0533028l-0.3330002,1.0116997
            c-0.0234108,0.0517006-0.2714119-0.1128006-0.2998123-0.1275024c-0.0419998-0.0243988-0.0937004-0.046299-0.1278992-0.0047989
            c-0.0360985,0.0429001,0,0.1220016,0.1133003,0.2168007c0.0848999,0.0702858,0.3818016,0.2865982,0.5136986,0.3964005
            c0.1201019,0.0995979,0.3027115,0.2827988,0.4179001,0.3769989c0.1924019,0.160099,0.258812,0.1650009,0.2891006,0.1283989
            c0.0331993-0.0527,0.0195007-0.0845146-0.0351982-0.1303978C56.993782,41.0625,57.0718842,40.7114983,57.1744919,40.3798981z
            M56.8023834,38.9950829l-0.9511986-0.7992821l1.5321999-0.8784027c0.0107994-0.0121994,0.0156975-0.0184975,0.0204964-0.0243988
            l-0.0047989,0.0059013l-0.0088005,0.0223999L56.8023834,38.9950829z"></path>
          <path fill="#397DDA" d="M59.128582,43.6850853c0.9393997,1.1396141,2.2831993,1.5464134,3.4843025,0.5469131
            c1.2128983-1.0098,0.9053001-2.3080978-0.2236023-3.6655998c-0.2245979-0.2695007-0.4766006-0.548317-0.5917892-0.6865005
            c-0.1045113-0.1259995-0.3271103-0.4291992-0.5215111-0.6641006c-0.0751991-0.0892982-0.1288986-0.1054001-0.1708984-0.0711975
            c-0.0429993,0.0355988-0.0361023,0.0804825,0.0244102,0.1527977c0.2050896,0.2456017,0.2294884,0.3984032,0.0439987,0.5531998
            c-0.1446114,0.1196022-0.2656097,0.2304878-0.9444084,0.7949028l-0.7871017,0.6548004
            c-1.1230011,0.934597-1.2187004,0.953598-1.3838005,0.7553978c-0.089798-0.1083984-0.1415977-0.1464996-0.1903992-0.1064987
            c-0.0419998,0.0356979-0.035099,0.0914001,0.0293007,0.1699982c0.1494102,0.1797028,0.3964996,0.4521027,0.4863014,0.5600014
            C58.5689812,42.900898,58.854084,43.3544998,59.128582,43.6850853z M59.5435829,42.2529984l2.0830002-1.7231979
            c0.1445007-0.1201019,0.1903992-0.1372032,0.4501991,0.1747971c0.8203011,0.9854012,0.8457108,2.1529007-0.1298981,2.9834023
            c-1.0088005,0.839901-1.7910004,0.7178001-2.580101-0.2309151
            C58.8814926,42.8745003,58.9546814,42.7421989,59.5435829,42.2529984z"></path>
          <path fill="#397DDA" d="M64.283783,52.2118988c0.0839996,0.0732994,0.1358032,0.0937996,0.2422028,0.0806007
            c0.2099991-0.0254173,0.7021942-0.1674995,0.7735062-0.1991997c0.0565872-0.0254021,0.0907898-0.0659027,0.0682907-0.1156998
            c-0.0253983-0.0572014-0.0526962-0.0625153-0.1660004-0.0459023c-0.4990005,0.0678024-0.7891006-0.1016159-1.1288986-0.8652992
            c-0.419899-0.9418983-0.1123009-1.9423141,1.1356964-2.4975014c1.0157013-0.4096985,1.8799133-0.2734985,2.3339996,0.7475014
            c0.2793045,0.6269989,0.3223038,1.3774986-0.2411957,1.6279984c-0.0918045,0.0415001-0.1298904,0.0923004-0.1044998,0.1494026
            c0.0253983,0.0565987,0.0859985,0.0555992,0.1807022,0.0223999c0.1141968-0.033699,0.4061966-0.1640015,0.5879059-0.2363014
            c0.191391-0.0678978,0.2626877-0.0830002,0.3055878-0.1016006c0.0498047-0.0223999,0.065506-0.0639,0.0371017-0.1455002
            c-0.1054001-0.294899-0.1473999-0.7543983-0.4266891-1.3827972c-0.5664062-1.269001-1.7832108-2.0439148-3.0811081-1.4653015
            c-1.5293007,0.6548004-1.6631012,2.1596985-1.065403,3.5009995
            C63.8872833,51.6274986,64.0992813,52.0292969,64.283783,52.2118988z"></path>
          <path fill="#397DDA" d="M64.7359848,53.0093002c-0.0108032-0.0444031-0.0391006-0.0942154-0.0987015-0.0708008
            c-0.0605011,0.0150986-0.0742035,0.0575829-0.0497894,0.1562996c0.0742874,0.2958984,0.1679993,0.6030006,0.1845856,0.6708984
            c0.0430069,0.1743011,0.0908051,0.4281998,0.1367035,0.610302c0.0195999,0.0751991,0.0458984,0.1171989,0.1143036,0.1005974
            c0.0595932-0.0150986,0.0664062-0.0565987,0.0311966-0.1933975c-0.044899-0.1816025,0.0038986-0.2425995,0.0878983-0.2641144
            c0.0606003-0.0151863,0.2041016-0.0180855,0.3614044-0.0020027l0.634697,0.0913162
            c0.0243988,0.0009995,0.0391006,0.0224991,0.0439987,0.044899l0.3387985,1.3819008
            c0.0069046,0.0293007,0.003006,0.0468864-0.0106964,0.0581017l-0.8672028,0.6200981
            c-0.0487976,0.028801-0.1513977-0.2518997-0.1669998-0.2807999c-0.0185013-0.0424004-0.0477982-0.0922012-0.1006012-0.0790977
            c-0.0526962,0.0131989-0.072197,0.0986977-0.0350876,0.2431984c0.0262909,0.1054993,0.1376877,0.4566002,0.1796875,0.6231003
            c0.038002,0.1512833,0.0781021,0.4067001,0.1142044,0.5512009c0.0606003,0.2421989,0.1112976,0.2856979,0.157196,0.2743988
            c0.0586014-0.0219002,0.0664062-0.0565987,0.048912-0.125c-0.0684128-0.2723999,0.2030869-0.509201,0.4813919-0.7168007
            c0.7636948-0.5853996,2.8291016-2.0775986,3.1064987-2.2919006c0.134697-0.105999,0.169899-0.1557999,0.163002-0.2178001
            c-0.0156021-0.0611-0.0966034-0.096199-0.2978058-0.1269989l-3.8983994-0.5458984
            C64.961586,53.4609985,64.8238831,53.3583984,64.7359848,53.0093002z M68.3023834,54.3554993
            c0.0156021-0.0039024,0.0233994-0.0059013,0.0302963-0.0078011l-0.006897,0.0018997l-0.0214996,0.0131989l-1.4637909,1.0034027
            l-0.2939987-1.2070007L68.3023834,54.3554993z"></path>
          <path fill="#397DDA" d="M69.4488831,57.5303001c-0.0625,0.0048981-0.1240997,0.0194969-0.1172028,0.0966988
            c0.0049057,0.0546989,0.0469055,0.0820007,0.1015015,0.0853996c0.2128983,0.0121994,0.3516006,0.1484985,0.4082031,0.605999
            l0.0987015,0.8379021l-2.8935928,0.2602005c-0.9169998,0.0825844-1.0009995,0.0346985-1.0293121-0.2915001
            c-0.0067978-0.0703011-0.027298-0.1230011-0.0878983-0.110302c-0.055603-0.0029984-0.0830002,0.0469017-0.073204,0.1473846
            c0.0215073,0.234417,0.0703049,0.6055145,0.0820007,0.7373161c0.0157013,0.1718979,0.0323029,0.5391006,0.0664139,0.9194984
            c0.0077896,0.0853996,0.0449982,0.1455002,0.0986862,0.1323013c0.0625,0.0028839,0.073204-0.0610008,0.0664062-0.1230011
            c-0.0410004-0.4668007,0.0077972-0.5341988,0.9794998-0.6215973l2.8535004-0.2564011l0.0487976,0.7089996
            c0.0391083,0.5214996-0.0653992,0.6641006-0.3057022,0.7016983c-0.107399,0.0171013-0.1366959,0.0517006-0.1307983,0.1058998
            c0.0116959,0.0454025,0.0438995,0.0669022,0.1307983,0.0669022c0.1484985-0.0063019,0.5538101-0.0263023,0.6708984-0.0366173
            c0.1172028-0.0111847,0.1534042-0.0375824,0.1485062-0.0927849c-0.0039062-0.0467987-0.0791016-0.1805992-0.1123047-0.5536995
            l-0.2363968-2.6288986c-0.0262985-0.2876167,0.0577011-0.483902,0.0537949-0.5381012
            c-0.0048981-0.0546989-0.0372009-0.0668983-0.140686-0.0892982
            C70.0884933,57.5820847,69.596283,57.5170822,69.4488831,57.5303001z"></path>
          <path fill="#397DDA" d="M71.181282,66.1016006c-0.0282974-0.0419998-0.5028992-0.8716049-0.719696-1.2022018
            c-0.0440063-1.5150986-1.164093-2.1991997-2.0899048-2.2733994c-1.0438995-0.0834999-2.3602982,0.5093002-2.4961014,2.2060852
            c-0.1180878,1.5029144,0.8633041,2.5444107,2.2032013,2.6513138c1.323204,0.1054993,2.2352982-0.7230988,2.3797989-2.1445007
            c0.128006,0.4564972,0.2919998,1.0560989,0.2910004,1.0727997c0.0098038,0.0708008,0.1016006,0.1015015,0.1660995,0.0678024
            c0.131813-0.0762024,0.171814-0.0884171,0.238205-0.1381989C71.2037811,66.3067017,71.2495804,66.2231979,71.181282,66.1016006z
            M70.1509857,65.0629959c-0.0830002,0.9419022-0.9209061,1.6269989-2.2207031,1.5235062
            c-1.6503983-0.1314163-1.7627029-1.0811005-1.7304993-1.4776001c0.086998-1.1055069,1.0830994-1.7002029,2.2812958-1.5962029
            C69.9048843,63.625,70.2037811,64.4091949,70.1509857,65.0629959z"></path>
          <path fill="#397DDA" d="M69.6304932,69.4814987c-0.1025085,0.408699-0.194313,0.3862-0.8525085,0.2207031l-2.217804-0.5596008
            c-0.9160004-0.2305145-1.0233994-0.2812042-0.9346008-0.6377029c0.017601-0.0679016,0.0157013-0.125-0.0467987-0.131813
            c-0.0527954-0.014183-0.0927963,0.0161133-0.1192017,0.1220016c-0.0575943,0.2276154-0.1317978,0.5865097-0.1640854,0.7159119
            c-0.1171112,0.4614029-0.25,0.8304977-0.4580002,1.6561966c-0.0742111,0.2948914-0.0566101,0.3560028,0.0693893,0.4281998
            c0.1561966,0.0865021,0.6512985,0.2602997,0.7118988,0.2754059c0.0907974,0.0224991,0.1338043,0.009285,0.1474991-0.0434036
            c0.0195007-0.0751953-0.0468979-0.1006012-0.0908966-0.1192017c-0.3758926-0.1679001-0.4990005-0.3192978-0.3466034-0.9253006
            c0.1816025-0.7186966,0.2578049-0.7635956,1.0371017-0.5672989l2.2176971,0.5581055
            c0.7198029,0.1810989,0.7315063,0.2318954,0.6377029,0.6029968c-0.0233994,0.0917969-0.0018997,0.1362,0.0507965,0.1493835
            c0.0440063,0.019516,0.0850067-0.0180817,0.1113052-0.1240005c0.0577011-0.2275848,0.1464996-0.6474838,0.1796951-0.7758865
            c0.0401001-0.1596985,0.1524124-0.5093002,0.2129059-0.783699c0.0351944-0.1039963,0.0155945-0.1567001-0.0371017-0.1708984
            C69.6861801,69.3583984,69.6529846,69.3907013,69.6304932,69.4814987z"></path>
          <path fill="#397DDA" d="M68.2662811,74.1376953c-0.1202011,0.3047028-0.222702,0.3057022-0.4317017,0.2397995
            c-0.1875-0.0580978-0.3280869-0.1231003-1.1513977-0.4424133l-0.9501953-0.3793869
            c-0.5214005-0.2069931-0.9501038-0.3778992-1.1562042-0.4940948c-0.2304993-0.1166992-0.2353973-0.2115173-0.1455002-0.4361038
            c0.0263977-0.0648956,0.0322113-0.1221008-0.0186005-0.142601c-0.0506973-0.0199966-0.1044998,0.0093002-0.1425018,0.1040039
            c-0.0790939,0.2198029-0.2031937,0.5732956-0.2529984,0.6972961c-0.0575981,0.1445007-0.218689,0.4844055-0.3602982,0.8398895
            c-0.0371017,0.0937119-0.017601,0.1518173,0.0331993,0.171814c0.0508003,0.0205002,0.0956993-0.0282974,0.1180992-0.0864029
            c0.1221008-0.3046951,0.217804-0.4418945,0.4423981-0.3700943c0.2373047,0.0775986,0.6690063,0.2411957,1.190506,0.4496841
            l0.9501953,0.3779144c0.8203049,0.3276978,0.9746017,0.3804016,1.1367035,0.4618988
            c0.2295074,0.1171875,0.2587967,0.2124023,0.1600952,0.4595032c-0.0205002,0.0507965-0.0311966,0.1210938,0.0410004,0.1508942
            c0.0469055,0.0097885,0.0860062-0.0243988,0.1240997-0.1192093c0.0830002-0.2103882,0.196312-0.536087,0.2509003-0.6732864
            c0.0625-0.1597137,0.2139053-0.4951019,0.297905-0.7046051c0.0429993-0.1088943,0.0341949-0.1718979-0.0088043-0.1888962
            C68.3277817,74.0279007,68.2867813,74.0868988,68.2662811,74.1376953z"></path>
          <path fill="#397DDA" d="M65.2310791,76.4029007c-1.4315872-0.8482056-2.7245865-0.0679016-3.4550972,1.2059937
            c-0.1864891,0.3257065-0.3847008,0.7344055-0.4237976,0.9907074c-0.0098,0.1118927,0.0038986,0.1645966,0.0770988,0.2426987
            c0.1455002,0.1547852,0.5498009,0.4687805,0.6182098,0.5073013c0.053688,0.0307999,0.1073914,0.033699,0.1337891-0.0131073
            c0.0312119-0.0546951,0.0185013-0.0800934-0.0606003-0.1616974c-0.3504982-0.3618011-0.3836975-0.6962967,0.0312996-1.421402
            c0.5127029-0.8949966,1.5018997-1.2376938,2.6875114-0.5570984c0.9296875,0.5776978,1.3290863,1.3564987,0.7743912,2.3242035
            c-0.3418045,0.5971985-0.9227982,1.0751953-1.4570007,0.7680969c-0.0888977-0.0513-0.1513901-0.0513-0.1826019,0.0038986
            c-0.0312996,0.0537033,0.0048027,0.1015854,0.0887985,0.158699c0.0938034,0.0723038,0.3721008,0.2305069,0.5371017,0.3345032
            c0.1689987,0.1147003,0.2217026,0.1634979,0.2618027,0.1869965c0.0477982,0.0263901,0.0907974,0.0151062,0.1406021-0.0546951
            c0.1747971-0.2602997,0.5214996-0.5653992,0.8632965-1.1625977
            C66.5572815,78.5497971,66.4644928,77.1099014,65.2310791,76.4029007z"></path>
          <path fill="#397DDA" d="M60.9488831,80.2719955c-0.2080002-0.3852997-0.2070999-0.5570984,0.0223999-0.8334961
            c0.0303001-0.0355988,0.0527992-0.0878983,0-0.1221008c-0.0477905-0.0400009-0.0907898-0.0243988-0.1562004,0.053688
            c-0.1944008,0.2339096-0.3876991,0.4903107-0.4316978,0.5439987c-0.1152,0.13871-0.2910004,0.3267136-0.4111023,0.4707108
            c-0.0497894,0.0601044-0.0684013,0.1060028-0.0145988,0.1509018c0.0488091,0.0404968,0.0858994,0.0199966,0.1757011-0.0874023
            c0.1191978-0.1445999,0.1982994-0.1407013,0.2636986-0.085495c0.0479012,0.0395966,0.1366997,0.1532822,0.214901,0.2895966
            l0.3016968,0.5653992c0.0137024,0.0214996,0.0039024,0.044899-0.0106964,0.0620041l-0.9131012,1.0878983
            c-0.0205002,0.0243835-0.0371017,0.0317001-0.0536995,0.0278015l-1.0126915-0.3339005
            c-0.0518112-0.0220032,0.1141891-0.2701035,0.1278915-0.2999039c0.0233994-0.0404968,0.0468979-0.0922012,0.0048981-0.1278992
            c-0.0419998-0.0345993-0.1221008,0-0.2177887,0.1147995c-0.0683098,0.0834885-0.2851105,0.3813019-0.3955116,0.5136032
            c-0.0995979,0.1196976-0.2821999,0.3027954-0.3768997,0.4169998c-0.1591988,0.1918869-0.1651001,0.2593002-0.1279984,0.2885971
            c0.0527992,0.0346985,0.0839996,0.0205002,0.1288986-0.0337143c0.1796989-0.2162857,0.5312996-0.1381836,0.8623009-0.0355835
            c0.9229012,0.2695007,3.3456993,1.0547028,3.6827011,1.1512985c0.1649971,0.0458984,0.2255096,0.0458984,0.2714005,0.0019989
            c0.0411072-0.0478973,0.0214996-0.1343002-0.0732002-0.3144989L60.9488831,80.2719955z M60.3140831,82.5263977
            l0.7988014-0.9496994l0.8798981,1.5326996c0.0088005,0.0067978,0.0127106,0.0098038,0.017601,0.0141983l-0.0224991-0.0083008
            L60.3140831,82.5263977z"></path>
          <path fill="#397DDA" d="M21.1870842,87.4135971c-0.3007011-0.1810989-0.6298008-0.3608017-0.7840996-0.4545975
            c-0.1407013-0.0845032-0.4492016-0.2977982-0.710001-0.4556046c-0.1005993-0.060997-0.1562004-0.0580978-0.1845989-0.0112
            c-0.0283012,0.0468903-0.0068016,0.0874023,0.0732994,0.1352997c0.2744007,0.1655045,0.347599,0.3008041,0.222599,0.5083008
            c-0.0965996,0.1605988-0.1747913,0.3050995-0.6317902,1.0605011l-0.5273094,0.876503
            c-0.7558994,1.2509995-0.8398991,1.3007965-1.0606003,1.1674957c-0.1200905-0.0732956-0.1826-0.091301-0.2147999-0.0385971
            c-0.0273991,0.0468979-0.0038986,0.0986023,0.0830002,0.150383c0.2011013,0.1211166,0.5234013,0.2978134,0.6445007,0.3701172
            c0.2470989,0.1493988,0.6660004,0.4838867,1.0322075,0.7055969c1.2617912,0.7700043,2.6640911,0.7129059,3.4706917-0.625
            C23.4156837,89.4521942,22.6988831,88.3256989,21.1870842,87.4135971z M21.7935829,90.5077972
            c-0.6776981,1.1236038-1.4569988,1.2647018-2.5136986,0.6275024c-0.6493912-0.3916016-0.6230011-0.5401001-0.2275009-1.1958008
            l1.4022999-2.3106003c0.0977001-0.1605988,0.1358089-0.1919022,0.4834003,0.017601
            C22.033783,88.3080826,22.4419842,89.4023972,21.7935829,90.5077972z"></path>
          <path fill="#397DDA" d="M27.4390831,90.4062958c-0.102499-0.0381012-1.2001991-0.4468002-1.375-0.5116959
            c-0.1532993-0.0581055-0.4921989-0.2002029-0.7919998-0.3121033c-0.1035004-0.0374985-0.1581993-0.0252991-0.1777,0.0263977
            c-0.0175991,0.0508041,0.0107002,0.0864029,0.0976009,0.1187057c0.3076,0.1151962,0.3985081,0.2318954,0.3125076,0.4589996
            c-0.0654087,0.1747971-0.1113071,0.3241959-0.4199085,1.150795l-0.3603001,0.966301
            c-0.1054993,0.2846985-0.2138996,0.552803-0.2959003,0.7724991c-0.2285995,0.6143036-0.2949982,0.6572037-0.6015987,0.5419998
            c-0.0664005-0.0243988-0.1182003-0.0181122-0.137701,0.0326996c-0.0175915,0.0508041,0.0078011,0.0941925,0.1025009,0.1289062
            c0.2187996,0.0824966,0.5762005,0.1987,0.7001991,0.2450943c0.5860004,0.217804,1.1162014,0.4487991,1.6504002,0.6480026
            c0.1201,0.0355988,0.1767998,0.0150986,0.2569008-0.0874023c0.0664082-0.1088943,0.2460995-0.4584961,0.2919998-0.5830002
            c0.0272999-0.0727997,0.0419006-0.1352997-0.0263996-0.1524124c-0.0586014-0.0214844-0.0859013,0.0103149-0.1260014,0.0703125
            c-0.1181908,0.205101-0.2128983,0.2793045-0.3681908,0.2959061c-0.161108,0.006897-0.5799999-0.1416016-0.7197075-0.193306
            c-0.3955002-0.1464996-0.4414005-0.1811981-0.3017006-0.5541992c0.0594997-0.1607132,0.2392006-0.6436005,0.4687004-1.2577972
            c0.0049-0.0141983,0.0175991-0.0263977,0.0390987-0.0186005c0.0449009,0.0166016,0.6473999,0.2573013,0.7490005,0.294899
            c0.2558994,0.0957031,0.2285004,0.3266983,0.164999,0.4955978c-0.0126991,0.036705-0.0077991,0.0884018,0.0440006,0.1075058
            c0.0732002,0.0277939,0.1084003-0.0435028,0.1289005-0.1016006c0.1093998-0.2929993,0.3223-0.6631012,0.3495998-0.7363052
            c0.0186005-0.051796-0.0028915-0.0844955-0.0391006-0.0971985c-0.0360985-0.014595-0.0838985,0.0263977-0.1766987,0.0497894
            c-0.0547009,0.0132141-0.2207012,0.0342102-0.5137005-0.0741882c-0.2343922-0.0878983-0.5018997-0.1952972-0.5830002-0.2260971
            c-0.0293007-0.0102005-0.0400009-0.0233994-0.0303001-0.0527191l0.5449085-1.4633865
            c0.008791-0.0214996,0.0185909-0.0263977,0.0341911-0.0205002c1.0674,0.3978882,1.0928097,0.4404068,1.0030003,0.8158875
            c-0.0088005,0.0454178-0.0303001,0.1045151,0.0361004,0.1289139c0.0576,0.0224991,0.1005993-0.0039139,0.1201-0.0546951
            c0.1660004-0.446701,0.2891006-0.5757065,0.3300991-0.6855011c0.0166016-0.0435028,0.0156002-0.0855026-0.0282993-0.1016006
            C27.7466831,90.4540863,27.6294918,90.4770813,27.4390831,90.4062958z"></path>
          <path fill="#397DDA" d="M35.7505836,92.028801c-0.3095016-0.0537186-0.7294998-0.2451019-1.4120903-0.3139038
            c-1.3838081-0.1387024-2.6592102,0.533699-2.8017101,1.9481888c-0.1894989,1.6524124,1.0517101,2.5147095,2.5117092,2.6611099
            c0.3730011,0.036705,0.8260918,0.0508041,1.0760918-0.0185013c0.1064987-0.0366135,0.1484985-0.0712967,0.1904984-0.1684952
            c0.0820007-0.1963043,0.2021027-0.6938019,0.2108994-0.771904c0.0059013-0.0619965-0.0126991-0.1109009-0.0673981-0.1162109
            c-0.0625-0.0068893-0.0790901,0.0156097-0.1220016,0.1210022c-0.1884995,0.4678116-0.4785995,0.6348114-1.3106003,0.5513077
            c-1.0253983-0.1024933-1.7421989-0.8651962-1.6054878-2.2250977c0.1484909-1.0834961,0.6953888-1.766098,1.8056908-1.6552963
            c0.684597,0.069397,1.3564987,0.4029007,1.2958984,1.0170822c-0.0107002,0.1006165,0.0147018,0.1587143,0.0772018,0.1646118
            c0.0614967,0.0063019,0.0907974-0.0458984,0.1083984-0.1464996c0.0273018-0.1147003,0.0586014-0.4331131,0.0868988-0.6264954
            c0.0351028-0.2011032,0.0576019-0.2690048,0.0625-0.3159027C35.8619843,92.0795975,35.8345833,92.0453949,35.7505836,92.028801z"></path>
          <path fill="#397DDA" d="M42.9331818,95.7192993c-0.3338966,0.0375977-0.4706879,0.0131989-0.5301971-0.2471008
            c-0.0596008-0.2534027-0.1104012-0.7108994-0.1680031-1.2388l-0.1132965-1.0166016
            c-0.0957031-0.8695984-0.1133003-1.032196-0.1191025-1.2201996c-0.0116997-0.2505112,0.0586014-0.328598,0.3222008-0.3579025
            c0.0928001-0.0102997,0.1290016-0.0458984,0.1231003-0.0917969c-0.0059013-0.0547028-0.0556984-0.080101-0.1562996-0.0692978
            c-0.232399,0.025795-0.5965996,0.0814819-0.7363014,0.0965958c-0.1620979,0.0186005-0.5293007,0.0429993-0.847599,0.0787048
            c-0.1006012,0.0111847-0.152401,0.0394974-0.1464996,0.0941849c0.0057983,0.0542145,0.0555992,0.0806122,0.1415977,0.071312
            c0.2871017-0.032196,0.4463005-0.0180969,0.485302,0.2685013c0.0274086,0.1850967,0.0536995,0.3398972,0.1494102,1.2164001
            l0.0292892,0.2563019c0.0019989,0.0150986-0.0057983,0.0238953-0.0205002,0.0263977l-2.2060013,0.2348022
            c-0.014698,0.0019989-0.0243988-0.0053024-0.0253983-0.0205002l-0.0273895-0.2480011
            c-0.0976105-0.8775024-0.1132088-1.0323029-0.1191101-1.2202148c-0.0126991-0.2582855,0.0586014-0.3286896,0.3134995-0.3570023
            c0.1015015-0.0111847,0.1366997-0.0467834,0.1307983-0.1005859c-0.0057983-0.0550995-0.0555992-0.072197-0.1639977-0.0682983
            c-0.2256012,0.0243988-0.5879021,0.0884018-0.7276001,0.1039963c-0.1631012,0.0181046-0.5390015,0.0351028-0.8485909,0.0698013
            c-0.1094093,0.0121994-0.1514091,0.047905-0.1455078,0.1030045c0.0058975,0.0536957,0.0556984,0.0800858,0.1415977,0.0699005
            c0.3096008-0.0332031,0.4424019,0.0223999,0.4834023,0.2611847c0.0370979,0.1846085,0.0458984,0.3408127,0.1435089,1.2256088
            l0.1132889,1.0166016c0.0576019,0.5277863,0.1084023,0.9852982,0.1035004,1.2226028
            c-0.0028992,0.2593002-0.0429001,0.3261871-0.3143997,0.3554993c-0.0703011,0.0083008-0.1231003,0.0298004-0.1161995,0.0917969
            c0.0068092,0.0547028,0.0565987,0.080101,0.1571999,0.0693054c0.232399-0.0254135,0.5956993-0.0815048,0.7362976-0.0966034
            c0.1621017-0.0186005,0.5293121-0.0429993,0.910202-0.0855026c0.0918007-0.0101929,0.142601-0.0468979,0.1366997-0.1019974
            c-0.0057983-0.0538025-0.0625-0.0708008-0.125-0.064003c-0.3339996,0.0366058-0.4678001,0.0278015-0.5381012-0.2470932
            c-0.0508003-0.2461014-0.1005974-0.7036057-0.1591988-1.2313995l-0.0390015-0.3496017
            c-0.0009995-0.015213,0.0049019-0.0234985,0.0215034-0.0253983l2.2030983-0.2442017
            c0.0088005-0.0009003,0.017601,0.0063858,0.017601,0.0141983l0.0399971,0.3569031
            c0.0586014,0.5278931,0.1084023,0.9853973,0.1045113,1.2294998c-0.0039101,0.2524948-0.0273094,0.3251953-0.3145103,0.3569946
            c-0.0703011,0.0083008-0.1219978,0.0293045-0.1152,0.091301c0.0049019,0.0467987,0.0557098,0.0804901,0.1641006,0.0682983
            c0.2256088-0.0243988,0.5887985-0.0804977,0.7275009-0.1039963c0.1631088-0.009697,0.5303001-0.0351028,0.9111099-0.0771027
            c0.1005898-0.0112991,0.1425896-0.0547104,0.1376877-0.1016006
            C43.051384,95.7221985,42.9956818,95.7128983,42.9331818,95.7192993z"></path>
          <path fill="#397DDA" d="M47.1978836,94.5536957c-0.3153915,0.092804-0.4823914,0.1026001-0.5644989-0.1180954
            c-0.0858917-0.2344055-0.225502-0.6739044-0.3847008-1.2129059l-0.2919998-0.9799957
            c-0.2509995-0.846199-0.3057022-1.0009995-0.3418007-1.1777039c-0.0508003-0.2533951,0.0057983-0.335495,0.2616997-0.4106979
            c0.0517998-0.0156021,0.1142998-0.0507965,0.0918007-0.125c-0.0205002-0.0429001-0.0723-0.0522156-0.1698914-0.0233994
            c-0.2158089,0.0649033-0.5419998,0.1777039-0.683609,0.2197037c-0.1641006,0.0487976-0.5205002,0.1381989-0.7373009,0.2030945
            c-0.1133003,0.033699-0.1562996,0.0782013-0.142601,0.1231003c0.0205116,0.0682907,0.0918007,0.0630035,0.1445122,0.0469055
            c0.3144913-0.092804,0.3779984-0.0142059,0.4550896,0.1908951c0.0703011,0.1820984,0.1083984,0.3344879,0.3661995,1.1777039
            l0.2919998,0.9799957c0.160202,0.5391006,0.2910004,0.9804993,0.3272018,1.2144012
            c0.0517006,0.2528992-0.0186005,0.3149033-0.2520027,0.384201c-0.0673981,0.0205002-0.1152,0.0507965-0.0995979,0.1026001
            c0.0165977,0.0531998,0.0723,0.0765839,0.1688995,0.0477982c0.2226982-0.0742111,0.5772095-0.1962967,0.705101-0.233902
            c0.1493988-0.0443954,0.514698-0.1288986,0.881897-0.2378006c0.0966034-0.0282974,0.1308022-0.0790939,0.1152115-0.1322937
            C47.3238831,94.540596,47.2574921,94.5356979,47.1978836,94.5536957z"></path>
          <path fill="#397DDA" d="M52.0630836,91.7411957c-0.0390015-0.0853958-0.0760994-0.1108017-0.1268997-0.088295
            c-0.0703011,0.0321808-0.0489006,0.0995941-0.0362015,0.1449966c0.1055107,0.3989029,0.0645103,0.589798-0.5047989,0.8506012
            c-0.6739006,0.3099976-0.7569008,0.2782974-1.0928001-0.4526978l-0.9541016-2.0805054
            c-0.309597-0.6753006-0.2763977-0.7158966,0.0713005-0.8754959c0.0859108-0.0386047,0.1084023-0.0835037,0.0859108-0.1333008
            c-0.01371-0.0458984-0.068409-0.0547028-0.1679993-0.0093002c-0.21381,0.097702-0.596611,0.2910004-0.7177124,0.346199
            c-0.1493988,0.0688019-0.4931984,0.2002029-0.7451973,0.3252029c-0.1025009,0.0375977-0.1328011,0.0863953-0.110302,0.1352005
            c0.0233994,0.0497971,0.0684013,0.0546951,0.1533012,0.0161972c0.3838005-0.1758041,0.4238014-0.0904007,0.7061005,0.5268021
            l0.955101,2.0814972c0.3934975,0.8593979,0.4217987,0.9750977,0.0868988,1.1285019
            c-0.0634995,0.0298004-0.1064987,0.0663986-0.0732994,0.1201019c0.0224991,0.0497971,0.0713005,0.060997,0.1708984,0.016098
            c0.2129021-0.097702,0.5401001-0.2650986,0.6612091-0.3203049c0.4334908-0.1991959,0.8045921-0.3261948,1.5780907-0.6811981
            c0.2773018-0.1268997,0.3143997-0.1776962,0.2910004-0.322197
            C52.2642822,92.2934952,52.0894928,91.7978973,52.0630836,91.7411957z"></path>
          <path fill="#397DDA" d="M56.3345833,89.1713943c-0.0429993-0.0648956-0.0849991-0.112793-0.1386986-0.0668945
            c-0.0517006,0.0346985-0.0429001,0.0751953-0.0166016,0.1425858c0.0976982,0.2163086,0.1016006,0.3354111,0.0224991,0.4717102
            c-0.0878983,0.1352005-0.4540977,0.3881989-0.577198,0.4697037c-0.3515015,0.233902-0.405201,0.2509995-0.6259003-0.0791016
            c-0.0956993-0.1436005-0.3819008-0.5727005-0.7451019-1.1185989c-0.0088005-0.0122986-0.0107994-0.0298004,0.0087013-0.0429993
            c0.0391006-0.0264053,0.5878983-0.3721008,0.6777992-0.4331055c0.2285004-0.1513977,0.3984108,0.0063019,0.4990005,0.1557007
            c0.0205002,0.0323029,0.0664101,0.0591049,0.1123009,0.0284042c0.0644989-0.0425034,0.0273972-0.1128006-0.0068016-0.1646042
            c-0.1729012-0.2593002-0.3486977-0.6498947-0.3915977-0.714798c-0.0303001-0.0449982-0.0694008-0.0478973-0.1016006-0.0253983
            c-0.0331993,0.0209961-0.0283012,0.0839996-0.0634995,0.1727982c-0.0214996,0.0518036-0.1015015,0.1992035-0.3613014,0.3721008
            c-0.2080002,0.139183-0.4530907,0.2929993-0.5243988,0.3407974c-0.0264015,0.0171051-0.0430031,0.019104-0.0606003-0.0072937
            l-0.8652-1.2993164c-0.0137024-0.0195847-0.0116997-0.0307846,0.0019989-0.0390854
            c0.9482002-0.6323013,0.9980011-0.6284027,1.25-0.3354034c0.0321999,0.0345993,0.0663986,0.0864029,0.125,0.0477982
            c0.0517006-0.0346985,0.0565987-0.0844955,0.0262985-0.130394c-0.2645988-0.3964005-0.2967987-0.5712051-0.3622971-0.6689148
            c-0.0254021-0.0385895-0.0595016-0.0630035-0.0996017-0.0365906c-0.0389977,0.0254059-0.0888977,0.1343002-0.2578011,0.2461014
            c-0.0907974,0.0610046-1.0653992,0.7108994-1.2216988,0.8143997c-0.1366997,0.0904007-0.4501991,0.2817993-0.7167892,0.4585037
            c-0.0908089,0.0610962-0.1133118,0.1132965-0.0830002,0.158699c0.0312881,0.0448837,0.0771904,0.0429993,0.1542892-0.0093002
            c0.2735023-0.1816025,0.4209023-0.1855011,0.5547104,0.0157013c0.1034889,0.1561966,0.1962891,0.2817001,0.6864891,1.0155945
            l0.5713005,0.8584061c0.1689987,0.2528992,0.3223,0.4979935,0.4531021,0.6923981
            c0.3622971,0.5458984,0.3583984,0.6244965,0.0859985,0.8066025c-0.0586014,0.0385971-0.0849991,0.0839996-0.0546989,0.1298981
            c0.0303001,0.044899,0.0800972,0.0497971,0.1649971-0.0067978c0.1944008-0.1304016,0.4981003-0.3511047,0.6084023-0.4244003
            c0.5195999-0.3466034,1.0186005-0.6406021,1.4921989-0.9570007c0.0996017-0.0762177,0.1172104-0.1343002,0.080101-0.2602158
            C56.5855827,89.6324005,56.408783,89.2812958,56.3345833,89.1713943z"></path>
        </g>
        <g>
          <path fill="#FFFFFF" d="M20.1958847,70.2040863c-0.6424999,0.371109-0.8662014,1.1929092-0.8662014,1.1929092
            s0.8233013,0.2188034,1.4658089-0.1532974c0.6445923-0.3701019,0.866291-1.1919022,0.866291-1.1919022
            S20.8404922,69.8320847,20.1958847,70.2040863z"></path>
          <path fill="#FFFFFF" d="M29.0425835,76.1529007c0.3721008-0.1504059,0.7236996-0.2950058,0.9696999-0.4371033l0.085001-2.0605011
            h-1.4492016l0.1055012,2.6143036C28.8511829,76.2299957,28.9488831,76.1913986,29.0425835,76.1529007z"></path>
          <path fill="#FFFFFF" d="M17.5943832,71.2436981c0.6445007,0.3721008,1.4668007,0.1532974,1.4668007,0.1532974
            s-0.2198009-0.8218002-0.8642998-1.1929092c-0.642601-0.3720016-1.4687004-0.1522903-1.4687004-0.1522903
            S16.953783,70.8735962,17.5943832,71.2436981z"></path>
          <path fill="#FFFFFF" d="M28.1470833,69.8027954c0,0,0,0.483902,0,0.6425018
            c-0.1376991,0.0019989-0.4569988-0.0028992-0.5975914,0c0-0.1625977,0-0.6425018,0-0.6425018
            c0-0.2602997-0.2110004-0.4727097-0.4737091-0.4727097h-2.6581993v0.1113129c0,0.2026825,0.0361996,0.4326859,0.1074009,0.660202
            h1.782299v0.294899h-1.6640987c0.2363071,0.4809952,0.6796989,0.8837967,1.4013996,0.8837967c0,0,0.5243988,0,0.6875,0
            c0.0038986,0.1371994,0.0058002,0.4565048,0.0077991,0.5986023c-0.1669998,0-0.6952991,0-0.6952991,0l-0.2803001-0.0170975
            l-0.5107002-0.0009003v1.0453949h8.5146008v-1.0453949h-0.2705002c-0.0137024,0.0009003-0.2919998,0.0179977-0.2919998,0.0179977
            s-0.5311928,0-0.6981926,0c0.0018921-0.1420975,0.0057907-0.4614029,0.0087929-0.5986023c0.160099,0,0.6893997,0,0.6893997,0
            c0.7196999,0,1.1610985-0.4028015,1.3983994-0.8837967H32.908783v-0.294899h1.8115997
            c0.0751114-0.2275162,0.107399-0.4575195,0.107399-0.660202v-0.1113129H32.172493
            c-0.2607098,0-0.4746094,0.21241-0.4746094,0.4727097c0,0,0,0.4799042,0,0.6425018
            c-0.1396999-0.0028992-0.4589996,0.0019989-0.5977001,0c0-0.1585999,0-0.6425018,0-0.6425018
            c0-0.3955002,0.2217007-0.7587967,0.580101-0.9458008l0.1582069-0.0825958l-0.1445084-0.1044006
            c-0.6464901-0.4737015-1.4013996-0.7250977-2.1816998-0.7250977c-0.7441006,0-1.472599,0.2279968-2.0965996,0.6600952
            l-0.1748009,0.1206055l0.1992016,0.0741959C27.8628845,68.953598,28.1470833,69.3574982,28.1470833,69.8027954z"></path>
          <path fill="#FFFFFF" d="M20.7671833,69.5634995c0.5674-0.4775009,0.6445999-1.3246994,0.6445999-1.3246994
            s-0.8477001-0.073204-1.4169998,0.4048004c-0.5674,0.4774857-0.6455002,1.3246994-0.6455002,1.3246994
            S20.1978836,70.0414963,20.7671833,69.5634995z"></path>
          <path fill="#FFFFFF" d="M13.962492,68.9828949c0,2.0957031,1.2510004,3.5777054,3.5097923,4.0454025
            c0.9013996,0.1861038,1.2206993,0.4013977,1.2694988,0.7509995h0.9493008c0-0.0986023,0-0.2113953,0-0.3222122
            c0-0.3027878-0.1933994-0.8832855-1.2509995-1.1318893c-1.7119007-0.402298-2.6435919-1.6776962-2.6435919-3.2259979
            c0-1.8427963,1.4540911-3.3355026,3.6552916-3.3355026c0.857399,0,1.6532993,0.2275009,2.303709,0.6152039l0.164999-0.198204
            c-0.8729992-0.8106079-2.1171074-1.3207932-3.5019073-1.3207932
            C15.7740841,64.8599014,13.962492,66.7050858,13.962492,68.9828949z"></path>
          <path fill="#FFFFFF" d="M18.5689831,67.0844955c-0.4355984-0.5214996-1.2148991-0.5907974-1.2148991-0.5907974
            s-0.0643997,0.7768021,0.3710995,1.2987976c0.4385014,0.5205002,1.2159004,0.5894012,1.2159004,0.5894012
            S19.0083847,67.6054993,18.5689831,67.0844955z"></path>
          <path fill="#FFFFFF" d="M21.0357838,66.4936981c0,0-0.7754002,0.0692978-1.2138996,0.5907974
            c-0.4375,0.5210037-0.3701,1.2974014-0.3701,1.2974014s0.7763996-0.0689011,1.2138996-0.5894012
            C21.1021843,67.2705002,21.0357838,66.4936981,21.0357838,66.4936981z"></path>
          <path fill="#FFFFFF" d="M16.9781837,68.2388c0,0,0.0771008,0.8471985,0.6445007,1.3246994
            c0.5723,0.4779968,1.419899,0.4048004,1.419899,0.4048004s-0.0751991-0.8472137-0.6464996-1.3246994
            C17.8267841,68.165596,16.9781837,68.2388,16.9781837,68.2388z"></path>
          <path fill="#FFFFFF" d="M18.5464916,74.6196976l-0.3965073,3.8973846c0,0.4771194,0.4795074,0.8633194,1.0693989,0.8633194
            c0.5918007,0,1.0703011-0.3862,1.0703011-0.8633194l-0.392601-3.8973846H18.5464916z"></path>
          <path fill="#FFFFFF" d="M33.7564926,73.4726944c-0.7509995-0.0204926-1.7617092,0.3799057-2.6543083,2.1665039
            c-0.1201,0.2391968-0.3233013,0.4272003-0.575201,0.587883c-0.0039005,0.001915-0.0058994,0.0054169-0.0107002,0.008316
            c-0.3066998,0.2075043-0.7236996,0.3768997-1.2050991,0.573204c-0.4395008,0.1791992-0.9199009,0.3754807-1.3633003,0.6225967
            c-0.5342007,0.3364029-0.9433918,0.7919998-0.9433918,1.4888c0,0.5628967,0.3495922,0.9749985,0.8007927,1.3012009
            c0.2549-0.1919022,0.5273991-0.3598022,0.8095989-0.5195007c-0.4209003-0.2319031-0.7276001-0.4707031-0.7276001-0.7817001
            c0-0.4414978,0.3907013-0.713913,0.9248009-0.9589996v0.0131989c0.2792988-0.1352997,0.5781994-0.2612991,0.8710995-0.3834
            c0.4473095-0.1884003,0.9043083-0.3823013,1.2950001-0.6063995c0.3789005-0.2368011,0.7080002-0.533699,0.914999-0.9492035
            c0.2607021-0.5175934,0.5918026-0.9755936,0.8076-1.192894c0.2929993-0.2919998,0.6748009,0.0263977,1.3339996,0.0263977
            c0.6934013,0,1.1758003-0.3736115,1.2061005-0.4688034c0-0.0009003,0.0390015-0.2269974,0.0390015-0.2269974
            C35.1021843,74.0121994,34.443985,73.4726944,33.7564926,73.4726944z M34.0816841,74.3827972
            c-0.1240005,0-0.2246017-0.1009979-0.2246017-0.2250977c0-0.1240005,0.1006012-0.224617,0.2246017-0.224617
            s0.2245979,0.1006165,0.2245979,0.224617C34.306282,74.2817993,34.2056847,74.3827972,34.0816841,74.3827972z"></path>
          <path fill="#FFFFFF" d="M29.7652836,78.8526001c0-0.2168045-0.1757908-0.392601-0.3925991-0.392601
            c-0.2168007,0-0.392601,0.1757965-0.392601,0.392601c0,0.2167969,0.1758003,0.3924866,0.392601,0.3924866
            C29.5894928,79.2450867,29.7652836,79.069397,29.7652836,78.8526001z"></path>
          <path fill="#FFFFFF" d="M30.243784,78.1205978c0.3682003,0.2076035,0.6103001,0.4502029,0.6103001,0.7994003
            c0,0.3563995-0.4043007,0.6048965-0.9188995,0.8700867c-0.1406002,0.0771103-0.2871017,0.1557159-0.4375,0.2373123
            c-0.4414005,0.2342987-0.9297009,0.4716873-1.3018017,0.7583008c-0.0077991,0.0073013-0.0174999,0.0121994-0.0282993,0.0170975
            c-0.362299,0.2910004-0.6259995,0.6518021-0.6259995,1.1504059c0,0.6039963,0.255909,0.9261932,0.5956993,1.1459961
            c0.1954002-0.2167969,0.4043007-0.4029007,0.652401-0.5917969c-0.2256012-0.1381989-0.3633003-0.2919998-0.3633003-0.5541992
            c0-0.1641159,0.107399-0.3115997,0.2881088-0.4589996c0.0018902-0.0020065,0.0048904-0.003006,0.0067902-0.0039062
            c0.0098-0.0093002,0.0244007-0.0170975,0.0342102-0.0253983c0.0243912-0.0180969,0.04879-0.0375977,0.0771904-0.0557022
            c0.3701-0.2655945,0.7021008-0.4141083,1.1229992-0.6215973c0.1201-0.0599976,0.2393017-0.1201019,0.3535004-0.1800995
            c0,0,0,0-0.0038986-0.0009995c0.7099991-0.3779984,1.4355984-0.878418,1.4355984-1.6865005
            c0-0.5298004-0.2402992-0.9205017-0.5879002-1.2247009C30.8648834,77.8500824,30.5552845,77.9882965,30.243784,78.1205978z"></path>
          <path fill="#FFFFFF" d="M27.551384,76.8394012c0.3076-0.1753159,0.6289005-0.3237,0.9375-0.4575043
            c-0.3759995-0.194397-0.6875-0.4208984-0.8476009-0.7426987c-0.8925991-1.7865982-1.9013996-2.1869965-2.6562996-2.1665039
            c-0.6854916,0-1.3417988,0.539505-1.5233994,0.7002029c0.0019073,0,0.0410004,0.2260971,0.0410004,0.2269974
            c0.0282993,0.095192,0.5145988,0.4688034,1.2040997,0.4688034c0.6611004,0,1.0429001-0.3183975,1.3379002-0.0263977
            c0.2157993,0.2173004,0.5449085,0.6753006,0.8036995,1.192894C27.0142841,76.3705978,27.2622833,76.6279984,27.551384,76.8394012
            z M24.6421833,74.3827972c-0.1239986,0-0.2245998-0.1009979-0.2245998-0.2250977
            c0-0.1240005,0.1006012-0.224617,0.2245998-0.224617c0.1231003,0,0.2236996,0.1006165,0.2236996,0.224617
            C24.8658829,74.2817993,24.7652836,74.3827972,24.6421833,74.3827972z"></path>
          <path fill="#FFFFFF" d="M30.266283,81.4234009c-0.0683994,0.032196-0.1357899,0.0677948-0.2031994,0.1014938
            c0.1611996,0.1371918,0.2539005,0.2754059,0.2539005,0.4283066c0,0.1786957-0.0664005,0.3070984-0.1767006,0.4134979
            c-0.0116997,0.0131989-0.0263996,0.0243835-0.0380993,0.0356979c-0.0088005,0.0063019-0.0166016,0.0121994-0.022501,0.0166016
            c-0.0018997,0.0028992-0.0067997,0.0067978-0.0107002,0.009697c-0.0058994,0.0053864-0.0136986,0.0083008-0.0204906,0.0131989
            c-0.0244083,0.017601-0.050808,0.0357056-0.0791092,0.0541916c-0.7890987,0.5337143-1.1856003,0.7769089-1.5390987,1.3311081
            c-0.0996017,0.180603-0.1592007,0.3618011-0.1592007,0.5693054c0,0.4442978,0.3339996,0.8153992,0.7870998,0.9365005
            l0.0186005,0.4838943c0,0.1636047,0.1317997,0.2969055,0.294899,0.2969055c0.1651001,0,0.2988091-0.1333008,0.2988091-0.2969055
            l0.0146923-0.4838943c0.4530983-0.1211014,0.7870998-0.4922028,0.7870998-0.9365005
            c0-0.1059036-0.0185013-0.2065048-0.0507927-0.3008041c-0.2138081,0.1016006-0.3418083,0.1758041-0.4765072,0.2969055
            c-0.146492,0.1288986-0.3702011,0.1166992-0.5-0.0298004c-0.1299-0.1464996-0.1182003-0.3701019,0.0301991-0.5
            c0.2178001-0.1963043,0.4190006-0.3022003,0.7159004-0.4424057c0.5067997-0.2714005,1.0107002-0.6791992,1.0107002-1.4671936
            c0-0.3399048-0.1240005-0.6143036-0.3154011-0.8482056C30.6870842,81.2138977,30.4820843,81.3163986,30.266283,81.4234009z"></path>
          <path fill="#FFFFFF" d="M11.2720842,66.8945999C11.2105837,67,11.2476845,67.1366959,11.3531837,67.1972961l1.8242006,1.0517883
            l0.0897999-0.4589996l-1.6942997-0.9770889C11.4693842,66.7519989,11.3326845,66.7885971,11.2720842,66.8945999z"></path>
          <path fill="#FFFFFF" d="M14.0757837,66.0761948l0.2813005-0.3427963l-1.1835918-0.9925995
            c-0.0957079-0.0791016-0.2344084-0.0660019-0.313508,0.0262833c-0.0791006,0.0938187-0.0674,0.2339172,0.0282993,0.3111191
            L14.0757837,66.0761948z"></path>
          <path fill="#FFFFFF" d="M15.5992842,64.7349014l0.4072084-0.206604l-1.0332088-1.2294006
            c-0.0790997-0.0938148-0.2177916-0.1054993-0.3134995-0.0273972c-0.0918007,0.0790977-0.1035004,0.2187996-0.0264006,0.3120003
            L15.5992842,64.7349014z"></path>
          <path fill="#FFFFFF" d="M23.7583847,63.5824966c0.0801067-0.0931969,0.0654984-0.2328987-0.0283012-0.3119965
            c-0.0937004-0.0781021-0.2334003-0.0664177-0.3115005,0.0273972l-1.5067997,1.7958984l0.3525009,0.2695007L23.7583847,63.5824966
            z"></path>
          <path fill="#FFFFFF" d="M25.516283,64.7802963c-0.0800991-0.0932999-0.2177906-0.1044998-0.3134995-0.027298l-3.0820999,2.596199
            l0.0049,0.5638885l3.3614006-2.8222885C25.5826836,65.0141983,25.5933838,64.8740845,25.516283,64.7802963z"></path>
          <path fill="#FFFFFF" d="M27.1206837,66.8945999c-0.0634003-0.1060028-0.199192-0.142601-0.3036995-0.0806046
            l-4.4033012,2.5522003l0.0049,0.4986038l4.6182003-2.6675034C27.1431847,67.1366959,27.1792831,67,27.1206837,66.8945999z"></path>
        </g>
        <g>
          <path fill="#D3DCF6" d="M10.5318842,61.9950829h25.8213005V36.264698
            C22.2476845,36.490799,10.8101845,47.8984985,10.5318842,61.9950829z M20.7378845,47.1338005
            c0,0,0.6689987-0.1269035,1.3017998-0.1269035c0.2489986,0,0.660099,0.108902,1.0205002,0.2524033v-3.8887024h4.2645988
            v-4.9125977h4.1065006c0,0,0,3.7563972,0,4.9125977c1.1220989,0,4.2616978,0,4.2616978,0v4.1070023c0,0-3.1395988,0-4.2616978,0
            c0,1.357399,0,13.7875977,0,13.7875977h-4.1065006V47.4776001h-3.8153992
            c0.3339996,0.2074852,0.8974991,0.7381973,1.0401077,0.874485c0.2127914-0.0566864,0.4823914-0.1195869,0.6405926-0.1195869
            c0.3994083,0,0.6737995,0.3495865,0.9110985,0.3495865c0.1748009,0,0.3359013-0.0131874,0.3359013,0.0976143
            c0.0009995,0.0751991-0.1074009,0.2276001-0.3681011,0.2276001c-0.2890987,0-0.5702991-0.2359009-0.8456993-0.2359009
            c-0.2157917,0-0.5165997,0.1240005-0.7285004,0.2271004l-0.0986996,0.5570984h1.987299v1.769001
            c0,1.6557999-1.2245998,2.5342026-2.1170998,3.1748009c-0.1464996,0.1054993-0.3477001,0.2206841-0.5450001,0.3334999
            c0.1114006,0.1259995,0.1973,0.2734985,0.2461014,0.4404984c0.0771999,0.2846031,0.0401077,0.5834999-0.1035004,0.8408012
            c-0.0762005,0.1333008-0.1758003,0.2461014-0.2929993,0.3363991c0.2333984,0.1464844,0.5186081,0.4932022,0.5186081,0.9326019
            c0,0.8784981-1.0820007,1.1431007-1.0820007,1.1431007c0,0.0282974,0,0.0517845,0,0.0658989
            c0.0731926,0.0723,0.1883926,0.1728859,0.2762909,0.2461014c0.4639015,0.3974991,1.5049,0.9018974,1.5049,1.8925972v0.6343002
            H18.453783v-0.6343002c0-0.8451996,0.7529011-1.3700981,1.2646008-1.7178154
            c-0.2070007-0.1454849-0.357399-0.3524818-0.4286995-0.5985832c-0.0771923-0.2862015-0.0410004-0.5849991,0.1035004-0.8408012
            c0.0751991-0.1348,0.174799-0.2476158,0.294899-0.3378983c-0.2334003-0.1464996-0.4960995-0.4707146-0.4960995-0.7271004
            c-2.0029011-0.721199-4.3681002-2.1190987-4.3681002-5.1908989c0-2.6582031,2.0780993-4.2759018,4.0019007-4.2759018
            C19.6988831,46.9418983,20.7378845,47.1338005,20.7378845,47.1338005z"></path>
          <path fill="#D3DCF6" d="M54.2808838,72.3134995l1.5293007-3.4048004l-5.1356926,0.0009995l-0.3106079,4.3817978
            c0.1503983,0.0083008,0.3046989,0.0201035,0.4482002,0.0547028c0.5449982,0.1284027,0.9296989,0.3936005,1.2978973,0.6508865
            c0.3730011,0.258316,0.7246017,0.5015106,1.2539024,0.6284103c0.2666092,0.064003,0.5156097,0.0987015,0.732399,0.1182022
            c-0.3320007-0.4618988-0.5312004-1.0220032-0.5312004-1.6314011v-0.7987976H54.2808838z"></path>
          <polygon fill="#D3DCF6" points="39.3872833,72.3134995 42.1997833,72.3134995 40.7945824,69.1836014 			"></polygon>
          <path fill="#D3DCF6" d="M41.3648834,68.9112015l1.5283012,3.402298h0.714798v0.7987976
            c0,0.1641006-0.0214005,0.3223038-0.0487976,0.4790039l0.7265968-1.102005l0.2129021-0.3202972l0.3105011,0.2246017
            c0.0038986,0.0018997,0.3866997,0.2733994,1.0566978,0.4340973c0.3046112,0.073204,0.6054001,0.0814972,0.910099,0.0692978
            l-0.2841988-3.9872971L41.3648834,68.9112015z"></path>
          <polygon fill="#D3DCF6" points="54.9693832,72.3134995 57.7867813,72.3134995 56.378582,69.1836014 			"></polygon>
          <path fill="#D3DCF6" d="M37.2417831,62.8808975V88.772995c14.1464996-0.244194,25.6074982-11.732399,25.7979012-25.8920975
            H37.2417831z M56.378582,75.9238968c-0.3574982,0-0.6963005-0.0732956-1.0107994-0.1949005l0.0078011,0.3676987
            l-2.592701,6.3687057l-3.0029984-0.9668045l-0.0996017,1.4316025c0-0.0009995,0-0.0018997,0-0.0028992V82.9375
            c-0.0057907,0.5976944-0.4960976,1.0805817-1.0937004,1.0805817c-0.6026001,0-1.0937996-0.4887848-1.0937996-1.0907822
            l0.0028992,0.0565948l-0.1464005-2.0560989c-0.2197876,0.1079025-0.5927887,0.118103-0.8300972,0.0541992
            c-0.339901-0.0883942-0.8466911-0.4219131-0.7783012-0.6957932l-5.2773895-1.0562057l0.2011909-1.221199l-0.0107994-0.0112
            l0.0186081-0.0298004l0.0048904-0.0199966h0.0088005l1.6357002-2.4800034
            c-0.4393921,0.2865982-0.9639015,0.4561005-1.5273018,0.4561005c-1.5517998,0-2.8115997-1.2608109-2.8115997-2.8115997
            v-0.7987976h0.7129021l1.5331993-3.4160004c-0.794899-0.0967026-1.4150009-0.7744141-1.4150009-1.5971985
            c0-0.8886032,0.7216988-1.6118011,1.6104088-1.6118011c0.6307907,0,1.1698914,0.3661957,1.4364929,0.8925858
            c2.0253983,0.0786133,3.652298-0.2001877,4.8378983-0.8446884c-0.0391006-0.158699-0.0693893-0.321312-0.0693893-0.4883118
            c0-1.0785904,0.8769989-1.9540863,1.9570885-1.9540863c1.0771027,0,1.9570007,0.8754959,1.9570007,1.9540863
            c0,0.1679153-0.0312996,0.330513-0.0723,0.489212c1.1884995,0.6455002,2.8203011,0.9234009,4.8506012,0.8427887
            c0.264698-0.5253906,0.8046989-0.8915863,1.4356079-0.8915863c0.886692,0,1.6102905,0.7231979,1.6102905,1.6118011
            c0,0.819397-0.6181984,1.4965973-1.4277,1.5942993l1.5381012,3.4188995h0.7118988v0.7987976
            C59.1900826,74.6630859,57.9292831,75.9238968,56.378582,75.9238968z"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'LogoUc'
}
</script>