module.exports = {
    cleanRut(rut) {
      return rut.replace(/[^0-9kK]/g, "");
    },
    cleanRutNumber(rut) {
      return rut.replace(/[^0-9]/g, "").slice(0, 9);
    },
    cleanRutDv(dv) {
      return this.cleanRut(dv);
    },
    format(rut, thousandSeparator) {
      rut = rut.replace(/\W/g, "");
      let run = rut.substr(0, rut.length - 1);
      const dv = rut.substr(-1);
  
      if (thousandSeparator === true) {
        run = run.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
  
      return run + "-" + dv;
    },
    parse(rut) {
      rut = this.cleanRut(rut || "");
      const numero = this.cleanRutNumber(rut.substr(0, rut.length - 1));
      let dv = this.cleanRutDv(rut.substr(rut.length - 1, 1));
      if (isNaN(dv) || dv === "") {
        dv = dv.toUpperCase();
      } else {
        dv = parseInt(dv, 10);
      }
      return { numero: numero, dv: dv };
    },
    validate(rut) {
      const parsedRut = this.parse(rut);
      let sum = 0;
      parsedRut.numero
        .split("")
        .reverse()
        .forEach(function(n, i) {
          sum += (i > 5 ? i - 4 : i + 2) * n;
        });
      const dvCheck = 11 - (sum % 11);
      return (dvCheck <= 9 ? dvCheck : dvCheck < 11 ? "K" : 0) === parsedRut.dv;
    },
  };
  