<template>
  <header id="app-header" class="uc-header">
    <nav class="uc-navbar">
      <!-- Menú para versión Escritorio -->
      <div class="container d-none d-lg-block">
        <div class="row align-items-center">
          <div class="col-lg-3 col-xl-2">
            <a href="/">
              <img src="https://kit-digital-uc-prod.s3.amazonaws.com/assets/logo-uc-color.svg" alt="Logo uc" class="img-fluid">
            </a>
          </div>
          <div class="col-lg-8 col-xl-9">
            <div class="h2 text-font--serif text-color--blue mt-24 text-left">{{ title }}</div>
            <div class="text-color--gray p-size--lg">{{ subtitle }}</div>
          </div>
          <a href="javascript:void(0);" @click.stop="open = !open" class="uc-navbar_mobile-button desktop-ham">
            <span class="uc-icon"></span>
            Menú
          </a>
        </div>
      </div>
      <!-- Menú para versión Móvil -->
      <div :class="['d-block navbar_mobile-slide uc-navbar_mobile', {'is-open': open}]" data-init="true">
        <div class="uc-navbar_mobile-bar navbar-brand d-lg-none">
          <div class="uc-navbar_mobile-logo navbar-light">
            <div class="h2 text-font--serif text-color--blue">{{ title }}</div>
          </div>
          <a href="javascript:void(0);" @click.stop="open = !open" class="uc-navbar_mobile-button">
            <span class="uc-icon"></span>
            Menú
          </a>
        </div>
        <div class="uc-navbar_mobile-content">
          <div class="uc-navbar_mobile-list text-left">
            <router-link :to="`/${$store.state.prefix}mapa/servicios`" class="list-item">
              <span>Servicios Estudiantiles</span>
            </router-link>
            <a class="list-item" target="_blank" href="https://sso.uc.cl/cas/login?service=https%3A%2F%2Fcursos.canvas.uc.cl%2Flogin%2Fcas">
              <span>
                RUTA NOVATA <i class="uc-icon">open_in_new</i>
              </span>
            </a>
            <router-link :to="`/${$store.state.prefix}mapa/carreras`" class="list-item">
              <span>Carreras UC</span>
            </router-link>
            <router-link :to="`/${$store.state.prefix}mapa/conoce-mas-de-la-uc`" class="list-item">
              <span>Conoce más de la UC</span>
            </router-link>
            <router-link :to="`/${$store.state.prefix}mapa/charlas`" class="list-item">
              <span>Ver Charlas</span>
            </router-link>
            <a class="list-item" target="_blank" href="https://postulacion.demre.cl/">
              <span>
                Postula hoy a la UC <i class="uc-icon">open_in_new</i>
              </span>
            </a>
            <a href="/perfil" class="list-item">
              <span>Mi perfil</span>
            </a>
            <a @click.stop.prevent="logout($router)" href="" class="list-item">
              <span>Cerrar sesión</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { logout } from "../helpers/auth.js";

export default {
  name: 'AppHeader',
  setup(){
    return { logout }
  },
  data: () => ({
    open: false,
    title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : 'Expo Futuro Novato y Novata 2022',
    subtitle: process.env.VUE_APP_SUBTITLE ? process.env.VUE_APP_SUBTITLE : 'Descubre nuestras carreras y vive la experiencia universitaria en la UC'
  }),
  mounted () {
    document.onclick = () => {
      this.open = false
    }
  }
}
</script>
<style lang="scss" scoped>
.uc-navbar_mobile-logo{
  div.h2{
    font-size:1.2em
  }
}
header.uc-header{
  position: relative;
  z-index: 999;
}
.uc-navbar_mobile-content{
  
  width: fit-content;
  right: 0;
  position: fixed;
  height: 100vh;
  .list-item{
    padding: .8rem !important;
  }
  @media screen and(min-width: 992px) {
    top: var(--top);
  }
}
.desktop-ham{
  color: #173f8a;
  background: white;
  .uc-icon {
    color: #173f8a;
  }
}
</style>