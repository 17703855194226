<template>
  <a
    target="_blank"
    :class="['simulate-score', isIn ? 'in' : '' ]"
    href="https://www12.uc.cl/ConsSimulacion/jsp/simul_paramConsulPtje.jsp"
  >
    <img src="@/assets/images/simula.svg" alt="simula tu puntaje">
  </a>
</template>
<style lang="scss" scoped>
  .simulate-score{
    width: 70px;
    height: 70px;
    position: fixed;
    z-index: 99;
    right: -100px;
    transition: ease-in-out all 0.2s;
    margin-top: -35px;
    top: 50%;
    img {
      position: relative;
      z-index: 1;
    }
    &::after{
      content: '';
      background: white;
      border-radius: 3px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      transition: ease-in-out all 0.2s;
    }
    &:hover{
      &::after{
        transform: scale(1.1);
      }
    }
    &.in{
      right: 30px;
    }
  }
</style>
<script>
export default {
  data: () => ({
    isIn: false
  }),
  mounted () {
    setTimeout(()=>{
      this.isIn = true
    },1000)
  }
}
</script>