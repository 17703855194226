<template>
  <div
    class="with-background-sticky"
    style="min-height: 100vh"
    :style="{ backgroundImage: 'url(' + page.imagenFondo + ')' }"
  >
    <div
      class="min-h-50 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="m-12">
        <img class="logo-uc" :src="page.logo" alt="" />
      </div>

      <div
        v-if="showMessage"
        style="background-color: white"
        class="uc-alert warning my-12"
      >
        <div class="uc-alert_content">
          <i class="uc-icon icon-size--sm">warning</i>
          <span class="p p-size--sm bold ml-8">{{ messageContent }}</span>
        </div>
      </div>

      <div class="uc-card mt-16 mb-36" style="max-width: 500px">
        <div class="uc-card_body">
          <p>
            A continuación ingrese el correo electrónico de la cuenta que quiera
            recuperar y presione en enviar, recibirá un correo con la nueva
            clave.
          </p>
          <form>
            <div class="uc-form-group">
              <label for="ucinputRut"
                ><i class="uc-icon">account_circle</i> Correo electrónico
                (*)</label
              >
              <input
                v-model="form.email"
                id="ucinputRut"
                type="text"
                class="uc-input-style"
                placeholder="Ingrese correo electrónico"
              />
            </div>

            <a href="/login" class="link-inline"> Volver al login </a>
            <div class="d-flex justify-content-end">
              <button
                :disabled="loading"
                @click.prevent="authenticate"
                class="uc-btn btn-primary btn-inline"
              >
                Enviar
                <i class="uc-icon">arrow_forward</i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { checkLogin } from "../helpers/auth.js";

export default {
  name: "Recuperar",
  components: {},
  props: {
    msg: String
  },
  setup() {
    return { checkLogin };
  },
  created() {
    this.fetchPageData();
    const logged = JSON.parse(localStorage.getItem("logged"));
    const user = JSON.parse(localStorage.getItem("user"));
    this.checkLogin(logged, user);
  },
  data() {
    return {
      showMessage: false,
      messageContent: "",
      loading: false,
      page: {
        titulo: "",
        logo: "",
        imagenFondo: "",
        imagenPromocional: ""
      },
      form: {
        email: "",
        token: ""
      }
    };
  },
  methods: {
    fetchPageData() {
      window.axios
        .get("/api/page/login")
        .then(({ data }) => {
          if (data) {
            this.page.titulo = data.titulo;
            this.page.logo = data.logo_url;
            this.page.imagenFondo = data.imagen_fondo_url;
            this.page.imagenPromocional = data.imagen_promocional_url;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    //async loadRecaptcha() {
    //  await this.$recaptchaLoaded();
    //  const token = await this.$recaptcha("recuperar");
    //  this.form.token = token;
    //},
    async getCsrfCookie() {
      await window.axios.get("/sanctum/csrf-cookie");
    },
    async authenticate() {
      if (this.validInputs()) {
        //await this.loadRecaptcha();
        this.loading = true;
        await this.getCsrfCookie();
        window.axios
          .post("/api/request-change-password", this.form)
          .then(() => {
            this.form.email = "";
            ElMessage({
              showClose: true,
              message:
                "Correo electrónico de recuperación de contraseña enviado",
              type: "success",
              duration: 10000
            });
            this.loading = false;
          })
          .catch(e => {
            this.triggerMessage(e.response.data, 5000);
            this.loading = false;
          });
      } else {
        this.triggerMessage(`Debe ingresar el correo electrónico.`, 5000);
      }
    },
    triggerMessage(message, duration) {
      this.showMessage = true;
      this.messageContent = message;
      setTimeout(() => {
        this.showMessage = false;
      }, duration);
    },
    validInputs() {
      if (this.form.email === "") {
        return false;
      }
      if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        ElMessage({
          showClose: true,
          message: "Formato de correo no valido",
          type: "error",
          duration: 10000
        });
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped>
.logo-uc {
  width: 140px;
}
</style>
