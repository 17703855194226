module.exports = {
  groupBy: function(xs, key, sortBy = false) {
    let data =  xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    if(sortBy) {
      Object.keys(data).forEach(key => {
        data[key] = data[key].sort((a,b) => a[sortBy] - b[sortBy])
      })
    }
    return data
  }
}