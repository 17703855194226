<template>
  <footer id="app-footer" class="uc-footer">
    <div class="container pb-28">
      <div class="d-flex align-items-center justify-content-center justify-content-lg-between">
        <img src="@/assets/images/acreditada-gratuidad.png" class="img-acreditada-gratuidad d-none d-lg-block" alt="acreditada y gratuidad">
        <ul class="uc-footer_social">
          <!-- agregar enlaces a redes sociales correspondientes -->
          <li>
            <a href="https://www.facebook.com/admisionenlauc/" target="_blank">
              <img src="https://kit-digital-uc-prod.s3.amazonaws.com/assets/social-icon-facebook.svg" alt="Facebook">
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/admisionuc/?hl=es-la" target="_blank">
              <img src="https://kit-digital-uc-prod.s3.amazonaws.com/assets/social-icon-instagram.svg" alt="Instagram">
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCwpok7_Wf2xLAmVKaskUPvg" target="_blank">
              <img src="https://kit-digital-uc-prod.s3.amazonaws.com/assets/social-icon-youtube.svg" alt="Youtube">
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@admisionuc?lang=es" target="_blank">
              <img src="@/assets/images/tiktok.svg" alt="Tiktok.">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
footer.uc-footer{
  position: relative;
  z-index: 999;
}
.uc-footer {
  padding-top: 28px;
  .img-acreditada-gratuidad {
    height: 50px;
  }
  .uc-footer_social {
    margin-top: 0;
  }
}
</style>