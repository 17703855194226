<template>
  <div modal-name="chatWithCareers" title="Selecciona y comienza el recorrido" :close-path="`/${$store.state.prefix}mapa`">
    <div id="single-career" v-if="stand">
      <side-data :parent="parent" :title="stand.nombre">
        <router-view cols="12" :data="stand"/>
      </side-data>
    </div>
  </div>
</template>
<script>
import SideData from '@/components/SideData.vue'

export default {
  name: 'SingleCareer',
  props: ['parent'],
  components: {
    SideData
  },
  data: () => ({
    charlas: []
  }),
  mounted () {
    this.$store.dispatch('getStands')
    this.$store.dispatch('getServices')
  },
  computed: {
    stand () {
      let stands = Object.values(this.$store.state.stands)
      let services = Object.values(this.$store.state.services)

      let data = [...stands, ...services]

      if(!data.length) return {}

      data = data
        .reduce((carry, el) => {
          carry = [...carry, ...el]
          return carry
        })

      return data.find(el => el.slug === this.$route.params.slug)
    }
  }
}
</script>
<style lang="scss">
#single-career {
  .single-career-img {
    width: 100%;
    background-color: lightgrey;
    height: 0;
    padding-bottom: 66%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: white;
    }
  }
  .single-career-content {
    font-size: 1rem;
    h3 {
      font-weight: 300;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    ul {
      margin: 0;
      padding: 0;
      padding-left: 1rem;
      list-style-type: none;
    }
    .requisitos {
      .requisitos-ul {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin: 0 -7.5px;
        li {
          background-color: var(--blue);
          color: white;
          margin: 0 7.5px;
          margin-top: 15px;
          border-radius: 4px;
          padding: 12px;
          display: flex;
          justify-content: center;
          align-items: stretch;
          flex: 1 0 30%;
          font-weight: 500;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            span {
              font-weight: 500;
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}
</style>
