<template>
  <div :class="['cursor-container', {grabbing:grabbing}]">
    <div class="cursor-bg"></div>
    <div class="cursor" :style="`top:${mouse.y}px;left:${mouse.x}px`">
      <div class="cursor-img a">
        <img src="@/assets/cursor/a.svg">
      </div>
      <div class="cursor-img b">
        <img src="@/assets/cursor/b.svg">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Cursor',
  data: () => ({
    grabbing: false,
    mouse: {
      x: 0,
      y: 0
    },
    cursorSpeed: 0.5
  }),
  methods: {
    animate () {
    }
  },
  mounted () {
    this.animate()
    window.onmousemove = (e) => {
      this.mouse = {
        x: e.offsetX - 50,
        y: e.offsetY - 50
      }
    }
    addEventListener('mousedown', () => {
      this.grabbing = true
    })
    addEventListener('mouseup', () => {
      this.grabbing = false
    })
    addEventListener('touchstart', () => {
      this.grabbing = true
    })
    addEventListener('touchend', () => {
      this.grabbing = false
    })
  }
}
</script>
<style lang="scss" scoped>
.cursor-container{
  position: fixed;
  width: 100%;
  height: calc(100% - var(--top) - var(--bottom));
  z-index: 99;
  &.grabbing{
    // .cursor-bg{
    //   display: block;
    //   z-index: 3;
    // }
    .cursor{
      opacity: 1;
      .cursor-img{
        &.b{
          transform: scale(1);
        }
      }
    }
  }
  .cursor-bg{
    width: calc(100%);
    height: calc(100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .cursor{
    //transform: translateY(calc(-1 * var(--top)));
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: ease-out opacity .15s;
    .cursor-img{
      width: 100px;
      &.b{
        position: absolute;
        top: 0;
        transition: ease-in-out all 0.25s;
        transform-origin: center;
        transform: scale(0.85);
      }
    }
  }
}
</style>